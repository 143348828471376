import React, { useEffect, useState } from "react";
import { MdVerifiedUser } from "react-icons/md";
import Modal from "react-modal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { AppColor } from "../../assests/colors/colors";
import { useApp } from "../../base/context/AppProviders";
import WebButton from "../../components/library/webButton";
import WebInput from "../../components/library/webInput";
import WebVerifyOtp from "../../components/library/webVerifyOtp";
import api from "../../config/apiConfig";
import { RejexConstants } from "../../helper/stringConstants";
import { NetworkConfiguration } from "../../network/NetworkConfriguation";
import { AuthApiService } from "../../service/authApiService";
import useIsVarified from "../../storage/membershipid";
import "./style.css";

export default function Registration() {
  const navigate = useNavigate();
  const app = useApp();

  const [emailOtp, setEmailOtp] = useState("");
  const [emailOtpPopup, setEmailOtpPopup] = useState(false);
  const [emailResId, setEmailResId] = useState();

  const [phoneOtp, setPhoneOtp] = useState("");
  const [phoneOtpPopup, setPhoneOtpPopup] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams("");
  const { isVarified } = useIsVarified();

  const [verified, setVerified] = useState({
    refree: false,
    email: false,
    phone: false,
    membershipId: false,
  });

  useEffect(() => {
    setData({ ...data, refree: searchParams.get("referalCode") });
  }, [searchParams]);

  const [data, setData] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    city: "",
    state: "",
    pincode: "",
    email: "",
    phone: "",
    refree: "",
    membershipId: "",
  });

  const [state, setState] = useState(null);
  const [city, setCity] = useState(null);

  const [error, setError] = useState({
    firstName: "",
    lastName: "",
    dateOfBirth: "",
    city: "",
    state: "",
    pincode: "",
    email: "",
    phone: "",
    refree: "",
    membershipId: "",
  });

  const [gender, setGender] = useState("Mr");

  const handleGenderChange = (event) => {
    setGender(event.target.value);
  };

  const handleSignIn = () => {
    navigate("/");
  };

  useEffect(() => {
    if (data.pincode && data.pincode.length === 6) {
      const pincodePayload = {
        zipCode: data.pincode,
      };
      AuthApiService.getStateCityByPin(JSON.stringify(pincodePayload))
        .then((res) => {
          const allStates = res.data.map((item) => {
            return item.state;
          });

          setState([...new Set(allStates)]);

          const allCities = res.data.map((item) => {
            return item.city;
          });

          setCity([...new Set(allCities)]);

          setData({
            ...data,
            state: res.data[0]?.state,
            city: res.data[0]?.city,
          });
        })
        .catch((err) => {
          console.log("picode error", err);
        });
    }
  }, [data.pincode]);

  /*
  set data on change input
  */
  const handleOnChange = (e) => {
    if (e.target.name === "phone" || e.target.name === "pincode") {
      setData({
        ...data,
        [e.target.name]: e.target.value.replace(RejexConstants.numRejex, ""),
      });
      setError({ ...error, [e.target.name]: "" });
    } else {
      setData({ ...data, [e.target.name]: e.target.value });
      setError({ ...error, [e.target.name]: "" });
    }
  };

  const handleDateChange = (e) => {
    const selectedDate = new Date(e.target.value);
    const currentDate = new Date();

    if (selectedDate > currentDate) {
      setError({ ...error, dateOfBirth: "Date cannot be in the future" });
    } else {
      setData({ ...data, dateOfBirth: e.target.value });
      setError({ ...error, dateOfBirth: "" });
    }
  };

  /*
  verify referral code function
  */
  const handleVerifyReferralCode = () => {
    app.showLoader(true, "wait for a minute");

    const referralPayload = {
      referralCode: data.refree,
    };

    api
      .post("/referralVerification", referralPayload)
      .then((res) => {
        app.showLoader(false, "wait over");
        setVerified({ ...verified, refree: true });
        setError({ ...error, refree: "" });
      })
      .catch((err) => {
        app.showLoader(false, "wait over");
      });
  };

  /*
  send otp to email function
  */
  const sendOtpToEmail = () => {
    app.showLoader(true, "wait for a minute");

    const emailPayload = {
      email: data.email,
    };

    api
      .post(NetworkConfiguration.SEND_OTP_TO_MAIL, emailPayload)
      .then((res) => {
        setEmailResId(res.data.data);
        setEmailOtpPopup(true);
        app.showLoader(false, "wait over");
      })
      .catch((err) => {
        app.showLoader(false, "wait over");
      });
  };

  /*
  verify email function
  */
  const handleVerifyEmailOtp = () => {
    app.showLoader(true, "wait for a minute");

    const otpPayload = {
      id: emailResId,
      email: data.email,
      otp: emailOtp,
    };

    console.log(otpPayload, "otpPayload");

    api
      .post("/verifyMailOTP", otpPayload)
      .then((res) => {
        setVerified({ ...verified, email: true });
        setError({ ...error, email: "" });
        app.showLoader(false, "wait over");
        setEmailOtpPopup(false);
        // toast.success(res.message);
      })
      .catch((err) => {
        app.showLoader(false, "wait over");
        // toast.error(err.message);
      });
  };

  /*
  send otp to phone function
  */
  const sendOtpToPhone = () => {
    app.showLoader(true, "wait for a minute");

    const phonePayload = {
      phone: data.phone,
    };

    api
      .post(NetworkConfiguration.SEND_OTP_TO_PHONE, phonePayload)
      .then((res) => {
        setError({ ...error, phone: "" });
        setPhoneOtpPopup(true);
        app.showLoader(false, "wait over");
      })
      .catch((err) => {
        app.showLoader(false, "wait over");
      });
  };

  /*
  verify phone function
  */
  const handleVerifyPhoneOtp = () => {
    app.showLoader(true, "wait for a minute");

    const otpPayload = {
      phone: data.phone,
      otp: phoneOtp,
    };

    api
      .post(NetworkConfiguration.VERIFY_PHONE_OTP, otpPayload)
      .then((res) => {
        setVerified({ ...verified, phone: true });
        app.showLoader(false, "wait over");
        setPhoneOtpPopup(false);
      })
      .catch((err) => {
        app.showLoader(false, "wait over");
      });
  };

  /*
  validate function
  */
  const validate = () => {
    let result = true;
    if (!data.refree) {
      setError({ ...error, refree: "Please Enter Referral code" });
      result = false;
    } else if (!verified.refree) {
      setError({ ...error, refree: "Verify Referral code" });
      result = false;
    } else if (gender === "") {
      // setErrorGender("Please Select a gender");
      result = false;
    } else if (!data.firstName) {
      setError({ ...error, firstName: "Please Enter First Name" });
      result = false;
    } else if (RejexConstants.spaceRegex.test(data.firstName)) {
      setError({ ...error, firstName: "Invalid First Name" });
      result = false;
    } else if (!data.lastName) {
      setError({ ...error, lastName: "Please Enter Last Name" });
      result = false;
    } else if (RejexConstants.spaceRegex.test(data.lastName)) {
      setError({ ...error, lastName: "Invalid Last Name" });
      result = false;
    } else if (!data.membershipId) {
      setError({ ...error, membershipId: "Please Create Membership Id" });
      result = false;
    } else if (!verified.membershipId) {
      setError({ ...error, membershipId: "Verify Membership Id" });
      result = false;
    } else if (RejexConstants.spaceRegex.test(data.membershipId)) {
      setError({ ...error, membershipId: "Invalid Membership Id" });
      result = false;
    } else if (!data.dateOfBirth) {
      setError({ ...error, dateOfBirth: "Please Select Date of Birth" });
      result = false;
    } else if (!data.email) {
      setError({ ...error, email: "Please Enter Email Address" });
      result = false;
    } else if (!RejexConstants.emailRegex.test(data?.email)) {
      setError({ ...error, email: "Invalid Email Address" });
      result = false;
    } else if (!verified.email) {
      setError({ ...error, email: "Verify Email Address" });
      result = false;
    } else if (!data.phone) {
      setError({ ...error, phone: "Please Enter Mobile Number" });
      result = false;
    } else if (data.phone.length !== 10) {
      setError({ ...error, phone: "Invalid Mobile Number" });
      result = false;
    } else if (!verified.phone) {
      setError({ ...error, phone: "Verify Mobile Number" });
      result = false;
    } else if (!data.pincode) {
      setError({ ...error, pincode: "Please Enter Pincode" });
      result = false;
    } else if (!data.state) {
      setError({ ...error, state: "Please Enter State" });
      result = false;
    } else if (RejexConstants.spaceRegex.test(data.state)) {
      setError({ ...error, state: "Invalid State" });
      result = false;
    } else if (!data.city) {
      setError({ ...error, city: "Please Enter City" });
      result = false;
    } else if (RejexConstants.spaceRegex.test(data.city)) {
      setError({ ...error, city: "Invalid City" });
      result = false;
    } else if (!isChecked) {
      toast.error("Please Agree Terms & Conditions");
      result = false;
    }

    return result;
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleMailPopUP = () => {};

  const handleRegistration = (e) => {
    e.preventDefault();

    if (validate()) {
      // app.showLoader(true, "wait for a minute");

      const registerPayload = {
        firstName: data.firstName,
        lastName: data.lastName,
        dateOfBirth: new Date(data.dateOfBirth),
        city: data.city,
        state: data.state,
        pincode: data.pincode,
        email: data.email,
        phone: data.phone,
        prefix: gender,
        refree: data.refree,
        referralCode: data.membershipId,
      };

      AuthApiService.registerUser(JSON.stringify(registerPayload))
        .then((res) => {
          // navigate("/");
          setIsModalOpen(true);

          app.showLoader(false, "wait over");
          toast.success(res.message);
          setTimeout(() => {
            navigate("/Login");
            setIsModalOpen(false);
          }, 3000);
        })
        .catch((err) => {
          app.showLoader(false, "wait over");
        });
    }
  };

  const handleVareifyMembershipId = () => {
    api
      .post(NetworkConfiguration.VERIFY_MEMBERSHIP, {
        referralCode: data.membershipId,
      })
      .then((res) => {
        console.log(res, "res");
        setVerified({ ...verified, membershipId: true });
      })
      .catch((err) => {
        setVerified({ ...verified, membershipId: false });
      });
  };

  const onCopy = (e) => {
    e.preventDefault();
  };

  function maxDate() {
    const today = new Date();
    today.setFullYear(today.getFullYear() - 18);
    const maxDate = today.toISOString().split("T")[0];

    return maxDate;
  }

  const date = maxDate();
  const handleNull = () => {
    toast.error("Please Agree Terms & Conditions");
    return;
  };

  return (
    <div className="registration_bg">
      <div className="registration_box">
        <form className="registration_form_bg" onSubmit={handleRegistration}>
          <div className="login_signup_heading" style={{ color: "#A3EC26" }}>
            REGISTRATION
          </div>

          <WebInput
            label=""
            type="text"
            placeholder="Enter Your Referral Code"
            onChange={!verified.refree ? handleOnChange : null}
            error={error.refree}
            value={data.refree}
            style={{
              borderTop: "none",
              borderLeft: "none",
              borderRight: "none",
              borderRadius: 0,
            }}
            name="refree"
            maxLength="20"
            eye={
              !verified.refree && data?.refree?.length > 1 ? (
                <WebButton
                  onClickEvent={handleVerifyReferralCode}
                  text="Verify Code"
                  style={{ zIndex: "111" }}
                  className="package_button"
                  type="button"
                ></WebButton>
              ) : verified.refree ? (
                <MdVerifiedUser color={"#a3ec26"} size={25} />
              ) : null
            }
          />

          {verified.refree && (
            <>
              <div
                className="registration_two_inputs_row"
                style={{
                  alignItems: "flex-end",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    marginBottom: "10px",
                    gap: "20px",
                  }}
                >
                  <label
                    style={{
                      fontSize: "11px",
                      color: "white",
                    }}
                  >
                    <input
                      type="radio"
                      value="Mr"
                      checked={gender === "Mr"}
                      onChange={handleGenderChange}
                    />
                    Mr
                  </label>
                  <label
                    style={{
                      fontSize: "11px",
                      color: "white",
                    }}
                  >
                    <input
                      type="radio"
                      value="Mrs"
                      checked={gender === "Mrs"}
                      onChange={handleGenderChange}
                    />
                    Mrs
                  </label>
                  <label
                    style={{
                      fontSize: "11px",
                      color: "white",
                    }}
                  >
                    <input
                      type="radio"
                      value="Miss"
                      checked={gender === "Miss"}
                      onChange={handleGenderChange}
                    />
                    Miss
                  </label>
                </div>

                <WebInput
                  type="text"
                  placeholder="Enter Your First Name"
                  onChange={handleOnChange}
                  error={error.firstName}
                  value={data.firstName}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderRadius: 0,
                  }}
                  name="firstName"
                  maxLength="40"
                />

                <WebInput
                  label=""
                  type="text"
                  placeholder="Enter Your Last Name"
                  onChange={handleOnChange}
                  error={error.lastName}
                  value={data.lastName}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderRadius: 0,
                  }}
                  name="lastName"
                  maxLength="40"
                />
              </div>

              <div className="registration_two_inputs_row">
                <WebInput
                  label=""
                  type="text"
                  placeholder={
                    //Suggest some membership id
                    data.firstName
                      ? data.firstName + Math.floor(Math.random() * 1000)
                      : "Enter Membership Id"
                  }
                  onChange={handleOnChange}
                  error={error.membershipId}
                  value={data.membershipId}
                  readOnly={verified.membershipId}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderRadius: 0,
                  }}
                  name="membershipId"
                  maxLength="20"
                  eye={
                    !verified.membershipId && data?.membershipId?.length > 1 ? (
                      <WebButton
                        onClickEvent={handleVareifyMembershipId}
                        text="Verify"
                        style={{ zIndex: "111" }}
                        className="package_button"
                        type="button"
                      ></WebButton>
                    ) : verified.membershipId ? (
                      <MdVerifiedUser color={AppColor.primaryColor} size={25} />
                    ) : null
                  }
                />

                <WebInput
                  label=""
                  type="date"
                  placeholder="Enter Your Date of Birth"
                  onChange={handleDateChange}
                  error={error.dateOfBirth}
                  value={data.dateOfBirth}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderRadius: 0,
                  }}
                  max={date}
                  name="dateOfBirth"
                />
              </div>

              <WebInput
                label=""
                type="text"
                placeholder="Enter Your Email Address"
                onChange={!verified.email ? handleOnChange : null}
                error={error.email}
                value={data.email}
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderRadius: 0,
                }}
                name="email"
                maxLength="70"
                eye={
                  !verified.email &&
                  data?.email &&
                  RejexConstants.emailRegex.test(data?.email) ? (
                    <WebButton
                      onClickEvent={sendOtpToEmail}
                      text="Verify"
                      style={{ zIndex: "111" }}
                      className="package_button"
                      type="button"
                    ></WebButton>
                  ) : verified.email ? (
                    <MdVerifiedUser color={AppColor.primaryColor} size={25} />
                  ) : null
                }
              />

              <WebInput
                label=""
                type="text"
                placeholder="Enter Your Mobile Number"
                onChange={!verified.phone ? handleOnChange : null}
                error={error.phone}
                value={data.phone}
                style={{
                  borderTop: "none",
                  borderLeft: "none",
                  borderRight: "none",
                  borderRadius: 0,
                }}
                name="phone"
                maxLength="10"
                eye={
                  !verified.phone && data?.phone && data.phone.length === 10 ? (
                    <WebButton
                      onClickEvent={sendOtpToPhone}
                      text="Verify"
                      style={{ zIndex: "111" }}
                      className="package_button"
                      type="button"
                    ></WebButton>
                  ) : verified.phone ? (
                    <MdVerifiedUser color={AppColor.primaryColor} size={25} />
                  ) : null
                }
              />

              <div className="registration_two_inputs_row">
                <WebInput
                  label=""
                  type="text"
                  placeholder="Pincode"
                  onChange={handleOnChange}
                  error={error.pincode}
                  value={data.pincode}
                  style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none",
                    borderRadius: 0,
                  }}
                  name="pincode"
                  maxLength="6"
                />

                <div className="dropdown_parent_div">
                  <lable className="label_style"></lable>
                  <div
                    className="dropdown_wrapper"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      borderRadius: 0,
                    }}
                  >
                    <select
                      style={{ color: "white" }}
                      className="web_dropdown"
                      onChange={handleOnChange}
                      name="state"
                      value={data.state === "" ? "Select State" : data.state}
                      // onClick={onClick}
                    >
                      {state?.map((item, index) => {
                        return (
                          <option key={index} value={item}>
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
                <div className="dropdown_parent_div">
                  <lable className="label_style"></lable>
                  <div
                    className="dropdown_wrapper"
                    style={{
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      borderRadius: 0,
                    }}
                  >
                    <select
                      style={{ color: "white" }}
                      className="web_dropdown"
                      onChange={handleOnChange}
                      name="city"
                      value={data.city === "" ? "Select city" : data.city}
                      // onClick={onClick}
                    >
                      {city?.map((item, index) => {
                        return (
                          <option
                            key={index}
                            value={item}
                            style={{ color: "white", background: "gray" }}
                          >
                            {item}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "10px",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div>
                  <label class="container" />
                  <input
                    style={{ color: "ffffff" }}
                    type="checkbox"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                  />
                  <span style={{ color: "white" }} class="checkmark"></span>
                </div>

                <div>
                  <p style={{ color: "#ffffff" }}>
                    I Agree with the company terms & condition
                  </p>
                </div>
              </div>

              <WebButton
                // disabled={!isChecked}
                onClickEvent={handleRegistration}
                text="REGISTER"
                style={{ marginTop: "20px", width: "200px" }}
                className="submit_btn"
                type={
                  !verified.refree ||
                  !verified.email ||
                  !verified.phone ||
                  !verified.membershipId
                    ? "button"
                    : "submit"
                }
              />
            </>
          )}
          <p className="login_signUp_bottom_msg">
            Already Registered? <span onClick={handleSignIn}>Sign In</span>
          </p>
        </form>
      </div>

      {emailOtpPopup ? (
        <WebVerifyOtp
          message="Verification code sent to your email"
          setOtpValue={setEmailOtp}
          verifyOtp={handleVerifyEmailOtp}
          resendOtp={sendOtpToEmail}
          close={() => setEmailOtpPopup(false)}
        />
      ) : null}

      {phoneOtpPopup ? (
        <WebVerifyOtp
          message="Verification code sent to your Number"
          setOtpValue={setPhoneOtp}
          verifyOtp={handleVerifyPhoneOtp}
          resendOtp={sendOtpToPhone}
          close={() => setPhoneOtpPopup(false)}
        />
      ) : null}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
        style={{
          overlay: {},
          content: {
            backgroundColor: "white",
            width: "50%",
            margin: "auto",
            height: "max-content",
            border: "1px solid #ccc",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              borderBottom: "1px solid #ccc",
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 style={{ margin: 0 }}>Referral Code</h2>
            <p
              onClick={() => setIsModalOpen(false)}
              style={{
                border: "none",
                cursor: "pointer",
                fontSize: "1.2rem",
              }}
            >
              X
            </p>
          </div>

          <div className="modal-body" style={{ padding: "20px" }}>
            <div>Your Password has been sent to your email.</div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
