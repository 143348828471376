/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { RiTeamLine } from "react-icons/ri";
import {
  ButtonFacebook,
  ButtonLinkedIn,
  ButtonTwitter,
  ButtonWhatsapp,
} from "share-button-links-react";

import ReactApexChart from "react-apexcharts";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BsFillPatchCheckFill, BsWhatsapp } from "react-icons/bs";
import { FaFacebookF, FaTwitter } from "react-icons/fa6";
import { GrLinkedinOption } from "react-icons/gr";
import { MdContentCopy } from "react-icons/md";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { DataTable } from "../../components/common/table/Data-Table";
import Loading from "../../components/library/loader/loader";
import WebButton from "../../components/library/webButton";
import api from "../../config/apiConfig";
import useGetNetworks from "../../hooks/networks/getNetworks.hook";
import useGetGraphData from "../../hooks/user/dashboard.hook";
import useSetPassword from "../../hooks/user/setFirstTimePassword";
import useGetUserProfile from "./../../hooks/user/getUserProfile.hook";
import { columns } from "./columns";
import "./style.css";
import Coin from "../../assests/images/Crypto-Coin-hour-glass.png";
import BitCoin from "../../assests/images/new st-coin-shine.png";
import image from "../../assests/images/new st-coin-shine.png";
import image2 from "../../assests/images/new st-coin-shine.png";
import image3 from "../../assests/images/new st-coin-shine.png";

import { Dialog, DialogContent, DialogTitle, Typography } from "@mui/material";
import axios from "axios";
import { APIUrl } from "../../network/NetworkConfriguation";
import { getLocalStorage } from "../../storage/LocalStorage";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import dj from "../../assests/images/ST-New.png"; // Import eye icons
import CandleChart from "./CandleChart";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "30%",
    // height: "50%",
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "20px",
  },
};

export default function Dashboard() {
  const [copyMessage, setCopyMessage] = useState(false);
  const [url, setUrl] = useState("");
  const [newPassword, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [addCoinPopup, setAddCoinPopup] = useState(false);
  const [feepopup, setFeePopup] = useState(false);
  const [alertPopup, setAlertPopup] = useState(false);
  const [activationFee, setActivationFee] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  // State to control password visibility

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword); // Toggle the state
  };
  const requestHeaders = {};
  const token = getLocalStorage();
  const userToken = token.loginHeader;

  requestHeaders["Authorization"] = `Bearer ${userToken}`;

  const handleAddCoin = () => {
    setAddCoinPopup(true);
  };

  const handleAddCoinClose = () => {
    setAddCoinPopup(true);
  };

  // const addFeePopup = () => {
  //   setFeePopup(true);
  // };

  const handleFeeClose = () => {
    setFeePopup(true);
  };

  const handleActivate = () => {
    setAddCoinPopup(false); // Close the add coin popup
    setFeePopup(true); // Open the fee modal
    setAlertPopup(false);
  };

  const handleAlertClose = () => {
    setAlertPopup(true);
  };

  const handleActivationFee = () => {
    axios
      .get(APIUrl + "/activationFee", { headers: requestHeaders })

      .then((res) => {
        console.log(res.data);
      })

      .catch((err) => {
        console.log(err);
        if (err.response.status === 444) {
          setAddCoinPopup(true);
        }
        if (err.response.status === 555) {
          setAlertPopup(true);
        }
      });
  };
  const handleActivationFee1 = () => {
    axios
      .get(APIUrl + "/activationFee1")

      .then((res) => {
        console.log(res.data);
        setActivationFee(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const handlefeePayment = () => {
    axios
      .post(
        APIUrl + "/activate",
        {},
        {
          headers: requestHeaders,
        }
      )

      .then((res) => {
        console.log(res.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleActivationFee();
    handleActivationFee1();
  }, []);

  useEffect(() => {
    const updateToken = async () => {
      await api.put("/fcm", {
        token: localStorage.getItem("fcmToken")
          ? localStorage.getItem("fcmToken")
          : "",
      });
    };
    updateToken();
  }, []);
  const navigate = useNavigate();
  const [isPasswordSet, setIsPasswordSet] = useState(false);
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const setFirtTimePassword = useSetPassword();

  const handleCopied = () => {
    setCopyMessage(true);
    setTimeout(() => {
      setCopyMessage(false);
    }, [2000]);
  };

  const userProfile = useGetUserProfile();
  const { directNetwork } = useGetNetworks();

  const userProfileData = userProfile.data;
  // console.log(userProfile, "ppppppppppppp");
  const directData = directNetwork.data?.data;

  const { graphData } = useGetGraphData();

  const { downlineNetworks } = useGetNetworks();

  const downlineNetwork = downlineNetworks?.data?.data;

  useEffect(() => {
    setUrl(
      `https://smarttreasure.net/registration/?referalCode=${userProfileData?.user?.referralCode}`
    );

    if (userProfileData?.user?.firstTimeLogin) setIsPasswordSet(true);
  }, [userProfileData]);

  const formattedData = graphData.data?.map((item) => ({
    x: new Date(item.createdAt).getTime(),
    y: item._sum.amount,
  }));

  const options = {
    chart: {
      // height: 350,
      type: "area",
      background: "#191919",
      style: {
        boxShadow: "2px 2px 3px 15px black",
      },
    },

    colors: ["#a3ec26"],
    title: {
      text: "Earnings",
      align: "left",
      style: {
        color: "#a3ec26",
        fontSize: "30px",
      },
    },

    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        inverseColors: false,
        opacityFrom: 1,
        opacityTo: 0,
        stops: [0, 90, 100],
      },
    },
    dataLabels: {
      enabled: false,
      color: ["#a3ec26"],
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      // color: ["#a3ec26"],
    },
    tooltip: {
      x: {
        format: "dd/MM/yy",
      },
    },
  };

  const series = [
    {
      name: "Amount",
      data: formattedData,
    },
  ];

  if (
    userProfile.isLoading ||
    userProfile.isPending ||
    directNetwork.isLoading ||
    directNetwork.isPending ||
    graphData.isLoading ||
    graphData.isPending
  ) {
    return <Loading />;
  }
  return (
    <>
      <div
        className={
          addCoinPopup || feepopup || alertPopup ? "blurred-background" : ""
        }
      >
        <Dialog open={addCoinPopup} onClose={handleAddCoinClose}>
          <div>
            <div>
              <DialogTitle
                style={{
                  textAlign: "center",
                  backgroundColor: "#313135",
                  color: "white",
                }}
              >
                Your Account Has Been Suspended
              </DialogTitle>
            </div>
          </div>

          <DialogContent
            style={{
              textAlign: "center",
              backgroundColor: "#313135",
              color: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                type="btn"
                style={{
                  backgroundColor: "#a3ec26",
                  color: "white",
                  width: "10vw",
                  height: "5vh",
                  cursor: "pointer",
                }}
                onClick={handleActivate}
              >
                Activate
              </button>

              <button
                onClick={() => navigate("/Login")}
                type="btn"
                style={{
                  backgroundColor: "#4A4A4F",
                  color: "white",
                  width: "10vw",
                  height: "5vh",
                  marginTop: "1rem",
                  cursor: "pointer",
                }}
              >
                Later
              </button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={feepopup} onClose={handleFeeClose}>
          <div>
            <div>
              <DialogTitle
                style={{
                  textAlign: "center",
                  backgroundColor: "#313135",
                  color: "white",
                  width: "20vw",
                  height: "8vh",
                }}
              >
                Fee : {activationFee?.data?.fee}
              </DialogTitle>
            </div>
          </div>

          <DialogContent
            style={{
              textAlign: "center",
              backgroundColor: "#313135",
              color: "white",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                type="btn"
                style={{
                  backgroundColor: "#a3ec26",
                  color: "white",
                  width: "10vw",
                  height: "5vh",
                  cursor: "pointer",
                }}
                onClick={handlefeePayment}
              >
                PAY
              </button>
            </div>
          </DialogContent>
        </Dialog>
        <Dialog open={alertPopup} onClose={handleAlertClose}>
          <div>
            <div>
              <DialogTitle
                style={{
                  textAlign: "center",
                  backgroundColor: "#a3ec26",
                  color: "white",
                  width: "36vw",
                  height: "15vh",
                }}
              >
                <p
                  style={{
                    color: "#ffffff",
                    fontSize: "22px",
                  }}
                >
                  Your Account Will Be Activated With in 24 Hours
                </p>
              </DialogTitle>
            </div>
          </div>
        </Dialog>
      </div>
      <div className="dashboard_bg">
        <div className="dashboard_value_header_row">
          <div className="dashboard_value_header_column">
            <span>
              <img src={BitCoin} alt="" className="bit_coin_img" />
            </span>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <p style={{ fontWeight: "bold" }}>Upline Members</p>
              <p style={{ textTransform: "capitalize", color: "white" }}>
                {userProfileData && userProfileData?.upline
                  ? userProfileData.upline
                  : "not found"}
              </p>
              {/* <div>
                <p style={{ fontSize: "25px" }}>$200.10</p>
              </div> 

              <div>
                <p>+0.8%</p>
              </div> */}
            </div>
          </div>

          {/* <div className="dashboard_value_header_column">
            <p>Value in Usd</p>
            <p>
              {userProfileData && userProfileData?.valueInUsd
                ? userProfileData.valueInUsd.toFixed(4)
                : 0}
            </p>
          </div> */}
          {/* <div className="dashboard_value_header_column">
            <p>Value in Trading</p>
            <p>
              {userProfileData && userProfileData?.value
                ? userProfileData?.value
                : 0}
            </p>
          </div> */}

          <div
            onClick={() => navigate("/STWallet")}
            className="dashboard_value_header_column"
            style={{
              cursor: "pointer",
            }}
          >
            <span>
              <img src={image} alt="" className="bit_coin_img" />
            </span>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <p style={{ fontWeight: "bold" }}>ST Wallet</p>
              <p style={{ color: "white" }}>
                {userProfileData && userProfileData?.user?.coinQuantity
                  ? userProfileData?.user?.coinQuantity
                  : 0}
              </p>
              d
            </div>
          </div>
          <div
            onClick={() => navigate("/network?redirect=true")}
            className="dashboard_value_header_column"
            style={{
              cursor: "pointer",
            }}
          >
            <span>
              <img src={image2} alt="" className="bit_coin_img" />
            </span>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <p style={{ fontWeight: "bold" }}>Downline Count</p>
              <p style={{ color: "white" }}>
                {downlineNetwork && downlineNetwork?.length > 0
                  ? downlineNetwork.length
                  : 0}
              </p>
              {/* <div>
                <p style={{ fontSize: "25px" }}>$450.10</p>
              </div>
              <div>
                <p>+2.10%</p>
              </div> */}
            </div>
          </div>

          {/* <div className="dashboard_value_header_column">
            <p>Total Active Contracts</p>
            <p>
              {userProfileData && userProfileData?.activeContracts
                ? userProfileData.activeContracts
                : 0}
            </p>
          </div> */}

          <div
            onClick={() => navigate("/contracts")}
            className="dashboard_value_header_column"
            style={{
              cursor: "pointer",
            }}
          >
            <span>
              <img src={image3} alt="" className="bit_coin_img" />
            </span>
            <div
              style={{
                marginTop: "10px",
              }}
            >
              <p style={{ fontWeight: "bold" }}>Active Contracts</p>
              <p style={{ color: "white" }}>
                {userProfileData && userProfileData?.activeContracts
                  ? userProfileData.activeContracts
                  : 0}
              </p>
            </div>
          </div>
        </div>

        <div className="mt-1rem">
          <CandleChart />
        </div>

        <div className="graph_refral">
          <div className="dashboard_chart scrollbar-hide">
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height={275}
            />
          </div>

          <div className="coin_images">
            <div className="refral_link_new_ui">
              <div className="dashboard_referal_code_row">
                <div
                  style={{ color: "#FFFFFF" }}
                  className="dashboard_referal_code_head"
                >
                  Your Referal Link
                </div>
                <div className="dashboard_referal_code_link green_gradient">
                  <span>{url ? url : "referal link not found"}</span>
                  <CopyToClipboard text={url} onCopy={() => handleCopied()}>
                    <MdContentCopy className="referal_link_copy_icon" />
                  </CopyToClipboard>
                  {copyMessage ? (
                    <div className="copied_message">
                      Copied
                      <span>
                        <BsFillPatchCheckFill />
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                <div className="dashboard_referal_code_social_row">
                  <div
                    style={{ color: "#FFFFFF" }}
                    className="dashboard_referal_code_head"
                  >
                    Share Your Referal Link Via
                  </div>

                  <div className="share_btns">
                    <div>
                      <ButtonFacebook
                        title="Referral Code"
                        text={
                          <p>
                            <FaFacebookF size={20} />
                          </p>
                        }
                        url={url}
                        isRounded
                        hasIcon
                      />
                    </div>
                    <div>
                      <ButtonLinkedIn
                        title="Referral Code"
                        text={
                          <p>
                            <GrLinkedinOption size={20} />
                          </p>
                        }
                        url={url}
                        isRounded
                        hasIcon
                      />
                    </div>
                    <div>
                      <ButtonTwitter
                        title="Referral Code"
                        text={
                          <p>
                            <FaTwitter size={20} />
                          </p>
                        }
                        url={url}
                        isRounded
                        hasIcon
                      />
                    </div>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <ButtonWhatsapp
                        title="ReferralCode"
                        text={
                          <p>
                            <BsWhatsapp size={20} />
                          </p>
                        }
                        url={url}
                        isRounded
                        hasIcon
                      />
                      <a
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        href={`https://api.whatsapp.com/send?text=${
                          userProfileData?.user?.firstName +
                          " " +
                          userProfileData?.user?.lastName +
                          " " +
                          "has invited you to join Trading User. Copy the link to join" +
                          " " +
                          url
                        } `}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {/* <BsWhatsapp style={{ color: "white" }} size={20} /> */}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="coin_image">
              <img src={Coin} alt="" className="image_coin" />
            </div>
          </div>
        </div>
        <div className="table_available_balnc"
          style={{
            display: "flex",
            justifyContent: "space-between",
            gap: "20px",
          }}
        >
          <div className="responsive_last_div">
            <Typography style={{ color: "#a3ec26" }} variant="h6" gutterBottom>
              Recent Trading Activities
            </Typography>
            {directData && <DataTable columns={columns} data={directData} />}
          </div>

          <div className="available_balance">
            <div className="available_balance_coin">
              <h3 style={{ color: "WHITE" }}>YOUR AVAILABLE BALANCE</h3>
              <p
                style={{
                  color: "#a3ec26",
                  textAlign: "center",
                  fontSize: "30px",
                  fontWeight: "bold",
                }}
              >
                {userProfile?.data?.user?.balance}
              </p>
              <img src={dj} alt="dj wala" className="dj_image" />
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={isPasswordSet}
        onRequestClose={() => setIsPasswordSet(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ textAlign: "center", padding: "10px" }}>
          <h2>Welcome</h2>
          <p
            style={{
              color: "#333",
              fontSize: "16px",
              // fontWeight: "500",
              marginBlock: "10px",
            }}
          >
            You have not set your password yet. Please set your password to
            continue. You can set your password by clicking on the link below or
            from your profile section.
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBlock: "10px",
            }}
          >
            <WebButton
              onClickEvent={() => {
                // window.location.href = "/changePassword";
                // navigate("/changePassword");
                setIsPasswordModalOpen(true);
                setIsPasswordSet(false); // Close this modal
              }}
              text="Change Password"
              style={{
                width: "150px",
                margin: "10px",
                color: "white",
                border: "none",
                borderRadius: "5px",
                padding: "10px",
                cursor: "pointer",
              }}
              className="submit_btn"
              type="button"
            />
            <WebButton
              onClickEvent={() => {
                setIsPasswordSet(false);
              }}
              text="Close"
              style={{
                width: "150px",
                margin: "10px",
                backgroundColor: "white",
                color: "#333",
                border: "1px solid #333",
                borderRadius: "5px",
                padding: "10px",
                cursor: "pointer",
              }}
              className="submit_btn"
              type="button"
            />
          </div>
          {/* <div style={{ position: "relative" }}>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              style={{ padding: "10px", width: "100%", borderRadius: "5px" }}
            />
            <div
              style={{
                position: "absolute",
                top: "50%",
                right: "10px",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
              onClick={togglePasswordVisibility}
            >

              {showPassword ? (
                <FaEyeSlash style={{ color: "black" }} />
              ) : (
                <FaEye />
              )}
            </div>
          </div> */}
        </div>
      </Modal>

      <Modal
        isOpen={isPasswordModalOpen}
        onRequestClose={() => setIsPasswordModalOpen(false)}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div style={{ textAlign: "center", padding: "10px" }}>
          <h2>Set Password</h2>
          <p
            style={{
              color: "#333",
              fontSize: "16px",
              // fontWeight: "500",
              marginBlock: "10px",
            }}
          >
            Please set your password to continue.
          </p>
          <div
            style={{
              marginBlock: "10px",
              position: "relative",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <input
              type={showPassword1 ? "text" : "password"}
              placeholder="Password"
              style={{
                margin: "10px",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #333",
              }}
              value={newPassword}
              onChange={(e) => setPassword(e.target.value)}
            />
            <span
              onClick={() => setShowPassword2(!showPassword2)}
              style={{
                position: "absolute",
                right: "40px",
                top: "74%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              {showPassword2 ? <FaEyeSlash /> : <FaEye />}
            </span>

            <input
              type={showPassword2 ? "text" : "password"}
              placeholder="Confirm Password"
              style={{
                margin: "10px",
                padding: "10px",
                borderRadius: "5px",
                border: "1px solid #333",
              }}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <span
              onClick={() => {
                console.log("passwordtwoclicked");
                setShowPassword1(!showPassword1);
              }}
              style={{
                position: "absolute",
                right: "40px",
                top: "25%",
                transform: "translateY(-50%)",
                cursor: "pointer",
              }}
            >
              {showPassword1 ? <FaEyeSlash /> : <FaEye />}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBlock: "10px",
            }}
          >
            <WebButton
              onClickEvent={() => {
                // window.location.href = "/changePassword";
                // navigate("/changePassword");
                // setIsPasswordModalOpen(true);
                setIsPasswordModalOpen(false);
                setIsPasswordSet(false);

                setFirtTimePassword.mutate({
                  newPassword,
                  confirmPassword,
                });
              }}
              text="Change Password"
              style={{
                width: "150px",
                margin: "10px",
                color: "white",
                fontSize: "12px",
                border: "none",
                borderRadius: "5px",
                padding: "10px",
                cursor: "pointer",
              }}
              className="submit_btn"
              type="button"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
