import api from "../config/apiConfig";
import { APIUrl, NetworkConfiguration } from "../network/NetworkConfriguation";

const fetchDirectNetwork = async () => {
  const res = await api.get(NetworkConfiguration.GET_DIRECT_NETWORKS);
  return res.data;
};

const fetchDownlineNetwork = async () => {
  const res = await api.get(NetworkConfiguration.GET_DOWNLINE_NETWORK);
  return res.data;
};

export const NetworkApiService = {
  fetchDirectNetwork,
  fetchDownlineNetwork,
};
