import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppFonts } from "../../../assests/fonts/fonts";
import { useApp } from "../../../base/context/AppProviders";
import WebButton from "../../../components/library/webButton";
import WebDropdown from "../../../components/library/webDropdown";
import WebTextArea from "../../../components/library/webTextArea";
import { getSupportCategory } from "../../../redux/actions";
import { SupportApiService } from "../../../service/supportApiService";
import "./style.css";
import { colors } from "@mui/material";

export default function CreateTicket() {
  const [data, setData] = useState({
    issue: "",
    description: "",
  });

  const [error, setError] = useState({
    issue: "",
    description: "",
  });

  const [categories, setCategories] = useState();

  const app = useApp();
  const navigate = useNavigate();

  const supportCategory = useSelector(
    (state) => state.appReducers.supportCategory
  );

  useEffect(() => {
    const array = [];
    supportCategory.map((item) => {
      array.push({
        name: item.name,
        id: item.id,
      });
    });
    setCategories(array);
  }, [supportCategory]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSupportCategory());
  }, []);

  const handleIssue = (e) => {
    setData({ ...data, issue: e.target.value });
  };

  const handleDescription = (e) => {
    setData({ ...data, description: e.target.value });
  };

  const validate = () => {
    let result = true;

    if (!data.issue) {
      setError({ ...error, issue: "Invalid Category" });
      result = false;
    } else if (!data.description) {
      setError({ ...error, description: "Invalid Description" });
      result = false;
    }

    return result;
  };

  const handleRaiseTicket = () => {
    if (validate()) {
      app.showLoader(true, "wait for a minute");

      SupportApiService.raiseTicket(JSON.stringify(data))
        .then((res) => {
          navigate("/support/myTickets");
          app.showLoader(false, "wait over");
          // message.showToast(res.message, ToastTypes.SUCCESS);
          toast.success(res.message);
          setData({ issue: "", description: "" });
        })
        .catch((err) => {
          err.then((e) => {
            app.showLoader(false, "wait over");
            toast.error(e.message);
          });
        });
    }
  };

  return (
    <div className="create_new_ticket_box">
      <div className="create_new_ticket_row">
        <WebDropdown
          label="ISSUES"
          placeholder="select issues"
          onChange={handleIssue}
          error={error.issue}
          value={data.issue}
          style={{
            background: "#373737",
            boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.2)",
            border: "none",
            borderRadius: "0",
          }}
          labelStyle={{ fontWeight: AppFonts.fontSemiBold }}
          options={categories}
        />
      </div>
      <div className="create_new_ticket_row">
        <WebTextArea
          label="DETAILED INFORMATION"
          type="text"
          onChange={handleDescription}
          error={error.description}
          value={data.description}
          style={{
            background: "#373737",
            boxShadow: "0px 1px 2px 0px rgba(0,0,0,0.2)",
            border: "none",
            borderRadius: "0",
          }}
          labelStyle={{ fontWeight: AppFonts.fontSemiBold }}
        />
      </div>

      <WebButton
        onClickEvent={handleRaiseTicket}
        text="Create Ticket"
        style={null}
        className="submit_btn"
        type="button"
      />
    </div>
  );
}
