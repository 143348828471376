import React, { useState } from "react";
import Modal from "react-modal";
import { DataTable } from "../../components/common/table/Data-Table";
import Loading from "../../components/library/loader/loader";
import WebButton from "../../components/library/webButton";
import useEarningWallet from "../../hooks/user/getEarningWallet.hook";
import useGetUserProfile from "../../hooks/user/getUserProfile.hook";
import { WithdrawPopup } from "./WithdrawPopup";
import { columns } from "./columns";
import "./style.css";
import RefralLinks from "../../components/RefralLinks/index";
import withdrawlableicon from "../../assests/images/withdraw.png";
import Escrowicon from "../../assests/images/escrow balance.png";
import finalbalanceicon from "../../assests/images/final balance.png";
import { useNavigate } from "react-router-dom";

export const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};

Modal.setAppElement("#root");

export default function EarningWallet() {
  const [withdrawPopup, setWithdrawPopup] = useState(false);
  const earningWallet = useEarningWallet();
  const userProfile = useGetUserProfile();
  const navigate = useNavigate();

  const handleWithdraw = () => {
    setWithdrawPopup(true);
  };

  if (
    earningWallet.isLoading ||
    earningWallet.isPending ||
    userProfile.isLoading ||
    userProfile.isPending
  ) {
    return <Loading />;
  }

  return (
    <>
      <div className="main_responive" style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <div className="earning_wallet_header_row">
            <div className="earning_wallet_header_col">
              <div className="withdraw_icon_div">
                <img
                  src={withdrawlableicon}
                  alt=""
                  className="earning_images"
                />
              </div>

              <div>
                <p>WITHDRAWABLE BALANCE</p>
                <p>{earningWallet.data?.withdrawableBalance}</p>
              </div>
            </div>
            <div className="earning_wallet_header_col">
              <div className="withdraw_icon_div">
                <img src={Escrowicon} alt="" className="escrow_images" />
              </div>

              <div>
                <p>ESCROW BALANCE</p>
                <p>{earningWallet.data?.escrowBalance}</p>
              </div>
            </div>
            <div className="earning_wallet_header_col">
              <div className="withdraw_icon_div">
                <img src={finalbalanceicon} alt="" className="final_images" />
              </div>

              <div>
                <p>FINAL BALANCE</p>
                <p>{userProfile?.data?.user?.balance}</p>
              </div>
            </div>
          </div>

          <div className="widthdrawl_btnnn_earningwallet">
            {window.innerWidth > 576 && (
              <WebButton
                onClickEvent={handleWithdraw}
                text="WITHDRAW"
                style={null}
                className="green_gradient_button "
              />
            )}
          </div>

          <div className="withdraw_btn_on_mobile">
            {window.innerWidth <= 576 && (
              <WebButton
                onClickEvent={handleWithdraw}
                text="WITHDRAW"
                style={null}
                className="widthdraw_btn"
              />
            )}
          </div>
        </div>
        <div style={{ marginLeft: "5rem" }}>
          <RefralLinks />
        </div>
      </div>

      <DataTable columns={columns} data={earningWallet.data?.incomes} />

      <div
        style={{
          fontSize: "11px",
          color: "#fff",
          marginTop: "10px",
        }}
      >
        <span
          style={{
            fontWeight: "bold",
            color: "#fff",
          }}
        >
          Note :
        </span>{" "}
        Each transaction except STB, you will be charged 10% for the Admin
        Charge & Service fees
      </div>

      {withdrawPopup && (
        <WithdrawPopup setIsOpen={setWithdrawPopup} isOpen={withdrawPopup} />
      )}
    </>
  );
}
