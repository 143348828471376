import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { RewardsApiService } from "../../service/rewardsApiService";

const useGetAllRewards = () => {
  const queryClient = useQueryClient();

  const getAllRewards = useQuery({
    queryKey: "getAllRewards",
    queryFn: RewardsApiService.getRewards,
  });

  const claimReward = useMutation({
    mutationFn: RewardsApiService.claimReward,

    onError: (error) => {
      console.error("Error in claim reward", error);
    },

    onSuccess: () => {
      queryClient.invalidateQueries("getAllRewards");
      console.log("Claim reward success");
    },
  });

  return { getAllRewards, claimReward };
};

export default useGetAllRewards;
