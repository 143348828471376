import React, { useState } from "react";
import { DateFormat } from "../../helper/utils";
import useGetNotification from "../../hooks/notification/getNotification.hook";
import "./style.css";
import api from "../../config/apiConfig";

export default function Notification() {
  const { notification } = useGetNotification();

  const [activeId, setActiveId] = useState();
  const [title, setTitle] = useState();
  const [body, setBody] = useState();

  const handleNotification = (id, title, body) => {
    setActiveId(id);
    setTitle(title);
    setBody(body);
  };

  const handleClearallNotification = () => {
    api
    .delete("/clearAll" , {

    })

    .then((res) => {
      console.log(res.data.result);
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    
    })

    .catch((err) => {
      console.log(err)
    })
  }

 
  return (
    <div className="notification_box">
      <div className="notification_list_box">
        <div className="notification_heading_row">
          <div>

          <p>All Notifications</p>
          </div>
          <div>
          <button
          onClick={handleClearallNotification}
          type="btn_clearall" className="clearall_noti_btn">Clear All</button>

          </div>
         
        </div>
        <div
          style={{
            height: "400px",
            overflowY: "auto",
            padding: "0 10px",
          }}
        >
          {notification?.data?.data?.notification?.map((item) => {
            return (
              <NotificationItem
                head={item.body}
                para={item.title}
                status={item.isRead ? "read" : "unread"}
                handleNotification={() =>
                  handleNotification(item.id, item.body, item.title)
                }
                active={activeId}
                id={item.id}
                key={item.id}
                date={item.createdAt}
              />
            );
          })}
        </div>

        {/* <NotificationItem
          head="heading"
          para="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,"
          status="read"
          handleNotification={() => handleNotification("2")}
          active={activeId}
          id="2"
        />
        <NotificationItem
          head="heading"
          para="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,"
          status="unread"
          handleNotification={() => handleNotification("3")}
          active={activeId}
          id="3"
        />
        <NotificationItem
          head="heading"
          para="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s,"
          status="unread"
          handleNotification={() => handleNotification("4")}
          active={activeId}
          id="4"
          date="2021-09-10T05:00:00.000Z"
        /> */}
      </div>
      <div className="notification_description_box">
        <p>{title}</p>
        <p>{body}</p>
      </div>
    </div>
  );
}

function NotificationItem({
  active,
  head,
  para,
  status,
  handleNotification,
  id,
  date,
}) {
  const handleUpdate = async (id) => {
    try {
      const res = await api.put("/notification", { id });
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div
      id={id}
      className={`${
        status === "read" ? "notification_read" : "notification_unread"
      } ${active === id ? "notification_active" : ""}`}
      onClick={() => {
        handleNotification()
      handleUpdate(id);
    }}
    >
      <p className="notification_heading">
        <span>{head}</span> <span>{DateFormat(date)}</span>
      </p>
      <p className="notification_paragraph">{para}</p>
    </div>
  );
}
