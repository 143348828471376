import React, { useState, useEffect } from "react";
import { SidebarUtility } from "../../../helper/utils";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.css";
import Tradinglogo from "../../../assests/images/logo.png";

export default function Sidebar() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => {
      window.removeEventListener('resize', checkIfMobile);
    };
  }, []);

  const handlePages = (link) => {
    navigate(link);
    if (isMobile) setIsOpen(false);
  };

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      {isMobile && (
        <button className="toggle-btn" onClick={toggleSidebar}>
          {isOpen ? '✕' : '☰'}
        </button>
      )}
      <div className={`sidebar_bg scrollbar-hide ${isMobile && isOpen ? 'open' : ''} ${isMobile ? 'mobile' : ''}`}>
        <div className="sidebar_header">
          <img src={Tradinglogo} alt="" className="trading_logo" />
        </div>

        {SidebarUtility.map((item, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <div
                className={
                  location.pathname.split("/")[1].toLowerCase() ===
                  item.name.toLowerCase()
                    ? "active_border"
                    : null
                }
              ></div>
              <div
                className={"sidebar_item_box"}
                onClick={() => handlePages(item.link)}
                style={{
                  boxShadow:
                    location.pathname.split("/")[1].toLowerCase() ===
                    item.name.toLowerCase()
                      ? "0 0 5px 0 #0000002c"
                      : null,
                  backgroundColor:
                    location.pathname.split("/")[1].toLowerCase() ===
                    item.name.toLowerCase()
                      ? "black"
                      : null,
                }}
              >
                <img
                  src={
                    location.pathname.split("/")[1].toLowerCase() ===
                    item.name.toLowerCase()
                      ? item.iconActive
                      : item.icon
                  }
                  alt="icon"
                  className="sidebar_icon"
                />
                <p
                  style={{
                    fontWeight:
                      location.pathname.split("/")[1].toLowerCase() ===
                      item.name.toLowerCase()
                        ? "bold"
                        : null,
                    color:
                      location.pathname.split("/")[1].toLowerCase() ===
                      item.name.toLowerCase()
                        ? "#a3ec26"
                        : null,
                  }}
                >
                  {item.name
                    .replace(/([a-z])([A-Z])/g, "$1 $2")
                    .replace(/([A-Z]+)([A-Z][a-z])/g, "$1 $2")}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      {isMobile && isOpen && <div className="overlay" onClick={toggleSidebar}></div>}
    </>
  );
}