import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { useApp } from "../base/context/AppProviders";
import { login } from "../service/loginApiService";

const useLoginHook = () => {
  const app = useApp();
  const navigate = useNavigate();

  const loginInMutation = useMutation({
    mutationFn: login,

    // onMutate: () => {
    //   app.showLoader(true, "wait for a minute");
    // },

    onSuccess: (response) => {
      localStorage.setItem("TRADING_USER_TOKEN", response.token);

      // window.location.href = "/dashboard";
      navigate("/dashboard");
    },

    onError: (error) => {
      app.showLoader(false, "wait over");
    },
  });

  return {
    loginInMutation,
  };
};

export default useLoginHook;
