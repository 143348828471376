import { useQuery } from "@tanstack/react-query";
import { ReportsApiService } from "../../service/reportsApiService";

const useGetDownlineIncome = () => {
  const downlineIncome = useQuery({
    queryKey: ["downlineIncome"],
    queryFn: ReportsApiService.getDownlineIncome,
  });

  return { downlineIncome };
};

export default useGetDownlineIncome;
