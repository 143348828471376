export const AppColor = {
  primaryColor: "#00e38c",
  secondaryColor: "#1f64ff",
  tertiaryColor: "#11193c",

  lightColor: "#f5f2fc",
  linkColor: "#189be7",

  extraLightGreyColor: "#4f5666",
  greyColor: "#646d82",
  lightGreyColor: "#b5bad0",
};
