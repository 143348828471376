import { Theme } from "@radix-ui/themes";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import AppLogout from "./AppLogout";
import AppProvider from "./base/context/AppProviders";
import PageRoutes from "./base/pageRoutes";
import { onMessageListener } from "./firebase";
import Providers from "./redux/reducers/Providers";

function App() {
  const [notification, setNotification] = useState({ title: "", body: "" });
  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    onMessageListener()
      .then((payload) => {
        setShow(true);
        setNotification({
          title: payload.data.title,
          body: payload.data.body,
        });
        console.log(payload);
      })
      .catch((err) => console.log("failed: ", err));
  }, []);

  useEffect(() => {
    if (show) {
      toast.info(`${notification.title}: ${notification.body}`);
      console.log(notification);
    }
  }, [show, notification]);

  return (
    <div className="App">
      <ToastContainer position="top-right" autoClose={3000} />
      {/* <AppLogout> */}
      <Theme>
        <AppProvider>
          <Providers>
            <PageRoutes />
          </Providers>
        </AppProvider>
      </Theme>
      {/* </AppLogout> */}
    </div>
  );
}

export default App;
