import React from "react";
import { DataTable } from "../../components/common/table/Data-Table";
import { DateTimeFormat } from "../../helper/utils";
import useGetAllRewards from "../../hooks/earning/getRewards.hook";
import Loading from "./../../components/library/loader/loader";
import "./style.css";
import RefralLinks from "../../components/RefralLinks/index"

function getTimeRemaining(endtime) {
  const total = Date.parse(endtime) - Date.parse(new Date());
  const seconds = Math.floor((total / 1000) % 60);
  const minutes = Math.floor((total / 1000 / 60) % 60);
  const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  const days = Math.floor(total / (1000 * 60 * 60 * 24));

  if (days > 0) {
    return days + " days and " + hours + " hours remaining";
  } else if (hours > 0) {
    return hours + " hours remaining";
  } else {
    return "The dates are the same";
  }
}

export default function Rewards() {
  const [table, setTable] = React.useState("daily");

  const { getAllRewards, claimReward } = useGetAllRewards();

  const rewards = getAllRewards?.data;

  const weeklyRewards = rewards?.rewards.filter(
    (item) => item.rewardType === "weekly"
  );

  const dailyRewards = rewards?.rewards.filter(
    (item) => item.rewardType === "daily"
  );

  const monthlyRewards = rewards?.rewards.filter(
    (item) => item.rewardType === "monthly"
  );

  if (getAllRewards.isLoading || getAllRewards.isPending) {
    return <Loading />;
  }

  const monthlyRewardsColumns = [
    {
      header: "S.No",
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "Reward Id",
      accessorKey: "id",
    },

    {
      header: "Description",
      accessorKey: "description",
    },
    {
      header: "Target Value",
      accessorKey: "target",
    },
    {
      header: "Reward Value",
      accessorKey: "reward",
    },
    {
      header: "Status",
      cell: ({ row }) => {
        return row.original.status === "Active" ? (
          <span className="success_status">Active</span>
        ) : (
          <span className="rejected">InActive</span>
        );
      },
    },
    {
      header: "Created At",
      cell: ({ row }) => DateTimeFormat(row.original.createdAt),
    },

    {
      header: "Expiry Date",
      cell: ({ row }) => DateTimeFormat(row.original.expiryDate),
    },

    {
      header: "Time Remaining",
      cell: ({ row }) => {
        return getTimeRemaining(row.original.expiryDate);
      },
    },

    {
      header: "Claim",
      cell: ({ row }) => {
        if (row.index === 0) {
          if (!rewards.monthlyClaim.bronze) {
            return (
              <button
                className="web_button refresh_btn"
                style={{
                  backgroundColor: "#a3ec26",
                  color: "#fff",
                  cursor: "not-allowed",
                }}
                disabled
              >
                Claim
              </button>
            );
          } else {
            return (
              <button
                className="web_button refresh_btn"
                onClick={() => {
                  claimReward.mutate({
                    rewardType: "monthly",
                    monthlyType: "bronze",
                  });
                }}
              >
                Claim
              </button>
            );
          }
        }

        if (row.index === 1) {
          if (!rewards.monthlyClaim.silver) {
            return (
              <button
                className="web_button refresh_btn"
                style={{
                  backgroundColor: "#a3ec26",
                  color: "#fff",
                  cursor: "not-allowed",
                }}
                disabled
              >
                Claim
              </button>
            );
          } else {
            return (
              <button
                className="web_button refresh_btn"
                onClick={() => {
                  claimReward.mutate({
                    rewardType: "monthly",
                    monthlyType: "silver",
                  });
                }}
              >
                Claim
              </button>
            );
          }
        }

        if (row.index === 2) {
          if (!rewards.monthlyClaim.gold) {
            return (
              <button
                className="web_button refresh_btn"
                style={{
                  backgroundColor: "#a3ec26",
                  color: "#fff",
                  cursor: "not-allowed",
                }}
                disabled
              >
                Claim
              </button>
            );
          } else {
            return (
              <button
                className="web_button refresh_btn"
                onClick={() => {
                  claimReward.mutate({
                    rewardType: "monthly",
                    monthlyType: "gold",
                  });
                }}
              >
                Claim
              </button>
            );
          }
        }
      },
    },
  ];

  const weeklyRewardsColumns = [
    {
      header: "S.No",
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "Reward Id",
      accessorKey: "id",
    },

    {
      header: "Description",
      accessorKey: "description",
    },
    {
      header: "Target Value",
      accessorKey: "target",
    },
    {
      header: "Reward Value",
      accessorKey: "reward",
    },
    {
      header: "Status",
      cell: ({ row }) => {
        return row.original.status === "Active" ? (
          <span className="success_status">Active</span>
        ) : (
          <span className="rejected">InActive</span>
        );
      },
    },
    {
      header: "Created At",
      cell: ({ row }) => DateTimeFormat(row.original.createdAt),
    },
    {
      header: "Expiry Date",
      cell: ({ row }) => DateTimeFormat(row.original.expiryDate),
    },

    {
      header: "Time Remaining",
      cell: ({ row }) => {
        return getTimeRemaining(row.original.expiryDate);
      },
    },
    {
      header: "Claim",
      cell: ({ row }) => {
        return rewards.weeklyClaim ? (
          <button
            className="web_button refresh_btn"
            onClick={() => {
              claimReward.mutate({ rewardType: "weekly" });
            }}
          >
            Claim
          </button>
        ) : (
          <button
            className="web_button refresh_btn"
            style={{
              backgroundColor: "#f5f5f5",
              color: "#000",
              cursor: "not-allowed",
            }}
            disabled
          >
            Claim
          </button>
        );
      },
    },
  ];

  const dailyRewardsColumns = [

    {
      header: "S.No",
      cell: ({ row }) => row.index + 1,
    },

    {
      header: "Reward Id",
      accessorKey: "id",
    },

    {
      header: "Description",
      accessorKey: "description",
    },

    {
      header: "Target Value",
      accessorKey: "target",
    },

    {
      header: "Reward Value",
      accessorKey: "reward",
    },

    {
      header: "Status",

      cell: ({ row }) => {
        return row.original.status === "Active" ? (
          <span className="success_status">Active</span>
        ) : (
          <span className="rejected">InActive</span>
        );
      },
    },

    {
      header: "Created At",
      cell: ({ row }) => DateTimeFormat(row.original.createdAt),
    },
    
    {
      header: "Expiry Date",
      cell: ({ row }) => DateTimeFormat(row.original.expiryDate),
    },

    {
      header: "Time Remaining",
      cell: ({ row }) => {
        return getTimeRemaining(row?.original?.expiryDate);
      },
    },
    {
      header: "Claim",
      cell: ({ row }) => {
        return rewards.dailyClaim ? (
          <button
            className="web_button refresh_btn"
            onClick={() => {
              claimReward.mutate({ rewardType: "daily" });
            }}
          >
            Claim
          </button>
        ) : (
          <button
            className="web_button refresh_btn"
            style={{
              backgroundColor: "#a3ec26",
              color: "#fff",
              cursor: "not-allowed",
            }}
            disabled
          >
            Claim
          </button>
        );
      },
    },
  ];

  return (
    <div className="rewards_bg">
      {/* <div className="set_table_container">
        <p
          style={{
            color: table === "daily" ? "#00e38c" : "#000",
            fontWeight: table === "daily" ? "bold" : "normal",
            borderRight: "1px solid #000",
            paddingRight: "15px",
          }}
          onClick={() => setTable("daily")}
        >
          Daily
        </p>
        <p
          style={{
            color: table === "weekly" ? "#00e38c" : "#000",
            fontWeight: table === "weekly" ? "bold" : "normal",
            borderRight: "1px solid #000",
            paddingRight: "15px",
          }}
          onClick={() => setTable("weekly")}
        >
          Weekly
        </p>

        <p
          style={{
            color: table === "monthly" ? "#00e38c" : "#000",
            fontWeight: table === "monthly" ? "bold" : "normal",
          }}
          onClick={() => setTable("monthly")}
        >
          Monthly
        </p>
      </div> */}

      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <button
          className="web_button widthdraw_btn"
          style={
            table === "daily"
              ? {
                  backgroundColor: "#313135",
                  color: "#a3ec26",
                }
              : {
                  backgroundColor: "#4A4A4F",
                  color: "#fff",
                }
          }
          onClick={() => setTable("daily")}
        >
          Daily
        </button>

        <button
          className="web_button widthdraw_btn"
          style={
            table === "weekly"
              ? {
                  backgroundColor: "#313135",
                  color: "#a3ec26",
                }
              : {
                  backgroundColor: "#4A4A4F",
                  color: "#fff",
                }
          }
          onClick={() => setTable("weekly")}
        >
          Weekly
        </button>

        <button
          className="web_button widthdraw_btn"
          style={
            table === "monthly"
              ? {
                  backgroundColor: "#313135",
                  color: "#a3ec26",
                }
              : {
                  backgroundColor: "#4A4A4F",
                  color: "#fff",
                }
          }
          onClick={() => setTable("monthly")}
        >
          Monthly
        </button>
      </div>

      {table === "daily" && (
        <div>
          <div className="rewards_heading">Daily</div>

          <DataTable columns={dailyRewardsColumns} data={dailyRewards} />
        </div>
      )}

      {table === "weekly" && (
        <div>
          <div className="rewards_heading">Weekly</div>

          <DataTable columns={weeklyRewardsColumns} data={weeklyRewards} />
        </div>
      )}

      {table === "monthly" && (
        <div>
          <div className="rewards_heading">Monthly</div>

          <DataTable columns={monthlyRewardsColumns} data={monthlyRewards} />
        </div>
      )}

<div className="refral-links-container  small-screen-only">
          <RefralLinks />
        </div>
    </div>
  );
}
