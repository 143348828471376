import React from "react";
import { IoCloseSharp } from "react-icons/io5";
import "./style.css";

export default function WebMultiSelectInput({
  label,
  onChange,
  error,
  message,
  style,
  labelStyle,
  values,
  handleRemove,
  accept,
}) {
  return (
    <div className="multi_image_input__parent_div">
      <label style={labelStyle} className="label_style">
        {label}
      </label>

      <div className="multi_image_input__wrapper" style={style}>
        <input
          type="file"
          onChange={onChange}
          multiple
          className={"multi_image_input_"}
          accept={accept}
        />
        {values?.map((file, index) => {
          return file.name ? (
            <div className="multi_file_name">
              {file.name}{" "}
              <span onClick={() => handleRemove(file)}>
                <IoCloseSharp />
              </span>
            </div>
          ) : null;
        })}
      </div>
      {error ? (
        <p className="multi_image_input_error">{error ? "*" + error : null}</p>
      ) : (
        <p className="multi_image_input__message">
          {message ? "*" + message : null}
        </p>
      )}
    </div>
  );
}
