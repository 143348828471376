import api from "../config/apiConfig";
import { NetworkConfiguration } from "../network/NetworkConfriguation";

/**
 * @author Pratham.
 * @since 16-02-2024.
 * @description  to get all ticket.
 */

const getRewards = async () => {
  const res = await api.get(NetworkConfiguration.GET_REWARDS);
  return res.data.data;
};

// const claimReward = async () => {
//   const res = await api.post(NetworkConfiguration.CLAIM_REWARD, { id });
//   return res;
// };

const claimReward = async (data) => {
  await api.post(NetworkConfiguration.CLAIM_REWARD, data);
};

const getUserEarnedRewards = async () => {
  const res = await api.get(NetworkConfiguration.GET_USER_EARNED_REWARDS);
  return res.data.data;
};

export const RewardsApiService = {
  getRewards,
  claimReward,
  getUserEarnedRewards,
};
