import React, { useState } from "react";
import Breadcrumb from "../breadCrumbs";
import Navbar from "../navbar";
import Sidebar from "../sidebar";
import "./style.css";
import RefralLinks from "../../RefralLinks/index";

export default function Layout({ children }) {
  const [sidebar, setSidebar] = useState(true);

  const handleSidebarCollapse = () => {
    setSidebar(!sidebar);
  };
  const path = window.location.pathname;
  // console.log(path);

  return (
    <div className="layout_bg">
      <div className="layout_left">
        <Sidebar sidebar={sidebar} />
      </div>
      <div className="layout_right ">
        <Navbar
          handleSidebarCollapse={handleSidebarCollapse}
          sidebar={sidebar}
        />
        <Breadcrumb />
        <div style={{ display: "flex" }}>
          <div className="layout_children_bg scrollbar-hide">{children}</div>
          {/* {path === "/dashboard" || path === "/earningWallet" ? (
            ""
          ) : (
            <div className="common_refral_component">
              <RefralLinks />
            </div>
          )} */}
        </div>

        <p className="web_copyright_line">
          Copyright &copy; {new Date().getFullYear}. All rights reserved.
        </p>
      </div>
    </div>
  );
}
