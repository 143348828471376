import React from "react";
import { AppColor } from "../../../../assests/colors/colors";
import { AppFonts } from "../../../../assests/fonts/fonts";
import WebButton from "../../../library/webButton";
import WebInput from "../../../library/webInput";
import WebMultiSelectInput from "../../../library/webMultiSelectInput";
import "./style.css";

export default function KycSubmitted() {
  return (
    <div>
      <div className="kyc_documentation_form_box">
        <WebMultiSelectInput
          label="Upload Adhar Card"
          onChange={null}
          error=""
          message="Please upload front and back pictures of your Aadhar Card"
          style={{
            border: `1px solid ${AppColor.primaryColor}`,
          }}
          labelStyle={{
            color: AppColor.greyColor,
            fontWeight: AppFonts.fontMedium,
          }}
          values={[]}
        />

        <WebMultiSelectInput
          label="Upload PAN Card"
          onChange={null}
          error=""
          message="Please upload front picture of your PAN Card"
          style={{
            border: `1px solid ${AppColor.primaryColor}`,
          }}
          labelStyle={{
            color: AppColor.greyColor,
            fontWeight: AppFonts.fontMedium,
          }}
          values={[]}
        />

        <WebInput
          name="name"
          label="Name"
          type="text"
          placeholder=""
          onChange={null}
          error=""
          message=""
          value=""
          eye=""
          handleType={null}
          onKeyUp={null}
          style={{
            border: `1px solid ${AppColor.primaryColor}`,
          }}
          labelStyle={{
            color: AppColor.greyColor,
            fontWeight: AppFonts.fontMedium,
          }}
          inputRef={null}
          onpaste={null}
        />

        <WebInput
          name="phone"
          label="Mobile Number"
          type="text"
          placeholder=""
          onChange={null}
          error=""
          message=""
          value=""
          eye=""
          handleType={null}
          onKeyUp={null}
          style={{
            border: `1px solid ${AppColor.primaryColor}`,
          }}
          labelStyle={{
            color: AppColor.greyColor,
            fontWeight: AppFonts.fontMedium,
          }}
          inputRef={null}
          onpaste={null}
        />

        <WebInput
          name="dateOfBirth"
          label="Date Of Birth"
          type="date"
          placeholder=""
          onChange={null}
          error=""
          message=""
          value=""
          eye=""
          handleType={null}
          onKeyUp={null}
          style={{
            border: `1px solid ${AppColor.primaryColor}`,
          }}
          labelStyle={{
            color: AppColor.greyColor,
            fontWeight: AppFonts.fontMedium,
          }}
          inputRef={null}
          onpaste={null}
        />

        <WebButton
          onClickEvent={null}
          text="Upload"
          style={null}
          className="submit_btn"
          type="button"
        />
      </div>

      <div className="kyc_submitted_outer_box">
        <div className="kyc_submitted_inner_box">
          Your Approval is pending. It will complete within next 7 working days.
        </div>
      </div>
    </div>
  );
}
