import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { MdVerifiedUser } from "react-icons/md";
import { toast } from "react-toastify";
import { AppFonts } from "../../assests/fonts/fonts";
import { useApp } from "../../base/context/AppProviders";
import WebButton from "../../components/library/webButton";
import WebInput from "../../components/library/webInput";
import api from "../../config/apiConfig";
import { RejexConstants } from "../../helper/stringConstants";
import { AuthApiService } from "../../service/authApiService";
import "./style.css";

export default function ChangePassword() {
  const app = useApp();

  const [paswordVarified, setPasswordVarified] = useState(false);

  const [passwordType, setPasswordType] = useState({
    old: true,
    new: true,
    confirm: true,
  });

  const [data, setData] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
  });

  // handle password input type
  const handlePasswordType = (type) => {
    setPasswordType({ ...passwordType, [type]: !passwordType[type] });
  };

  //handle password eye icon
  const handleOldPasswordEye = () => {
    if (passwordType.old) {
      return <FaEye style={{color:"white"}} />;
    } else {
      return <FaEyeSlash style={{color:"white"}}/>;
    }
  };

  const handleNewPasswordEye = () => {
    if (passwordType.new) {
      return <FaEye style={{color:"white"}}/>;
    } else {
      return <FaEyeSlash style={{color:"white"}}/>;
    }
  };

  const handleConfirmPasswordEye = () => {
    if (passwordType.confirm) {
      return <FaEye style={{color:"white"}}/>;
    } else {
      return <FaEyeSlash style={{color:"white"}}/>;
    }
  };

  const handleVarifyPassword = () => {
    api
      .post("/verifyPassword", { password: data.password })
      .then((res) => {
        setPasswordVarified(true);
      })
      .catch((err) => {
        // message.showToast(res.message, ToastTypes.ERROR);
        setPasswordVarified(false);
      });
  };

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const validate = () => {
    let result = true;
    if (!data.password) {
      setError({ ...error, password: "First Enter Password" });
      result = false;
    } else if (RejexConstants.spaceRegex.test(data.password)) {
      setError({ ...error, password: "Invalid Password" });
      result = false;
    } else if (!data.newPassword) {
      setError({ ...error, newPassword: "First Enter Password" });
      result = false;
    } else if (RejexConstants.spaceRegex.test(data.newPassword)) {
      setError({ ...error, newPassword: "Invalid Password" });
      result = false;
    } else if (!data.confirmPassword) {
      setError({ ...error, confirmPassword: "First Enter Password" });
      result = false;
    } else if (RejexConstants.spaceRegex.test(data.confirmPassword)) {
      setError({ ...error, confirmPassword: "Invalid Password" });
      result = false;
    } else if (data.newPassword !== data.confirmPassword) {
      setError({ ...error, confirmPassword: "Password not match" });
      result = false;
    } else if (data.password === data.newPassword) {
      setError({ ...error, newPassword: "Old and New Password can't be same" });
      result = false;
    } else if (data.password === data.confirmPassword) {
      setError({
        ...error,
        confirmPassword: "Old and New Password can't be same",
      });

      result = false;
    } else if (paswordVarified === false) {
      setError({
        ...error,
        password: "First Verify Password",
      });
      result = false;
    }

    return result;
  };

  const handleChangePassword = (e) => {
    e.preventDefault();

    if (validate()) {
      app.showLoader(true, "wait for a minute");

      AuthApiService.changePassword(JSON.stringify(data))
        .then((res) => {
          app.showLoader(false, "wait over");
          setData({ password: "", newPassword: "", confirmPassword: "" });
          toast.success("Password Changed Successfully");
          setPasswordVarified(false);
        })
        .catch((err) => {
          app.showLoader(false, "wait over");
        });
    }
  };

  return (
    <form className="change_password_box" onSubmit={handleChangePassword}>
      <div className="change_password_input_box">
        <div
          style={{
            display: "grid",
            gap: "5px",
            marginBottom: "20px",
          }}
        >
          <WebInput
            label="Current Password"
            type={passwordType.old ? "password" : "text"}
            onChange={handleOnChange}
            error={error.password}
            value={data.password}
            eye={handleOldPasswordEye()}
            handleType={() => handlePasswordType("old")}
            labelStyle={{ fontWeight: AppFonts.fontMedium }}
            name="password"
            maxLength="16"
          />

          {!paswordVarified && data?.password?.length > 1 ? (
            <WebButton
              onClickEvent={handleVarifyPassword}
              text="Verify Code"
              style={{ zIndex: "111" }}
              className="package_button"
              type="button"
            ></WebButton>
          ) : paswordVarified ? (
            <MdVerifiedUser color={"green"} size={25} />
          ) : null}
        </div>
        <WebInput
          label="New Password"
          type={passwordType.new ? "password" : "text"}
          onChange={handleOnChange}
          error={error.newPassword}
          value={data.newPassword}
          eye={handleNewPasswordEye()}
          handleType={() => handlePasswordType("new")}
          style={null}
          labelStyle={{ fontWeight: AppFonts.fontMedium }}
          name="newPassword"
          maxLength="16"
        />
        <WebInput
          label="Confirm Password"
          type={passwordType.confirm ? "password" : "text"}
          onChange={handleOnChange}
          error={error.confirmPassword}
          value={data.confirmPassword}
          eye={handleConfirmPasswordEye()}
          handleType={() => handlePasswordType("confirm")}
          style={null}
          labelStyle={{ fontWeight: AppFonts.fontMedium }}
          name="confirmPassword"
          maxLength="16"
        />

        <WebButton
          onClickEvent={null}
          text="Change Password"
          style={{ margin: "20px 0px" }}
          className="submit_btn"
          type="submit"
        />
      </div>
    </form>
  );
}
