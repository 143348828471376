import { KycStatus } from "../../helper/stringConstants";
import { AuthApiService } from "../../service/authApiService";
import { ContractApiService } from "../../service/contractApiService";
import { EarningApiService } from "../../service/earningApiService";
import { KycApiService } from "../../service/kycApiService";
// import { LoginApiService } from "../../service/loginApiService";
import { NetworkApiService } from "../../service/networkApiService";
import { ReportsApiService } from "../../service/reportsApiService";
import { RewardsApiService } from "../../service/rewardsApiService";
import { SupportApiService } from "../../service/supportApiService";
import {
  ACTIVE_CONTRACTS,
  CONTRACTS_LIST,
  DIRECT_INCOME,
  DIRECT_LIST,
  DOWNLINE_INCOME,
  DOWNLINE_LIST,
  EARNINGS,
  IS_AUTH,
  KYC_STATUS,
  REWARDS,
  STB_INCOME,
  SUPPORT_CATEGORY,
  TICKETS,
  USER_PROFILE,
  WITHDRAWALS,
} from "./types";
/**
 * @author Diksha Lalit
 * @since 15-01-2024
 * @description to set contact list
 */

export const setContracts = (data) => ({
  type: CONTRACTS_LIST,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 15-01-2024
 * @description to set contact list
 */

export const setUserProfile = (data) => ({
  type: USER_PROFILE,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 15-01-2024
 * @description to set direct network list
 */

export const setDirectNetwork = (data) => ({
  type: DIRECT_LIST,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 15-01-2024
 * @description to set downline network list
 */

export const setDownline = (data) => ({
  type: DOWNLINE_LIST,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 16-01-2024
 * @description to set active contracts
 */

export const setActiveContracts = (data) => ({
  type: ACTIVE_CONTRACTS,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 16-01-2024
 * @description to set Stb Income
 */

export const setStbIncome = (data) => ({
  type: STB_INCOME,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 16-01-2024
 * @description to set direct Income
 */

export const setDirectIncome = (data) => ({
  type: DIRECT_INCOME,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 16-01-2024
 * @description to set downline Income
 */

export const setDownlineIncome = (data) => ({
  type: DOWNLINE_INCOME,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 17-01-2024
 * @description to set kyc status
 */

export const setKycStatus = (data) => ({
  type: KYC_STATUS,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 18-01-2024
 * @description to set Auth
 */

export const setAuth = (data) => ({
  type: IS_AUTH,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 19-01-2024
 * @description to set support category
 */

export const setSupportCategory = (data) => ({
  type: SUPPORT_CATEGORY,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 22-01-2024
 * @description to set tickets
 */

export const setTickets = (data) => ({
  type: TICKETS,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 22-01-2024
 * @description to set tickets
 */

export const setEarnings = (data) => ({
  type: EARNINGS,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 24-01-2024
 * @description to set withdrawals
 */

export const setWithdrawals = (data) => ({
  type: WITHDRAWALS,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 12-02-2024
 * @description to set Rewards
 */

export const setRewards = (data) => ({
  type: REWARDS,
  payload: data,
});

/**
 * @author Diksha Lalit
 * @since 15-01-2024
 * @description to get contract list
 */

export const getContracts =
  (sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    ContractApiService.fetchContracts()
      .then((response) => {
        dispatch(setContracts(response.data));
        sendSuccess(response);
      })
      .catch((error) => {
        sendError(error);
      });
  };

/**
 * @author Diksha Lalit
 * @since 15-01-2024
 * @description to get user profile
 */

export const getUserProfile =
  (sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    AuthApiService.fetchUserProfile()
      .then((response) => {
        dispatch(setUserProfile(response.data));
        dispatch(setKycStatus(response.data.user.KYCStatus));
        sendSuccess(response);
      })
      .catch((error) => {
        sendError(error);
      });
  };

/**
 * @author Diksha Lalit
 * @since 15-01-2024
 * @description to get direct network
 */

export const getDirectNetwork =
  (sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    NetworkApiService.fetchDirectNetwork()
      .then((response) => {
        sendSuccess(response);
        dispatch(setDirectNetwork(response.data));
      })
      .catch((error) => {
        sendError(error);
      });
  };

/**
 * @author Diksha Lalit
 * @since 16-01-2024
 * @description to get downline network
 */

export const getDownlineNetwork =
  (sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    NetworkApiService.fetchDownlineNetwork()
      .then((response) => {
        sendSuccess(response);
        dispatch(setDownline(response.data));
      })
      .catch((error) => {
        sendError(error);
      });
  };

/**
 * @author Diksha Lalit
 * @since 16-01-2024
 * @description to get active contracts
 */
export const getActiveContracts =
  (sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    ContractApiService.fetchActiveContracts()
      .then((response) => {
        sendSuccess(response);
        dispatch(setActiveContracts(response.data));
      })
      .catch((error) => {
        sendError(error);
      });
  };

/**
 * @author Diksha Lalit
 * @since 16-01-2024
 * @description to purchase new contract
 */
export const toPurchaseNewContract =
  (contractPayload, sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    ContractApiService.purchaseNewContract(contractPayload)
      .then((response) => {
        dispatch(getActiveContracts());
        sendSuccess(response);
      })
      .catch((error) => {
        sendError(error);
      });
  };

/**
 * @author Diksha Lalit
 * @since 16-01-2024
 * @description to get stb income
 */
export const fetchStbIncome =
  (sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    ReportsApiService.getStbIncome()
      .then((response) => {
        dispatch(setStbIncome(response.data));
        sendSuccess(response);
      })
      .catch((error) => {
        sendError(error);
      });
  };

/**
 * @author Diksha Lalit
 * @since 16-01-2024
 * @description to get level direct income
 */
export const fetchDirectIncome =
  (sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    ReportsApiService.getDirectIncome()
      .then((response) => {
        dispatch(setDirectIncome(response.data));
        sendSuccess(response);
      })
      .catch((error) => {
        sendError(error);
      });
  };

/**
 * @author Diksha Lalit
 * @since 16-01-2024
 * @description to get level downline income
 */
export const fetchDownlineIncome =
  (sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    ReportsApiService.getDownlineIncome()
      .then((response) => {
        dispatch(setDownlineIncome(response.data));
        sendSuccess(response);
      })
      .catch((error) => {
        sendError(error);
      });
  };

/**
 * @author Diksha Lalit
 * @since 17-01-2024
 * @description to get level downline income
 */
export const kycRegistration =
  (kycPayload, sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    KycApiService.kycRegistration(kycPayload)
      .then((response) => {
        dispatch(setKycStatus(KycStatus.KYC_SUBMITTED));
        sendSuccess(response);
        window.location.reload();
      })
      .catch((error) => {
        sendError(error);
      });
  };

/**
 * @author Diksha Lalit
 * @since 18-01-2024
 * @description to login user
 */
// export const login = (loginPayload, sendSuccess, sendError) => (dispatch) => {
//   LoginApiService.login(loginPayload)
//     .then((response) => {
//       sendSuccess(response);
//       dispatch(setAuth(true));
//     })
//     .catch((error) => {
//       sendError(error);
//       dispatch(setAuth(false));
//     });
// };

/**
 * @author Diksha Lalit
 * @since 19-01-2024
 * @description to get support categories
 */
export const getSupportCategory = () => (dispatch) => {
  SupportApiService.getSupportCategory()
    .then((response) => {
      dispatch(setSupportCategory(response.data));
    })
    .catch((error) => {});
};

/**
 * @author Diksha Lalit
 * @since 22-01-2024
 * @description to get all tickets
 */
export const getTickets =
  (ticketPayload, sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    SupportApiService.getTickets(ticketPayload)
      .then((response) => {
        sendSuccess(response);
        dispatch(setTickets(response.data));
      })
      .catch((error) => {
        sendError(error);
      });
  };

/**
 * @author Diksha Lalit
 * @since 23-01-2024
 * @description to get all earnings
 */
export const getEarnings =
  (sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    EarningApiService.fetchEarnings()
      .then((response) => {
        sendSuccess(response);
        dispatch(setEarnings(response.data));
      })
      .catch((error) => {
        sendError(error);
      });
  };

/**
 * @author Diksha Lalit
 * @since 23-01-2024
 * @description to withdraw earnings
 */
export const getWithdrawals =
  (withdrawPayload, sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    EarningApiService.getWithdrawals(withdrawPayload)
      .then((response) => {
        dispatch(setWithdrawals(response.data));
        sendSuccess(response);
      })
      .catch((error) => {
        sendError(error);
      });
  };

/**
 * @author Diksha Lalit
 * @since 12-02-2024
 * @description to get rewards
 */
export const getRewards =
  (sendSuccess = () => {}, sendError = () => {}) =>
  (dispatch) => {
    RewardsApiService.getRewards()
      .then((response) => {
        dispatch(setRewards(response.data));
        sendSuccess(response);
      })
      .catch((error) => {
        sendError(error);
      });
  };
