import { useState } from "react";
import { DataTable } from "../../components/common/table/Data-Table";
import Loading from "../../components/library/loader/loader";
import { DateTimeFormat } from "../../helper/utils";
import useGetAllRewardsEarned from "../../hooks/earning/rewardsEarned";
import useGetDirectIncome from "./../../hooks/networks/getDirectIncome.hook";
import useGetDownlineIncome from "./../../hooks/networks/getDownline.hook";
import useGetSTBincome from "./../../hooks/networks/getSTBincome.hook";
import RefralLinks from "../../components/RefralLinks/index"

const Reports = () => {
  const [table, setTable] = useState("direct");

  const { directIncome } = useGetDirectIncome();
  const { stbIncome } = useGetSTBincome();
  const { downlineIncome } = useGetDownlineIncome();
  const { getAllRewardsEarned } = useGetAllRewardsEarned();

  if (
    directIncome.isLoading ||
    stbIncome.isLoading ||
    downlineIncome.isLoading ||
    getAllRewardsEarned.isLoading
  )
    return <Loading />;

  if (
    directIncome.isError ||
    stbIncome.isError ||
    downlineIncome.isError ||
    getAllRewardsEarned.isError
  )
    return "An error occurred: " + directIncome.error.message;

  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <button
          className="widthdraw_btn web_button"
          style={
            table === "direct"
              ? {
                  backgroundColor: "#313135",
                  color: "#a3ec26",
                }
              : {
                  backgroundColor: "#4A4A4F",
                  color: "#fff",
                }
          }
          onClick={() => setTable("direct")}
        >
          Direct
        </button>

        <button
          className="widthdraw_btn web_button"
          style={
            table === "downline"
              ? {
                  backgroundColor: "#313135",
                  color: "#a3ec26",
                }
              : {
                  backgroundColor: "#4A4A4F",
                  color: "#fff",
                }
          }
          onClick={() => setTable("downline")}
        >
          Downline
        </button>

        <button
          className="widthdraw_btn web_button"
          style={
            table === "stb"
              ? {
                  backgroundColor: "#313135",
                  color: "#a3ec26",
                }
              : {
                  backgroundColor: "#4A4A4F",
                  color: "#fff",
                }
          }
          onClick={() => setTable("stb")}
        >
          Escrow Balance
        </button>
        <button
          className="widthdraw_btn web_button"
          style={
            table === "rewards"
              ? {
                  backgroundColor: "#313135",
                  color: "#a3ec26",
                }
              : {
                  backgroundColor: "#4A4A4F",
                  color: "#fff",
                }
          }
          onClick={() => setTable("rewards")}
        >
          Rewards
        </button>
      </div>

      {table === "direct" && (
        <DataTable columns={directIncomeColumns} data={directIncome.data} />
      )}

      {table === "downline" && (
        <DataTable columns={downLineIncomeColumns} data={downlineIncome.data} />
      )}

      {table === "stb" && (
        <DataTable columns={stbIncomeColumns} data={stbIncome.data} />
      )}

      {table === "rewards" && (
        <DataTable columns={rewardsColumns} data={getAllRewardsEarned.data} />
      )}
<div className="refral-links-container  small-screen-only">
          <RefralLinks />
        </div>

    </div>
  );
};

export default Reports;

const directIncomeColumns = [
  {
    header: "Sno",
    cell: ({ row }) => row.index + 1,
  },

  {
    header: "Transaction ID",
    accessorKey: "id",
  },
  {
    header: "ST Token",
    accessorKey: "amount",
  },
  {
    header: "Type",
    accessorKey: "type",
  },
  {
    header: "Remark",
    accessorKey: "remark",
    cell: ({ row }) => (
      <p className="web_text_td_para">{row.original.remark}</p>
    ),
  },
  {
    header: "Status",
    // accessorKey: "paymentStatus",
    cell: ({ row }) => (
      <div
        className={
          row.original.paymentStatus === "Success"
            ? "success_status"
            : "pending_status"
        }
      >
        {row.original.paymentStatus}
      </div>
    ),
  },
  {
    header: "Transaction Timestamp",
    // accessorKey: "",
    cell: ({ row }) => DateTimeFormat(row.original.updatedAt),
  },
];
const downLineIncomeColumns = [
  {
    header: "Sno",
    cell: ({ row }) => row.index + 1,
  },
  {
    header: "Transaction ID",
    accessorKey: "id",
  },
  {
    header: "ST Token",
    accessorKey: "amount",
  },
  {
    header: "Type",
    accessorKey: "type",
  },
  {
    header: "Remark",
    accessorKey: "remark",
    cell: ({ row }) => (
      <p className="web_text_td_para">{row.original.remark}</p>
    ),
  },
  {
    header: "Status",
    // accessorKey: "paymentStatus",
    cell: ({ row }) => (
      <div
        className={
          row.original.paymentStatus === "Success"
            ? "success_status"
            : "pending_status"
        }
      >
        {row.original.paymentStatus}
      </div>
    ),
  },
  {
    header: "Transaction Timestamp",
    cell: ({ row }) => DateTimeFormat(row.original.updatedAt),
  },
];

const stbIncomeColumns = [
  {
    header: "Sno",
    cell: ({ row }) => row.index + 1,
  },
  {
    header: "Transaction ID",
    accessorKey: "id",
  },
  {
    header: "ST Token",
    accessorKey: "amount",
  },
  {
    header: "Type",
    accessorKey: "type",
  },
  {
    header: "Remark",
    accessorKey: "remark",
    cell: ({ row }) => (
      <p className="web_text_td_para">{row.original.remark}</p>
    ),
  },
  {
    header: "Status",
    cell: ({ row }) => (
      <div
        className={
          row.original.paymentStatus === "Success"
            ? "success_status"
            : "pending_status"
        }
      >
        {row.original.paymentStatus}
      </div>
    ),
  },
  {
    header: "Transaction Timestamp",
    cell: ({ row }) => DateTimeFormat(row.original.updatedAt),
  },
];

const rewardsColumns = [
  {
    header: "Sno",
    cell: ({ row }) => row.index + 1,
  },
  {
    header: "Transaction ID",
    accessorKey: "id",
  },
  {
    header: "ST Token",
    accessorKey: "amount",
  },
  {
    header: "Type",
    accessorKey: "type",
  },
  {
    header: "Reward Type",
    // accessorKey: "rewardType",
    cell: ({ row }) => (
      <p className="web_text_td_para">{row.original?.rewardType}</p>
    ),
  },
  {
    header: "Remark",
    accessorKey: "remark",
    cell: ({ row }) => (
      <p className="web_text_td_para">{row.original.remark}</p>
    ),
  },
  {
    header: "Status",
    cell: ({ row }) => (
      <div
        className={
          row.original.paymentStatus === "Success"
            ? "success_status"
            : "pending_status"
        }
      >
        {row.original.paymentStatus}
      </div>
    ),
  },
  {
    header: "Transaction Timestamp",
    cell: ({ row }) => DateTimeFormat(row.original.updatedAt),
  },
];
