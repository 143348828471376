import React from "react";
import "./style.css";

export default function WebDropdown({
  label,
  placeholder,
  onChange,
  error,
  value,
  style,
  labelStyle,
  onClick,
  options,
}) {
  return (
    <div className="dropdown_parent_div">
      <label style={labelStyle} className="label_style">
        {label}
      </label>

      <div className="dropdown_wrapper" style={style}>
        <select
       className="web_dropdown"
          onChange={onChange}
          value={value}
          onClick={onClick}
        >
          <option value="">{placeholder}</option>
          {options?.map((item, index) => {
            return (
              <option key={index} value={item.id}>
                {item.name}
              </option>
            );
          })}
        </select>
      </div>

      <p className="dropdown_error">{error ? "*" + error : null}</p>
    </div>
  );
}
