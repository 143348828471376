import React from "react";
import { GrLinkNext } from "react-icons/gr";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/library/loader/loader";
import WebButton from "../../components/library/webButton";
import { DateFormat } from "../../helper/utils";
import useGetUserProfile from "./../../hooks/user/getUserProfile.hook";
import "./style.css";

export default function Profile() {
  // const userProfile = useSelector((state) => state.appReducers.userProfile);
  const userProfile = useGetUserProfile();

  const user = userProfile?.data?.user;

  console.log("userProfile", user);

  const navigate = useNavigate();

  if (userProfile.isLoading || userProfile.isPending) {
    return <Loading />;
  }

  return (
    <div className="profile_box">
      <div className="profile_heading">PERSONAL INFORMATION</div>
      <div className="profile_heading_border"></div>

      <div className="profile_inputs_wrapper">
        <div className="profile_inputs_row">
          <div className="input_field_two">
            <span>MEMBERSHIP ID</span>
            <input type="text" value={user?.referralCode} readOnly></input>
          </div>

          <div className="input_field_two">
            <span>DATE OF BIRTH</span>
            <input
              type="text"
              value={DateFormat(user?.dateOfBirth)}
              readOnly
            ></input>
          </div>
        </div>

        <div className="profile_inputs_row">
          {user?.prefix ? (
            <div className="input_field_two">
              <span>Prefix</span>

              <input type="text" value={user?.prefix} readOnly />
            </div>
          ) : (
            ""
          )}
          <div className="input_field_two">
            <span>FIRST NAME</span>
            <input type="text" value={user?.firstName} readOnly></input>
          </div>
          <div className="input_field_two">
            <span>LAST NAME</span>
            <input type="text" value={user?.lastName} readOnly></input>
          </div>
        </div>

        <div className="profile_inputs_row">
          <div className="input_field_two">
            <span>PINCODE</span>
            <input type="text" value={user?.pincode} readOnly></input>
          </div>
          <div className="input_field_two">
            <span>STATE</span>
            <input type="text" value={user?.state} readOnly></input>
          </div>
          <div className="input_field_two">
            <span>CITY</span>
            <input type="text" value={user?.city} readOnly></input>
          </div>
        </div>
      </div>

      <div className="profile_heading">CONTACT DETAILS</div>
      <div className="profile_heading_border"></div>

      <div className="profile_inputs_wrapper">
        <div className="profile_inputs_row">
          <div className="input_field_two">
            <span>EMAIL ID</span>
            <input type="text" value={user?.email} readOnly></input>
          </div>

          <div className="input_field_two">
            <span>MOBILE NUMBER</span>
            <input type="text" value={user?.phone} readOnly></input>
          </div>
        </div>
      </div>

      <WebButton
        onClickEvent={() => navigate("/myProfile/updateProfile")}
        text={
          <p className="button_icon_text">
            Edit Profile
            <GrLinkNext size={22} />
          </p>
        }
        style={null}
        className="submit_btn"
        type="button"
      />
    </div>
  );
}
