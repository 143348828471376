import React, { useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa6";
import { MdVerifiedUser } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { AppColor } from "../../assests/colors/colors";
import { AppFonts } from "../../assests/fonts/fonts";
import { useApp } from "../../base/context/AppProviders";
import OtpInputs from "../../components/common/otpInputs";
import WebButton from "../../components/library/webButton";
import WebInput from "../../components/library/webInput";
import { RejexConstants } from "../../helper/stringConstants";
import { AuthApiService } from "../../service/authApiService";
import "./style.css";

export default function ForgetPassword() {
  const [newPasswordInput, setNewPasswordInput] = useState(true);
  const [confirmPasswordInput, setConfirmPasswordInput] = useState(true);
  const [isOTP, setIsOTP] = useState(false);

  const [data, setData] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [error, setError] = useState({
    email: "",
    password: "",
    confirmPassword: "",
  });

  const otpBtnStyle = {
    fontSize: AppFonts.fontSizeXXSmall,
    fontWeight: AppFonts.fontMedium,
    color: AppColor.greyColor,
    textWrap: "nowrap",
  };

  const [verified, setVerified] = useState(false);
  const [emailOtpArea, setEmailOtpArea] = useState(false);
  const [emailOtp, setEmailOtp] = useState("");
  const [emailOtpId, setEmailOtpId] = useState("");

  const app = useApp();
  const navigate = useNavigate();

  // handle password input type
  const handleNewPasswordType = () => {
    setNewPasswordInput(!newPasswordInput);
  };

  //handle password eye icon
  const handleNewPasswordEye = () => {
    if (newPasswordInput) {
      return <FaEye style={{color:"white"}}/>;
    } else {
      return <FaEyeSlash style={{color:"white"}} />;
    }
  };

  // handle confirm password input type
  const handleConfirmPasswordType = () => {
    setConfirmPasswordInput(!confirmPasswordInput);
  };

  //handle confirm password eye icon
  const handleConfirmPasswordEye = () => {
    if (confirmPasswordInput) {
      return <FaEye style={{color:"white"}} />;
    } else {
      return <FaEyeSlash  style={{color:"white"}}/>;
    }
  };

  // handle on chnage event to set value
  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  // send otp to email address
  const sendOtpToEmail = () => {
    if (data.email) {
      if (RejexConstants.emailRegex.test(data?.email)) {
        setError({ ...error, email: "" });

        app.showLoader(true, "wait for a minute");
        const emailPayload = {
          email: data.email,
        };
        AuthApiService.sendOtpToEmailforForget(JSON.stringify(emailPayload))
          .then((res) => {
            app.showLoader(false, "wait over");
            setIsOTP(true);
            setEmailOtpId(res.data);
            setEmailOtpArea(true);
          })
          .catch((err) => {
            app.showLoader(false, "wait over");
          });
      } else {
        setError({ ...error, email: "Invalid Email" });
      }
    } else {
      setError({ ...error, email: "Please Enter Email" });
    }
  };

  //verify email address
  const handleVerifyEmail = () => {
    app.showLoader(true, "wait for a minute");
    const otpPayload = {
      id: emailOtpId,
      otp: emailOtp,
    };
    AuthApiService.verifyEmailOtp(JSON.stringify(otpPayload))
      .then((res) => {
        app.showLoader(false, "wait over");
        setEmailOtpArea(false);
        setVerified(true);
      })
      .catch((err) => {
        app.showLoader(false, "wait over");
      });
  };

  //validate function
  const validate = () => {
    let result = true;
    if (!data.email) {
      setError({ ...error, email: "Please Enter Email" });
      result = false;
    } else if (!RejexConstants.emailRegex.test(data?.email)) {
      setError({ ...error, email: "Invalid Email" });
      result = false;
    } else if (!verified) {
      setError({ ...error, email: "Verify Email" });
      result = false;
    } else if (!data.password) {
      setError({ ...error, password: "Please Enter Password" });
      result = false;
    } else if (!data.confirmPassword) {
      setError({ ...error, confirmPassword: "Please Enter Password" });
      result = false;
    } else if (data.password !== data.confirmPassword) {
      setError({ ...error, confirmPassword: "Password Not Matched" });
      result = false;
    }

    return result;
  };

  //update password
  const handleUpdatePassword = (e) => {
    e.preventDefault();
    if (validate()) {
      app.showLoader(true, "wait for a minute");
      const passwordPayload = {
        email: data.email,
        password: data.password,
      };
      AuthApiService.forgetPassword(JSON.stringify(passwordPayload))
        .then((res) => {
          app.showLoader(false, "wait over");
          navigate("/");
        })
        .catch((e) => {
          app.showLoader(false, "wait over");
        });
    }
  };

  return (
    <div className="login_bg">
      <div className="login_box">
        <form className="login_form_bg" onSubmit={handleUpdatePassword}>
          <div className="login_signup_heading">Change Password</div>
          <div className="login_inputs">
            <WebInput
              type="text"
              placeholder="Enter Your Email Id"
              onChange={verified ? null : handleOnChange}
              error={error.email}
              value={data.email}
              style={{
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderRadius: 0,
              }}
              name="email"
              eye={
                verified ? (
                  <MdVerifiedUser color={"#a3ec26"} />
                ) : (
                  <p
                    style={{
                      fontSize: AppFonts.fontSizeXXSmall,
                      fontWeight: "11px",
                      color: "white",
                      backgroundColor: "#a3ec26",
                      padding: "8px",
                      textWrap: "nowrap",
                      borderRadius: "7px",
                    }}
                  >
                    {isOTP ? "Resend OTP" : "Send OTP"}
                  </p>
                )
              }
              handleType={verified ? null : sendOtpToEmail}
            />

            {emailOtpArea ? (
              <OtpInputs
                handleVerify={handleVerifyEmail}
                setOtp={setEmailOtp}
                otp={emailOtp}
              />
            ) : null}

            <WebInput
              placeholder="Enter New Password"
              type={newPasswordInput ? "password" : "text"}
              onChange={handleOnChange}
              error={error.password}
              value={data.password}
              style={{
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderRadius: 0,
              }}
              name="password"
              handleType={handleNewPasswordType}
              eye={handleNewPasswordEye()}
            />

            <WebInput
              placeholder="Confirm Password"
              type={confirmPasswordInput ? "password" : "text"}
              onChange={handleOnChange}
              error={error.confirmPassword}
              value={data.confirmPassword}
              style={{
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderRadius: 0,
              }}
              name="confirmPassword"
              handleType={handleConfirmPasswordType}
              eye={handleConfirmPasswordEye()}
            />
          </div>

          <WebButton
            onClickEvent={null}
            text="Submit"
            style={null}
            className="submit_btn"
            type="submit"
          />
        </form>
      </div>
    </div>
  );
}
