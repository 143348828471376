import { APIUrl, NetworkConfiguration } from "../network/NetworkConfriguation";
import { callAPI } from "../network/NetworkConnection";

/**
 * @author Diksha Lalit.
 * @since 19-01-2024.
 * @description  to get suppoet category list.
 */

const getSupportCategory = () => {
  return new Promise((resolve, reject) => {
    callAPI(APIUrl + NetworkConfiguration.SUPPORT_CATEGORY, "GET", {})
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 19-01-2024.
 * @description  to raise ticket.
 */

const raiseTicket = (ticketPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.RAISE_TICKET,
      "POST",
      {
        "content-type": "application/json",
      },
      ticketPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 22-01-2024.
 * @description  to get all ticket.
 */

const getTickets = (ticketPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.GET_TICKETS,
      "POST",
      {
        "content-type": "application/json",
      },
      ticketPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 23-01-2024.
 * @description  to get ticket by id.
 */

const getTicketById = (ticketId) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.GET_TICKET_BY_ID + `/${ticketId}`,
      "GET",
      {}
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 22-01-2024.
 * @description  to get all ticket.
 */

const updateTicket = (ticketPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.UPDATE_TICKET,
      "POST",
      {
        "content-type": "application/json",
      },
      ticketPayload
    )
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const SupportApiService = {
  getSupportCategory,
  raiseTicket,
  getTickets,
  getTicketById,
  updateTicket,
};
