/* eslint-disable no-unused-vars */
import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "../components/common/layout";
import ProtectRoute from "../components/common/protectRoute";
import Dashboard from "../pages/Dashboard";
import ChangePassword from "../pages/changePassword";
import ContractList from "../pages/contractsList";
import STWallet from "../pages/cryptoWallet";
import EarningWallet from "../pages/earningWallet";
import ForgetPassword from "../pages/forgetPassword";
import KycDocumentation from "../pages/kycDocumentation";
import Login from "../pages/login";
import Networks from "../pages/network";
import NewContracts from "../pages/newContracts";
import Notification from "../pages/notification";
import Profile from "../pages/profile";
import EditProfile from "../pages/profile/editProfile";
import Registration from "../pages/registration";
import Reports from "../pages/reports";
import Rewards from "../pages/rewards";
import CreateTicket from "../pages/support/createTicket";
import MyTickets from "../pages/support/myTickets";
import TicketReply from "../pages/ticketReply";
import Withdrawals from "../pages/withdrawals";
import STToken from "../pages/AddCoin/index";
import TransactionHistory from "../pages/TransactionHistory/index";
import Convert from "../pages/Convert/index";
// import Navbar from "../components/Website ui/Navbar";
// import AboutUs from "../components/Website ui/AboutUs";
// import TermsConditions from "../components/Website ui/TermsConditions";
// import "../../src/components/Website ui/website.css";
import NetworkContractList from "../pages/network/contactlist";
import DownlineContractlist from "../pages/network/DownlineContractList";

export default function PageRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/Login" element={<Login />} />
        {/* <Route path="/AboutUs" element={<AboutUs />} /> */}
        {/* <Route path="/TermsConditions" element={<TermsConditions />} /> */}
        {/* <Route path="/" element={<Navbar />} /> */}
        <Route path="/registration" element={<Registration />} />

        <Route
          path="/dashboard"
          element={
            <ProtectRoute>
              <Layout>
                <Dashboard />
              </Layout>
            </ProtectRoute>
          }
        />

        <Route
          path="/earningWallet"
          element={
            <ProtectRoute>
              <Layout>
                <EarningWallet />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/STToken"
          element={
            <ProtectRoute>
              <Layout>
                <STToken />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/Convert"
          element={
            <ProtectRoute>
              <Layout>
                <Convert />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/STWallet"
          element={
            <ProtectRoute>
              <Layout>
                <STWallet />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/contracts"
          element={
            <ProtectRoute>
              <Layout>
                <ContractList />
              </Layout>
            </ProtectRoute>
          }
        />

        <Route
          path="/contracts/newContracts"
          element={
            <ProtectRoute>
              <Layout>
                <NewContracts />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/support/myTickets"
          element={
            <ProtectRoute>
              <Layout>
                <MyTickets />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/support/createNewTicket"
          element={
            <ProtectRoute>
              <Layout>
                <CreateTicket />
              </Layout>
            </ProtectRoute>
          }
        />

        <Route
          path="/network"
          element={
            <ProtectRoute>
              <Layout>
                {/* <DownlineNetwork /> */}
                <Networks />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/NetworkContractList"
          element={
            <ProtectRoute>
              <Layout>
                <NetworkContractList />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/DownlineContractlist"
          element={
            <ProtectRoute>
              <Layout>
                <DownlineContractlist />
              </Layout>
            </ProtectRoute>
          }
        />

        <Route
          path="/reports"
          element={
            <ProtectRoute>
              <Layout>
                <Reports />
              </Layout>
            </ProtectRoute>
          }
        />

        <Route
          path="/kycDocumentation"
          element={
            <ProtectRoute>
              <Layout>
                <KycDocumentation />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/myProfile"
          element={
            <ProtectRoute>
              <Layout>
                <Profile />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/changePassword"
          element={
            <ProtectRoute>
              <Layout>
                <ChangePassword />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/ticketReply"
          element={
            <ProtectRoute>
              <Layout>
                <TicketReply />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/myProfile/updateProfile"
          element={
            <ProtectRoute>
              <Layout>
                <EditProfile />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/notifications"
          element={
            <ProtectRoute>
              <Layout>
                <Notification />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/withdrawals"
          element={
            <ProtectRoute>
              <Layout>
                <Withdrawals />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/rewards"
          element={
            <ProtectRoute>
              <Layout>
                <Rewards />
              </Layout>
            </ProtectRoute>
          }
        />
        <Route
          path="/transactionHistory"
          element={
            <ProtectRoute>
              <Layout>
                <TransactionHistory />
              </Layout>
            </ProtectRoute>
          }
        />

        <Route path="/forgetPassword" element={<ForgetPassword />} />
      </Routes>
    </BrowserRouter>
  );
}

const routes = [
  {
    path: "/dashboard",
    component: Dashboard,
  },

  {
    path: "/earningWallet",
    component: EarningWallet,
  },
  {
    path: "/STToken",
    component: STToken,
  },
  {
    path: "/Convert",
    component: Convert,
  },
  {
    path: "/STWallet",
    component: STWallet,
  },
  {
    path: "/contracts",
    component: ContractList,
  },
  {
    path: "/contracts/newContracts",
    component: NewContracts,
  },
  {
    path: "/support/myTickets",
    component: MyTickets,
  },
  {
    path: "/support/createNewTicket",
    component: CreateTicket,
  },
  {
    path: "/network",
    component: Networks,
  },
  {
    path: "/reports",
    component: Reports,
  },
  {
    path: "/kycDocumentation",
    component: KycDocumentation,
  },
  {
    path: "/myProfile",
    component: Profile,
  },
  {
    path: "/changePassword",
    component: ChangePassword,
  },
  {
    path: "/ticketReply",
    component: TicketReply,
  },
  {
    path: "/myProfile/updateProfile",
    component: EditProfile,
  },
  {
    path: "/notifications",
    component: Notification,
  },
  {
    path: "/withdrawals",
    component: Withdrawals,
  },
  {
    path: "/rewards",
    component: Rewards,
  },
  {
    path: "/transactionHistory",
    component: TransactionHistory,
  },
];
