import React, { useEffect, useState } from "react";
import Loading from "../../components/library/loader/loader";
import KycForm from "../../components/pages/kycDocumentation/KycForm";
import KycReject from "../../components/pages/kycDocumentation/KycReject";
import KycSubmitted from "../../components/pages/kycDocumentation/KycSubmitted";
import useGetUserProfile from "../../hooks/user/getUserProfile.hook";
import KycAccept from "./../../components/pages/kycDocumentation/KycAccept/index";
import "./style.css";

export default function KycDocumentation() {
  const [kycStatusValue, setKycStatusValue] = useState(null);
  const [component, setComponent] = useState(null);
  const userProfile = useGetUserProfile();
  const userProfileData = userProfile.data;

  useEffect(() => {
    setKycStatusValue(userProfileData?.user?.KYCStatus);
    switch (kycStatusValue) {
      case "Pending": {
        return setComponent(<KycForm />);
      }
      case "Submitted": {
        return setComponent(<KycSubmitted />);
      }
      case "Accepted": {
        return setComponent(<KycAccept />);
        // return setComponent(<KycForm />);
      }
      case "Rejected": {
        return setComponent(<KycReject />);
      }
      default: {
        return setComponent(<KycForm />);
      }
    }
  }, [userProfileData, kycStatusValue]);

  if (userProfile.isLoading) {
    return <Loading />;
  }

  return <div className="kyc_documentation_bg">{component}</div>;
}
