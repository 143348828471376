import React from "react";
import "./style.css";
import useReApplyKYC from "../../../../hooks/user/kyc.hook";
import Loading from "../../../library/loader/loader";

export default function KycReject() {
  const replyKYC = useReApplyKYC();

  if (replyKYC.isPending) {
    return <Loading />;
  }

  return (
    <div>
      <div
        style={{
          marginBlock: "20px",
        }}
        className="kyc_reject_box"
      >
        Sorry! Your KYC Verification has been Rejected.
      </div>

      <button
        onClick={() => {
          replyKYC.mutate();
        }}
        className="submit_btn"
        style={{
          padding: "10px 20px",
        }}
      >
        Re-apply KYC
      </button>
    </div>
  );
}
