import React, { useEffect, useState } from "react";
import { DataTable } from "../../components/common/table/Data-Table";
import axios from "axios";
import { APIUrl } from "../../network/NetworkConfriguation";
import { useLocation } from "react-router-dom";
import { DateTimeFormat } from "../../helper/utils";

const Contactlist = () => {
  const [data, setData] = useState();
  const state = useLocation();
  console.log(state);
  const handledirectcontactlist = () => {
    axios
      .post(APIUrl + "/contractByUser", {
        id: state.state,
      })

      .then((res) => {
        console.log(res.data);
        setData(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handledirectcontactlist();
  }, []);
  const ContactListColumns = [
    {
      header: "S.No",
      cell: ({ row }) => <span>{row.index + 1}</span>,
    },

    {
      header: "Contract Id",
      cell: ({ row }) => <span>{row.original.contractId?.id}</span>,
    },
    {
      header: "Purchase Id",
      cell: ({ row }) => <span>{row?.original.id}</span>,
    },
    {
      header: "Start Date",
      cell: ({ row }) => DateTimeFormat(row.original.startDate),

    },
    {
      header: "End Date",
      cell: ({ row }) => DateTimeFormat(row.original.enddate),

    },
    {
      header: "Validity",
      accessorKey: "validity",
    },
  ];
  return <DataTable columns={ContactListColumns} data={data} />;
};

export default Contactlist;
