import React from "react";
import "./style.css";

export default function KycAccept() {
  return (
    <div className="kyc_accept_box green_gradient">
      Congratulations! Your KYC Verification has been approved.
    </div>
  );
}
