import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "./../config/apiConfig";
const changeProfile = async (image) => {
  console.log("image", image);

  await api.put("/profilePicture", image, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const useUpdateProfile = () => {
  const queryClient = useQueryClient();

  const upateProfileMutation = useMutation({
    mutationFn: changeProfile,

    onSuccess: (data) => {
      queryClient.invalidateQueries(["userProfile"]);
    },
  });

  return upateProfileMutation;
};

export default useUpdateProfile;
