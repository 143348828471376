import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { DataTable } from "../../components/common/table/Data-Table";
import WebButton from "../../components/library/webButton";
import { DateTimeFormat } from "../../helper/utils";
import useGetAllContractsList from "../../hooks/contracts/getAllContractsList";
import "./style.css";
import { useNavigate } from "react-router-dom";

const handleTotalInterset = (contract) => {
  const array = contract?.returns?.map((item) => {
    return item.percentage;
  });
  let sum = 0;
  for (let i = 0; i < array?.length; i++) {
    sum += array[i];
  }
  return sum;
};

const handleIntersetAmount = (amount, percentage) => {
  return amount * (percentage / 100);
};

const handleTotalAmount = (contract) => {
  const array = contract?.returns?.map((item) => {
    return item.percentage;
  });
  let sum = 0;
  for (let i = 0; i < array?.length; i++) {
    sum += array[i];
  }
  return contract.amount * (sum / 100);
};
export default function NewContracts() {
  const [activeContractId, setActiveContractId] = useState("");
  const [contractDetails, setContractDetails] = useState(false);
  const navigate = useNavigate();

  const { getAllContracts, purchaseNewContractMutation } =
    useGetAllContractsList();

  const contracts = getAllContracts?.data;

  const handleBuyContract = async (id) => {
    const contractPayload = {
      contractId: id,
    };

    try {
      const response = await purchaseNewContractMutation.mutateAsync(
        contractPayload
      );
      console.log(response, "response");
    } catch (error) {
      console.log(error.message, "purchaseNewCOntractMutation");
      if (error.message === "complete your kyc first") {
        setTimeout(() => {
          navigate("/kycDocumentation");
        }, 1000);
      } else {
        setTimeout(() => {
          navigate("/STToken");
        }, 1000);
      }
      // if (error.response && error.response.status === 400) {

      // } else {
      //   // Handle other errors
      // }
    }

    // purchaseNewContractMutation.mutate(contractPayload);
  };

  const handleContractDetails = (e) => {
    if (activeContractId === e) {
      setActiveContractId("");
      return;
    }

    setActiveContractId(e);
  };

  const contractListColumns = [
    {
      header: "S.No",
      cell: ({ row }) => row.index + 1,
    },

    {
      header: "Contract Id",
      cell: ({ row }) => row.original.id,
    },
    {
      header: "For New User",
      cell: ({ row }) => (row?.original?.forNewUser ? "true" : "false"),
    },

    {
      header: "ST Token",
      // accessorKey:"price",
      cell: ({ row }) =>
        row.original.amount <= 1 ? 1 : Math.ceil(row.original.amount),
    },
    {
      header: "Validity",
      cell: ({ row }) => row.original.validity + " months",
    },
    {
      header: "Returns",
      cell: ({ row }) => {
        return (
          <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
            {handleTotalInterset(row.original)} %
            {row.original.returns.length > 0 ? (
              activeContractId === row.original.id ? (
                <IoIosArrowUp

                  style={{ cursor: "pointer",marginLeft: "5px" }}
                  onClick={() => handleContractDetails(row.original.id)}
                />
              ) : (
                <IoIosArrowDown
                  style={{ cursor: "pointer",marginLeft:"5px" }}
                  onClick={() => handleContractDetails(row.original.id)}
                />
              )
            ) : null}
          </div>
        );
      },
    },
    {
      header: "Buy Now",
      cell: ({ row }) => (
        <span
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <WebButton
            onClickEvent={() => handleBuyContract(row.original.id)}
            text="Convert"
            style={null}
            className="refresh_btn"
            type="button"
          />
        </span>
      ),
    },
  ];

  return (
    <DataTable
      columns={contractListColumns}
      data={contracts}
      subComponent={ContractDetail}
      activeId={activeContractId}
    />
  );
}

const ContractDetail = (row) => {
  return (
    <tr className="contract_details">
      <td colSpan={11}>
        <div className="contract_details_head">Contract Details</div>
        <div>
          <p>Month</p>
          <p>Interest</p>
          <p>Interest Amount</p>
          <p>Stb Date</p>
        </div>

        {row?.returns?.map((item, index) => {
          return (
            <div>
              <p>{index + 1}</p>
              <p>{item.percentage} % </p>
              <p className="web_table_green_td">
                {handleIntersetAmount(row?.amount, item?.percentage)}
              </p>
              <p>{DateTimeFormat(item.month)}</p>
            </div>
          );
        })}

        <div>
          <p className="contract_details_grand_total">Grand Total</p>
          <p>{handleTotalInterset(row)} %</p>
          <p>{handleTotalAmount(row)}</p>
          <p></p>
        </div>
      </td>
    </tr>
  );
};
