import { useQuery } from "@tanstack/react-query";
import { ContractApiService } from "../../service/contractApiService";

const useGetContracts = () => {
  const getActiveContract = useQuery({
    queryKey: ["getActiveContracts"],
    queryFn: ContractApiService.fetchActiveContract,
  });

  return getActiveContract;
};

export default useGetContracts;
