import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import Modal from "react-modal";
import { z } from "zod";
import { customStyles } from ".";

import { useForm } from "react-hook-form";
import useWithdraw from "../../hooks/earning/withdraw.hook";
import { Navigate, useNavigate } from "react-router-dom";
import useGetUserProfile from "../../hooks/user/getUserProfile.hook";
import { toast } from "react-toastify";

const withdrawSchema = z.object({
  amount: z
    .string()
    .min(1, "Amount is required")
    .max(10, "Amount is too long")
    .regex(/^\d+$/, "Amount should only contain digits")
    .regex(/^\S+$/, "Amount should not contain whitespace"),
});

export function WithdrawPopup({ setIsOpen, isOpen }) {
  // const [amount, setAmount] = useState("");
  // const [error, setError] = useState("");
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(withdrawSchema),
  });

  const { withdrawMutation } = useWithdraw();
  const userProfile = useGetUserProfile();

  const withdrawSubmit = (data) => {
    console.log(data);
    if (userProfile.data.user.balance < data.amount) {
      toast.error("you dont have enough coin");
    } else {
      navigate("/Convert", { state: data.amount });
    }

    // console.log
    // if (
    //   data.amount === "" ||
    //   data.amount === null ||
    //   data.amount === undefined ||
    //   data.amount === "0"
    // ) {
    //   return;
    // }
    // const withdrawPayload = {
    //   amount: parseInt(data.amount),
    // };
    // withdrawMutation.mutate(withdrawPayload);
    // setIsOpen(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      contentLabel="Example Modal"
    >
      <form
        onSubmit={handleSubmit(withdrawSubmit)}
        className="withdraw_modal_bg"
      >
        <h3>Withdraw</h3>

        <input
          className="input_wrapper"
          type="number"
          placeholder="Enter Withdraw Amount"
          {...register("amount")}
        />

        {errors.amount && <p className="error">{errors.amount.message}</p>}

        <button type="submit" className="submit_btn">
          Withdraw
        </button>
      </form>
    </Modal>
  );
}

// const handleAmount = (e) => {
//   setAmount(e.target.value.replace(RejexConstants.numRejex, ""));
//   setError("");
// };
// const validate = () => {
//   let result = true;
//   if (!amount) {
//     setError("Invalid Amount");
//     result = false;
//   }
//   return result;
// };
// const handleWithdraw = () => {
//   if (validate()) {
//     const withdrawPayload = {
//       amount: parseInt(amount),
//     };
//     EarningApiService.withdraw(JSON.stringify(withdrawPayload))
//       .then((res) => {
//         app.showLoader(false, "wait over");
//         message.showToast(res.message, ToastTypes.SUCCESS);
//         setIsOpen(false);
//         navigate("/withdrawals");
//       })
//       .catch((e) => {
//         app.showLoader(false, "wait over");
//         setIsOpen(false);
//         if (e === "Failed to fetch") {
//           message.showToast(
//             "No Internet Connection or Server Down! Try Later.",
//             ToastTypes.ERROR
//           );
//         } else {
//           e.then((res) => {
//             message.showToast(res.message, ToastTypes.ERROR);
//           });
//           e.catch((res) => {
//             message.showToast(res.message, ToastTypes.ERROR);
//           });
//         }
//       });
//   }
// };
