import api from "../config/apiConfig";
import { APIUrl, NetworkConfiguration } from "../network/NetworkConfriguation";
import { callAPI } from "../network/NetworkConnection";

/**
 * @author Diksha Lalit.
 * @since 15-01-2024.
 * @description  to get contract list .
 */
// const fetchContracts = () => {
//   return new Promise((resolve, reject) => {
//     callAPI(APIUrl + NetworkConfiguration.GET_CONTRACT_LIST, "GET", {})
//       .then(async (res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

const fetchContracts = async () => {
  const res = await api.get(NetworkConfiguration.GET_CONTRACT_LIST);
  return res.data.data;
};

/**
 * @author Diksha Lalit.
 * @since 15-01-2024.
 * @description  to get user Active contracts .
 */
const fetchActiveContracts = () => {
  return new Promise((resolve, reject) => {
    callAPI(APIUrl + NetworkConfiguration.GET_ACTIVE_CONTRACTS, "POST", {})
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const fetchActiveContract = async () => {
  const res = await api.post(NetworkConfiguration.GET_ACTIVE_CONTRACTS, {});
  return res.data.data;
};

/**
 * @author Diksha Lalit.
 * @since 15-01-2024.
 * @description  to purchase new contract .
 */
// const purchaseNewContract = (contractPayload) => {
//   return new Promise((resolve, reject) => {
//     callAPI(
//       APIUrl + NetworkConfiguration.PURCHASE_NEW_CONTRACTS,
//       "POST",
//       {
//         "content-type": "application/json",
//       },
//       contractPayload
//     )
//       .then(async (res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

const purchaseNewContract = async (contractPayload) => {
  const res = await api.post(
    NetworkConfiguration.PURCHASE_NEW_CONTRACTS,
    contractPayload
  );
  return res.data.data;
};

const renewContract = async (purchasedContractId) => {
  const res = await api.post(NetworkConfiguration.RENEW_CONTRACT, {
    purchasedContractId,
  });
  return res.data.data;
};

export const ContractApiService = {
  fetchContracts,
  fetchActiveContracts,
  purchaseNewContract,
  fetchActiveContract,
  renewContract,
};
