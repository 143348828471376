/* eslint-disable react-hooks/rules-of-hooks */
import api from "../config/apiConfig";
import { APIUrl, NetworkConfiguration } from "../network/NetworkConfriguation";
import { callAPI } from "../network/NetworkConnection";
import useIsVarified from "../storage/membershipid";

/**
 * @author Diksha Lalit.
 * @since 17-01-2024.
 * @description  to get user profile.
 */
// const fetchUserProfile = () => {
//   return new Promise((resolve, reject) => {
//     callAPI(APIUrl + NetworkConfiguration.GET_USER_PROFILE, "GET", {})
//       .then(async (res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

/**
 * @author Pratham.
 * @since 16-02-2024.
 * @description  to get user profile.
 */

const fetchUserProfile = async () => {
  const res = await api.get(NetworkConfiguration.GET_USER_PROFILE);
  return res.data.data;
};

const firstTimePassword = async (data) => {
  const res = await api.post(NetworkConfiguration.FIRST_TIME_PASSWORD, data);
  return res.data.data;
};

/**
 * @author Diksha Lalit.
 * @since 19-01-2024.
 * @description  to verify referal code on registration.
 */
const VerifyReferalCode = (referralPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.REFERRAL_VERIFICATION,
      "POST",
      {
        "content-type": "application/json",
      },
      referralPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const varifyMembershipId = (membershipId) => {
  // const res = await api.post(NetworkConfiguration.VERIFY_MEMBERSHIP, {
  //   referralCode: membershipId,
  // });

  // console.log(res, "res");
  const { setIsVarified } = useIsVarified();

  api
    .post(NetworkConfiguration.VERIFY_MEMBERSHIP, {
      referralCode: membershipId,
    })
    .then((res) => {
      console.log(res, "res");
      setIsVarified(true);
      return res;
    })
    .catch((err) => {
      console.log(err, "err");
      return err;
    });
};

/**
 * @author Diksha Lalit.
 * @since 19-01-2024.
 * @description  to send otp to email.
 */
const sendOtpToEmail = (emailPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.SEND_OTP_TO_MAIL,
      "POST",
      {
        "content-type": "application/json",
      },
      emailPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 19-01-2024.
 * @description  to verify email otp.
 */
const verifyEmailOtp = (otpPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.VERIFY_MAIL_OTP,
      "POST",
      {
        "content-type": "application/json",
      },
      otpPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 19-01-2024.
 * @description  to send otp to phone.
 */
const sendOtpToPhone = (phonePayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.SEND_OTP_TO_PHONE,
      "POST",
      {
        "content-type": "application/json",
      },
      phonePayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 19-01-2024.
 * @description  to verify phone otp.
 */
const verifyPhoneOtp = (otpPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.VERIFY_PHONE_OTP,
      "POST",
      {
        "content-type": "application/json",
      },
      otpPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 19-01-2024.
 * @description  to verify phone otp.
 */
const registerUser = (userPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.REGISTER_USER,
      "POST",
      {
        "content-type": "application/json",
      },
      userPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 22-01-2024.
 * @description  to change password.
 */
const changePassword = (passwordPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.CHANGE_PASSWORD,
      "POST",
      {
        "content-type": "application/json",
      },
      passwordPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 24-01-2024.
 * @description  to update profile.
 */
const updateProfile = (profilePayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.UPDATE_PROFILE,
      "PUT",
      {
        "content-type": "application/json",
      },
      profilePayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 29-01-2024.
 * @description  to get State City By Pincode.
 */
const getStateCityByPin = (pincodePayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.STATE_CITY_BY_PIN,
      "POST",
      {
        "content-type": "application/json",
      },
      pincodePayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 08-02-2024.
 * @description  to send otp to email for forget password.
 */
const sendOtpToEmailforForget = (emailPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.SEND_OTP_TO_MAIL_FOR_FORGET,
      "POST",
      {
        "content-type": "application/json",
      },
      emailPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

/**
 * @author Diksha Lalit.
 * @since 08-02-2024.
 * @description  to forget password.
 */
const forgetPassword = (passwordPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.FORGET_PASSWORD,
      "POST",
      {
        "content-type": "application/json",
      },
      passwordPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const AuthApiService = {
  fetchUserProfile,
  VerifyReferalCode,
  sendOtpToEmail,
  verifyEmailOtp,
  sendOtpToPhone,
  verifyPhoneOtp,
  registerUser,
  changePassword,
  updateProfile,
  getStateCityByPin,
  sendOtpToEmailforForget,
  forgetPassword,
  firstTimePassword,
  varifyMembershipId,
};
