import React, { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import WebButton from "../../components/library/webButton";
import { DateTimeFormat } from "../../helper/utils";
import { SupportApiService } from "../../service/supportApiService";
import { useApp } from ".././../base/context/AppProviders";
import "./style.css";

export default function TicketReply() {
  const [message, setMessage] = useState("");
  const [searchParams, setSearchParams] = useSearchParams();
  const [ticket, setTicket] = useState([]);
  const [ticketStatus, setTicketStatus] = useState([]);
  const [description, setDescription] = useState("");
  const [close, setClose] = useState(false);
  const [error, setError] = useState("");

  const app = useApp();

  const handleTicketById = () => {
    app.showLoader(true, "wait for a minute");
    SupportApiService.getTicketById(searchParams.get("id"))
      .then((res) => {
        app.showLoader(false, "wait over");
        setTicket(res.data.ticket);
        setTicketStatus(res.data.status);
        setDescription(res.data.description);
      })
      .catch((err) => {
        app.showLoader(false, "wait over");
      });
  };

  useEffect(() => {
    handleTicketById();
  }, []);

  const handleMessage = (e) => {
    setMessage(e.target.value);
    setError("");
  };

  const scrollContainerRef = useRef(null);

  // Function to scroll to the bottom
  const scrollToBottom = () => {
    if (scrollContainerRef.current) {
      const scrollHeight = scrollContainerRef.current.scrollHeight;
      const clientHeight = scrollContainerRef.current.clientHeight;
      const maxScrollTop = scrollHeight - clientHeight;
      scrollContainerRef.current.scrollTop = maxScrollTop;
    }
  };

  // Scroll to the bottom on component mount or when content changes
  useEffect(() => {
    scrollToBottom();
  }, [ticket]);

  const handleClose = (e) => {
    setClose(e.target.checked);
  };

  const validate = () => {
    let result = true;
    if (!message) {
      setError("*Invalid Message");
      result = false;
    }

    return result;
  };

  const handleReply = () => {
    if (validate()) {
      app.showLoader(true, "wait for a minute");
      const ticketPayload = {
        ticketId: searchParams.get("id"),
        content: message,
        sender: "user",
        close: close,
      };
      SupportApiService.updateTicket(JSON.stringify(ticketPayload))
        .then((res) => {
          setMessage("");
          app.showLoader(false, "wait over");
          toast.success(res.message);
          handleTicketById();
        })
        .catch((e) => {
          app.showLoader(false, "wait over");
          toast.error(e.message);
        });
    }
  };

  return (
    <div className="ticket_reply_box">
      <div
        className={
          ticketStatus === "Open"
            ? "ticket_reply_Messages"
            : "ticket_reply_all_Messages"
        }
        id="Messages_body"
        ref={scrollContainerRef}
      >
        {ticket.length &&
          ticket?.map((item, index) => {
            return (
              <div
                className={
                  item.sender === "user" ? "out_message" : "in_message"
                }
                key={index}
              >
                {item.content}
                <p className="ticket_message_date">
                  {DateTimeFormat(item.createdAt)}
                </p>
                {index === 0 && <p>{description}</p>}
              </div>
            );
          })}
      </div>

      {ticketStatus === "Open" ? (
        <div className="reply_box">
          <div className="reply_box_container">
            <textarea
              placeholder="enter your message here..."
              className="reply_textarea"
              onChange={handleMessage}
              value={message}
            />
            <div className="close_ticket_message">
              <input type="checkbox" value={close} onClick={handleClose} />
              <p>Close Ticket</p>
              <p className="ticket_error">{error}</p>
            </div>
          </div>

          <WebButton
            onClickEvent={handleReply}
            text="Submit Reply"
            style={null}
            className="package_button"
            type="button"
          />
        </div>
      ) : null}
    </div>
  );
}
