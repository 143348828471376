import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { KycApiService } from "../../service/kycApiService";

const useReApplyKYC = () => {
  const queryClient = useQueryClient();

  const replyKYC = useMutation({
    mutationFn: KycApiService.reApplyKYC,

    onError: (error) => {
      console.error("Error in re-apply KYC", error);
    },

    onSuccess: (data) => {
      queryClient.invalidateQueries(["userProfile"]);
    },
  });

  return replyKYC;
};

export default useReApplyKYC;
