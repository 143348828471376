import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import useGetUserProfile from "../../../hooks/user/getUserProfile.hook";
import { DateTimeFormat } from "./../../../helper/utils";
import "./style.css";

export default function Breadcrumb() {
  let location = useLocation();
  const [pagePath, setPagePath] = useState([]);
  const [lastElement, setLastElement] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();

  const name = searchParams.get("name");

  const userProfile = useGetUserProfile();

  const user = userProfile.data?.user;

  useEffect(() => {
    const path = location.pathname.split("/").filter((item) => {
      return item !== "";
    });
    setPagePath(path);
  }, [location]);

  useEffect(() => {
    setLastElement(pagePath.pop()?.split(/(?=[A-Z])/));
  }, [pagePath]);

  return (
    <div className="page_path">
      <p style={{ color: "#a3ec26" }} className="active_page_head">
        {lastElement && lastElement}
      </p>
      <p className="active_page_path">
        {location.pathname === "/dashboard" ? (
          <span style={{ color: "#B9BBBD" }}>
            Welcome back, {user?.firstName} Last Login on{" "}
            {DateTimeFormat(user?.last_login)}
          </span>
        ) : (
          <>
            {pagePath.map((page) => {
              return page.replace(/%20/g, " ") + " / ";
            })}
            <span>
              {lastElement && lastElement}
              {name ? ` / ${name} ` : null}
            </span>
          </>
        )}
      </p>
    </div>
  );
}
