import { useQuery } from "@tanstack/react-query";
import { EarningApiService } from "../../service/earningApiService";

const useGetAllWithdraw = () => {
  const getAllWithdrawalList = useQuery({
    queryKey: "getAllWithdrawalList",
    queryFn: EarningApiService.getWithdrawals,
  });

  return { getAllWithdrawalList };
};

export default useGetAllWithdraw;
