import { useQuery } from "@tanstack/react-query";
import { AuthApiService } from "../../service/authApiService";

const useGetUserProfile = () => {
  const userProfile = useQuery({
    queryKey: ["userProfile"],
    queryFn: AuthApiService.fetchUserProfile,
  });

  return userProfile;
};

export default useGetUserProfile;
