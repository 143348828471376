import React from "react";
import "./style.css";

export default function WebTextArea({
  label,
  type,
  placeholder,
  onChange,
  error,
  value,
  onKeyUp,
  style,
  labelStyle,
  inputRef,
  onpaste,
}) {
  return (
    <div className="textarea_parent_div">
      <label style={labelStyle} className="label_style">
        {label}
      </label>

      <div className="textarea_wrapper" style={style}>
        <textarea
          ref={inputRef}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className="textarea_field"
          onKeyUp={onKeyUp}
          onPaste={onpaste}
        />
      </div>

      <p className="textarea_error">{error ? "*" + error : null}</p>
    </div>
  );
}
