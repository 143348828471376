import moment from "moment";

import kycIcon from "../assests/images/Icons Grey User/Group 3409.png";
import contractsIcon from "../assests/images/Icons Grey User/Group 3410.png";
import supportIcon from "../assests/images/Icons Grey User/Path 15422.png";
import reportsIcon from "../assests/images/Icons Grey User/Group 3408.png";
import cryptoWalletIcon from "../assests/images/Icons Grey User/Path 15419.png";
import dashboardIcon from "../assests/images/Icons Grey User/Path 15417.png";
import earningWalletIcon from "../assests/images/Icons Grey User/Path 15421.png";
import rewardsIcon from "../assests/images/Icons Grey User/ic_redeem_24px.png";
import networkIcon from "../assests/images/Icons Grey User/ic_settings_input_antenna_24px.png";
import CoinIcon from "../assests/images/Icons Grey User/Group 3412.png";
import ConvertIcon from "../assests/images/Icons Grey User/ic_cached_24px.png";
import WithdrawlsIcon from "../assests/images/Icons Grey User/ic_move_to_inbox_24px.png";
import TransactionHistoryIcon from "../assests/images/Icons Grey User/Group 3414.png";

import kycActiveIcon from "../assests/images/Icons Green User/Group 3418.png";
import contractsActiveIcon from "../assests/images/Icons Green User/Group 3416.png";
import supportActiveIcon from "../assests/images/Icons Green User/Path 15431.png";
import reportsActiveIcon from "../assests/images/Icons Green User/Group 3417.png";
import cryptoWalletActiveIcon from "../assests/images/Icons Green User/Path 15429.png";
import dashboardActiveIcon from "../assests/images/Icons Green User/Path 15428.png";
import earningWalletActiveIcon from "../assests/images/Icons Green User/Path 15430.png";
import rewardsActiveIcon from "../assests/images/Icons Green User/ic_redeem_24px.png";
import networkActiveIcon from "../assests/images/Icons Green User/network.png";
import CoinActiveIcon from "../assests/images/Icons Green User/Group 3415.png";
import ConvertActiveIcon from "../assests/images/Icons Green User/ic_cached_24px.png";
import WithdrawlsActiveIcon from "../assests/images/Icons Green User/ic_move_to_inbox_24px.png";
import TransactionHistoryActiveIcon from "../assests/images/Icons Green User/Group 3419.png";

export const SidebarUtility = [
  {
    name: "dashboard",
    icon: dashboardIcon,
    iconActive: dashboardActiveIcon,
    link: "/dashboard",
  },
  {
    name: "STWallet",
    icon: cryptoWalletIcon,
    iconActive: cryptoWalletActiveIcon,
    link: "/STWallet",
  },
  {
    name: "EarningWallet",
    icon: earningWalletIcon,
    iconActive: earningWalletActiveIcon,
    link: "/earningWallet",
  },
  {
    name: "STToken",
    icon: CoinIcon,
    iconActive: CoinActiveIcon,
    link: "/STToken",
  },
  {
    name: "Convert",
    icon: ConvertIcon,
    iconActive: ConvertActiveIcon,
    link: "/Convert",
  },

  {
    name: "contracts",
    icon: contractsIcon,
    iconActive: contractsActiveIcon,
    link: "/contracts",
  },
  {
    name: "network",
    icon: networkIcon,
    iconActive: networkActiveIcon,
    link: "/network",
  },
  {
    name: "support",
    icon: supportIcon,
    iconActive: supportActiveIcon,
    link: "/support/myTickets",
  },

  {
    name: "reports",
    icon: reportsIcon,
    iconActive: reportsActiveIcon,
    link: "/reports",
  },
  {
    name: "rewards",
    icon: rewardsIcon,
    iconActive: rewardsActiveIcon,
    link: "/rewards",
  },
  {
    name: "kycDocumentation",
    icon: kycIcon,
    iconActive: kycActiveIcon,
    link: "/kycDocumentation",
  },
  {
    name: "Withdrawals",
    icon: WithdrawlsIcon,
    iconActive: WithdrawlsActiveIcon,
    link: "/withdrawals",
  },
  {
    name: "TransactionHistory",
    icon: TransactionHistoryIcon,
    iconActive: TransactionHistoryActiveIcon,
    link: "/transactionHistory",
  },
];

export const DateFormat = (date) => {
  return moment(date).format("DD MMM, YYYY");
};

export const DateTimeFormat = (date) => {
  return moment(date).format("DD MMM, YYYY @ hh:mm a");
};

export const networkTabs = [
  { name: "Direct", link: "/network/direct" },
  { name: "Downline", link: "/network/downline" },
];

export const reportsTabs = [
  { name: "STB Income", link: "/reports/stbIncome" },
  { name: "Direct", link: "/reports/levelDirectIncome" },
  { name: "Downline", link: "/reports/levelDownlineIncome" },
];

export function getTimeDifference(startDate, endDate) {
  console.log(startDate, endDate, "date");
  if (!(startDate instanceof Date) || !(endDate instanceof Date)) {
    return "Invalid date objects";
  }

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return "Invalid dates";
  }

  const difference = endDate - startDate;
  const daysDifference = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hoursDifference = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );

  let result = "";

  if (daysDifference > 0) {
    result += `${daysDifference} days `;
  }
  if (hoursDifference > 0) {
    result += `${hoursDifference} hours `;
  }

  if (result === "") {
    return "Time expired";
  } else {
    return result + "left";
  }
}
