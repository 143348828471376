/* eslint-disable react-hooks/rules-of-hooks */
import React, { useEffect, useState } from 'react'
import {
    ButtonFacebook,
    ButtonLinkedIn,
    ButtonTwitter,
    ButtonWhatsapp,
  } from "share-button-links-react";
  
  import { CopyToClipboard } from "react-copy-to-clipboard";
  import { BsFillPatchCheckFill, BsWhatsapp } from "react-icons/bs";
  import { FaFacebookF, FaTwitter } from "react-icons/fa6";
  import { GrLinkedinOption } from "react-icons/gr";
  import { MdContentCopy } from "react-icons/md";
  import Logobitcoin from "../../assests/images/Crypto-Coin-hour-glass.png";
import useGetUserProfile from '../../hooks/user/getUserProfile.hook';

const index = () => {

    const [copyMessage, setCopyMessage] = useState(false);
    const [isPasswordSet, setIsPasswordSet] = useState(false);
    const [url, setUrl] = useState("");
  

    const userProfile = useGetUserProfile();
    const userProfileData = userProfile.data;

  
    const handleCopied = () => {
      setCopyMessage(true);
      setTimeout(() => {
        setCopyMessage(false);
      }, [2000]);
    };
  
    useEffect(() => {
      setUrl(
        `https://smarttreasure.net/registration/?referalCode=${userProfileData?.user?.referralCode}`
      );
  
      if (userProfileData?.user?.firstTimeLogin) setIsPasswordSet(true);
    }, [userProfileData]);
  

  return (
    <div>
    <div className="refral_link_new_ui">
      <div className="dashboard_referal_code_row">
        <div
          style={{ color: "#FFFFFF" }}
          className="dashboard_referal_code_head"
        >
          Your Referal Link
        </div>
        <div className="dashboard_referal_code_link green_gradient">
          <span>{url ? url : "referal link not found"}</span>
          <CopyToClipboard text={url} onCopy={() => handleCopied()}>
            <MdContentCopy className="referal_link_copy_icon" />
          </CopyToClipboard>
          {copyMessage ? (
            <div className="copied_message">
              Copied
              <span>
                <BsFillPatchCheckFill />
              </span>
            </div>
          ) : (
            <></>
          )}
        </div>

        <div className="dashboard_referal_code_social_row">
          <div
            style={{ color: "#FFFFFF" }}
            className="dashboard_referal_code_head"
          >
            Share Your Referal Link Via
          </div>

          <div className="share_btns">
            <div>
              <ButtonFacebook
                title="Referral Code"
                text={
                  <p>
                    <FaFacebookF size={20} />
                  </p>
                }
                url={url}
                isRounded
                hasIcon
              />
            </div>
            <div>
              <ButtonLinkedIn
                title="Referral Code"
                text={
                  <p>
                    <GrLinkedinOption size={20} />
                  </p>
                }
                url={url}
                isRounded
                hasIcon
              />
            </div>
            <div>
              <ButtonTwitter
                title="Referral Code"
                text={
                  <p>
                    <FaTwitter size={20} />
                  </p>
                }
                url={url}
                isRounded
                hasIcon
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ButtonWhatsapp
                title="ReferralCode"
                text={
                  <p>
                    <BsWhatsapp size={20} />
                  </p>
                }
                url={url}
                isRounded
                hasIcon
              />
              <a
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                href={`https://api.whatsapp.com/send?text=${
                  userProfileData?.user?.firstName +
                  " " +
                  userProfileData?.user?.lastName +
                  " " +
                  "has invited you to join Trading User. Copy the link to join" +
                  " " +
                  url
                } `}
                target="_blank"
                rel="noreferrer"
              >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src={Logobitcoin} alt="" className="logo_bitcoin" />

  </div>
  )
}

export default index