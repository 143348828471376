import { useQuery } from "@tanstack/react-query";
import { RewardsApiService } from "../../service/rewardsApiService";

const useGetAllRewardsEarned = () => {
  const getAllRewardsEarned = useQuery({
    queryKey: ["getAllRewardsEarned"],
    queryFn: RewardsApiService.getUserEarnedRewards,
  });

  return { getAllRewardsEarned };
};

export default useGetAllRewardsEarned;
