export const KycStatus = {
  KYC_PENDING: "Pending",
  KYC_SUBMITTED: "Submitted",
  KYC_ACCEPTED: "Accepted",
  KYC_REJECTED: "Rejected",
};

export const RejexConstants = {
  emailRegex: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/,
  phoneRegex: /^[0-9\b]+$/,
  numRejex: /\D/g,
  spaceRegex: /^\s*$/,
};
