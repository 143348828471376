import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppColor } from "../../../../assests/colors/colors";
import { AppFonts } from "../../../../assests/fonts/fonts";
import { useApp } from "../../../../base/context/AppProviders";

import { DateFormat } from "../../../../helper/utils";
import useGetUserProfile from "../../../../hooks/user/getUserProfile.hook";
import { kycRegistration } from "../../../../redux/actions";
import WebButton from "../../../library/webButton";
import WebInput from "../../../library/webInput";
import WebMultiSelectInput from "../../../library/webMultiSelectInput";
import "./style.css";

export default function KycForm() {
  const dispatch = useDispatch();
  const app = useApp();
  const [user, setUser] = useState(null);

  const [kycPayload, setKycPayload] = useState({
    aadharFront: "",
    aadharBack: "",
    panCard: "",
  });

  const [error, setError] = useState({
    aadhar: "",
    panCard: "",
  });

  // const userProfile = useSelector((state) => state.appReducers.userProfile);

  const userProfile = useGetUserProfile();
  const userProfileData = userProfile?.data;

  useEffect(() => {
    if (userProfileData) {
      setUser(userProfileData);
    }
  }, [userProfileData]);

  const handleAdharCard = (e) => {
    if (!kycPayload.aadharFront) {
      if (
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "application/pdf" ||
        e.target.files[0].type === "image/jpeg"
      ) {
        setKycPayload({ ...kycPayload, aadharFront: e.target.files[0] });
        setError({ ...error, aadhar: "" });
      } else {
        setError({ ...error, aadhar: "Invalid File Type" });
      }
    } else {
      if (
        e.target.files[0].type === "image/png" ||
        e.target.files[0].type === "application/pdf" ||
        e.target.files[0].type === "image/jpeg"
      ) {
        setKycPayload({ ...kycPayload, aadharBack: e.target.files[0] });
        setError({ ...error, aadhar: "" });
      } else {
        setError({ ...error, aadhar: "Invalid File Type" });
      }
    }
  };

  const handleRemoveAdhar = (e) => {
    if (kycPayload.aadharFront === e) {
      setKycPayload({ ...kycPayload, aadharFront: "" });
    } else if (kycPayload.aadharBack === e) {
      setKycPayload({ ...kycPayload, aadharBack: "" });
    }
  };

  const handleRemovePanCard = (e) => {
    if (kycPayload.panCard === e) {
      setKycPayload({ ...kycPayload, panCard: "" });
    }
  };

  const handlePanCard = (e) => {
    if (
      e.target.files[0].type === "image/png" ||
      e.target.files[0].type === "application/pdf" ||
      e.target.files[0].type === "image/jpeg"
    ) {
      setKycPayload({ ...kycPayload, panCard: e.target.files[0] });
      setError({ ...error, panCard: "" });
    } else {
      setError({ ...error, panCard: "Invalid File Type" });
    }
  };

  const validate = () => {
    let result = true;
    if (!kycPayload?.aadharFront) {
      setError({ ...error, aadhar: "Please Upload Aadhar Card Front Image" });
      result = false;
    } else if (!kycPayload.aadharBack) {
      setError({ ...error, aadhar: "Please Upload Aadhar Card Back Image" });
      result = false;
    } else if (!kycPayload.panCard) {
      setError({ ...error, panCard: "Please Upload PAN Card Front Image" });
      result = false;
    }
    return result;
  };

  const handleSubmitKycForm = () => {
    if (validate()) {
      const data = new FormData();
      data.append("aadharFront", kycPayload.aadharFront);
      data.append("aadharBack", kycPayload.aadharBack);
      data.append("panCard", kycPayload.panCard);

      app.showLoader(true, "wait for a minute");
      dispatch(kycRegistration(data, sendSuccess, sendError));
    }
  };

  const sendSuccess = (response) => {
    app.showLoader(false, "wait over");
  };

  const sendError = (e) => {
    app.showLoader(false, "wait over");
  };

  return (
    <div className="kyc_documentation_form_box">
      <WebMultiSelectInput
        label="Upload Adhar Card"
        onChange={handleAdharCard}
        handleRemove={handleRemoveAdhar}
        error={error.aadhar}
        message="Please upload front and back pictures of your Aadhar Card. Upload 2 files."
        style={{
          border: `1px solid ${"#a3ec26"}`,
        }}
        labelStyle={{
          color: AppColor.greyColor,
          fontWeight: AppFonts.fontMedium,
        }}
        values={[kycPayload.aadharFront, kycPayload.aadharBack]}
        accept="image/png, application/pdf, image/jpeg"
      />

      <WebMultiSelectInput
        label="Upload PAN Card"
        onChange={handlePanCard}
        handleRemove={handleRemovePanCard}
        error={error.panCard}
        message="Please upload front picture of your PAN Card"
        style={{
          border: `1px solid ${"#a3ec26"}`,

        }}
        labelStyle={{
          color: AppColor.greyColor,
          fontWeight: AppFonts.fontMedium,
        }}
        values={[kycPayload.panCard]}
        setValue={setKycPayload}
        accept="image/png, application/pdf, image/jpeg"
      />

      <WebInput
        label="Name"
        value={user?.user?.firstName + " " + user?.user?.lastName}
        style={{
          border: `1px solid ${"#a3ec26"}`,

        }}
        labelStyle={{
          color: AppColor.greyColor,
          fontWeight: AppFonts.fontMedium,
        }}
      />

      <WebInput
        label="Mobile Number"
        value={user?.user?.phone}
        style={{
          border: `1px solid ${"#a3ec26"}`,

        }}
        labelStyle={{
          color: AppColor.greyColor,
          fontWeight: AppFonts.fontMedium,
        }}
      />

      <WebInput
        name="dateOfBirth"
        label="Date Of Birth"
        value={DateFormat(user?.user?.dateOfBirth)}
        style={{
          border: `1px solid ${"#a3ec26"}`,

        }}
        labelStyle={{
          color: AppColor.greyColor,
          fontWeight: AppFonts.fontMedium,
        }}
      />

      <WebButton
        onClickEvent={handleSubmitKycForm}
        text="Upload"
        className="submit_btn"
        type="button"
      />
    </div>
  );
}
