import api from "../config/apiConfig";
import { NetworkConfiguration } from "../network/NetworkConfriguation";

const getStbIncome = async () => {
  const { data } = await api.get(NetworkConfiguration.GET_STB_INCOME);
  return data.data;
};

const getDirectIncome = async () => {
  const { data } = await api.get(NetworkConfiguration.GET_DIRECT_INCOME);
  console.log(data.data);
  return data.data;
};

const getDownlineIncome = async () => {
  const { data } = await api.get(NetworkConfiguration.GET_DOWNLINE_INCOME);
  return data.data;
};

export const ReportsApiService = {
  getStbIncome,
  getDirectIncome,
  getDownlineIncome,
};
