import React, { useEffect, useState } from "react";
import WebButton from "../webButton";
import "./style.css";

export default function WebVerifyOtp({
  setOtpValue,
  verifyOtp,
  resendOtp,
  message,
  close,
}) {
  const [error, setError] = useState("");
  const [second, setSecond] = useState(60);
  const [showTimer, setTimer] = useState(true);
  const [otp, setOtp] = useState({
    otpOne: "",
    otpTwo: "",
    otpThree: "",
    otpFour: "",
    otpFive: "",
    otpSix: "",
  });

  const handleNumberOne = (e) => {
    setOtp({ ...otp, otpOne: e.target.value });
  };
  const handleNumberTwo = (e) => {
    setOtp({ ...otp, otpTwo: e.target.value });
  };
  const handleNumberThree = (e) => {
    setOtp({ ...otp, otpThree: e.target.value });
  };
  const handleNumberFour = (e) => {
    setOtp({ ...otp, otpFour: e.target.value });
  };
  const handleNumberFive = (e) => {
    setOtp({ ...otp, otpFive: e.target.value });
  };
  const handleNumberSix = (e) => {
    setOtp({ ...otp, otpSix: e.target.value });
  };

  useEffect(() => {
    if (
      otp?.otpOne &&
      otp?.otpTwo &&
      otp?.otpThree &&
      otp?.otpFour &&
      otp?.otpFive &&
      otp?.otpSix
    ) {
      setOtpValue(
        otp?.otpOne +
          otp?.otpTwo +
          otp?.otpThree +
          otp?.otpFour +
          otp?.otpFive +
          otp?.otpSix
      );
    }
  }, [otp]);

  //add one element on input

  const onInput = (e) => (e.target.value = e.target.value.slice(0, 1));

  //handle focus input
  const inputFocus = (e) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      const next = e.target.tabIndex - 1;
      if (next > -1) {
        e.target.form.elements[next].focus();
      }
    } else {
      const next = e.target.tabIndex + 1;
      if (next < 6) {
        e.target.form.elements[next].focus();
      }
    }
  };

  //verify otp
  const handleVerify = () => {
    verifyOtp();
  };

  //60 sec count down timer
  const handleSeconds = () => {
    const interval = setInterval(() => {
      setSecond((prevSeconds) => {
        if (prevSeconds > 0 && showTimer) {
          return prevSeconds - 1;
        }
        return 0;
      });
    }, 1000);

    return () => clearInterval(interval);
  };

  useEffect(() => {
    handleSeconds();
  }, []);

  //Resend Otp
  const ReSendOtp = () => {
    if (second < 1) {
      resendOtp();
      handleSeconds();
    } else {
    }
  };

  return (
    <div className="verify_otp_bg">
      <div className="verify_otp_box">
        {close && (
          <span className="close_btn" onClick={close}>
            X
          </span>
        )}
        <p className="verify_otp_heading">{message}</p>

        {/* //Input Form Start--------------------------------- */}
        <form className="otp_input">
          <input
            type="number"
            tabIndex={0}
            value={otp?.otpOne}
            onChange={handleNumberOne}
            onInput={onInput}
            onKeyUp={inputFocus}
          ></input>
          <input
            type="number"
            tabIndex={1}
            value={otp?.otpTwo}
            onChange={handleNumberTwo}
            onInput={onInput}
            onKeyUp={inputFocus}
          ></input>
          <input
            type="number"
            tabIndex={2}
            value={otp?.otpThree}
            onChange={handleNumberThree}
            onInput={onInput}
            onKeyUp={inputFocus}
          ></input>
          <input
            type="number"
            tabIndex={3}
            value={otp?.otpFour}
            onChange={handleNumberFour}
            onInput={onInput}
            onKeyUp={inputFocus}
          ></input>
          <input
            type="number"
            tabIndex={4}
            value={otp?.otpFive}
            onChange={handleNumberFive}
            onInput={onInput}
            onKeyUp={inputFocus}
          ></input>
          <input
            type="number"
            tabIndex={5}
            value={otp?.otpSix}
            onChange={handleNumberSix}
            onInput={onInput}
            onKeyUp={inputFocus}
          ></input>
        </form>

        <p
          className="verify_otp_err"
          style={{ display: error ? "block" : "none" }}
        >
          *{error}
        </p>
        <p className="otp_time">00:{second < 10 ? "0" + second : second} sec</p>
        <p className="otp_receive_msg">
          Didn't Receive OTP ? <span onClick={ReSendOtp}>Resend</span>
        </p>
        <WebButton
          onClickEvent={handleVerify}
          type="submit"
          text="Verify & Proceed"
          className="submit_btn"
        />

        {/* //Input Form End--------------------------------- */}
      </div>
    </div>
  );
}
