import React, { useEffect, useState } from "react";
import "./style.css";
import coin from "../../assests/images/new st-coin-shine.png";
import RefralLinks from "../../components/RefralLinks/index";
import useGetUserProfile from "../../hooks/user/getUserProfile.hook";
import axios from "axios";
import { APIUrl } from "../../network/NetworkConfriguation";
import { getLocalStorage } from "../../storage/LocalStorage";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function CryptoWallet() {
  const navigate = useNavigate();
  const [sikka, setSikka] = useState();
  const requestHeaders = {};
  const token = getLocalStorage();
  const userToken = token.loginHeader;
  requestHeaders["Authorization"] = `Bearer ${userToken}`;
  const userProfile = useGetUserProfile();
  // const [balance, setBalance] = useState(null);

  const handlecryptowallet = () => {
    axios
      .get(APIUrl + "/coin", {
        headers: requestHeaders,
      })

      .then((res) => {
        console.log(res.data);
        setSikka(res.data.data);
      })

      .catch((err) => {
        console.log(err);
      });
  };
  const handleClick = () => {
    if (userProfile.data.user.balance > 0) {
      navigate("/Convert");
    } else {
      toast.error("You don't have enough coins to withdraw.");
      // navigate("/Convert");
    }
  };

  useEffect(() => {
    handlecryptowallet();
  }, []);

  return (
    <div className="st_wallet_responsive">
      <div className="crypto_wallet_card">
        <p className="crypto_wallet_card_name">
          <span>
            <img src={coin} alt="" className="bitt_coin" />
          </span>
          {/* Bitcoin [Trading] */}
        </p>
        <p className="crypto_wallet_card_wallet">ST Wallet</p>
        <p className="crypto_wallet_card_amount">
          {Number(userProfile?.data?.user?.coinQuantity)}
        </p>

        <p className="crypto_wallet_card_balace">Current Balance</p>
        <p>
          <button onClick={handleClick} type="btn" className="withdra_btn">
            Withdraw
          </button>
        </p>
      </div>
      <div>
        <RefralLinks />
      </div>
    </div>
  );
}
