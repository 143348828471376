import React, { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "../../../storage/LocalStorage";

export default function ProtectRoute({ children }) {
  const token = getLocalStorage();
  const navigate = useNavigate();

  useLayoutEffect(() => {
    if (!token.loginHeader) {
      navigate("/");
    }
  }, []);

  return <>{children}</>;
}
