import React, { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import WebButton from "../../components/library/webButton";
import { DateTimeFormat } from "../../helper/utils";
import useGetContracts from "../../hooks/contracts/getActiveContracts.hooks";
import useRenewContracts from "../../hooks/contracts/renewContracts.hooks";
import { DataTable } from "./../../components/common/table/Data-Table";
import Loading from "./../../components/library/loader/loader";
import RefralLinks from "../../components/RefralLinks/index";
import "./style.css";

const handleTotalInterset = (contract) => {
  const array = contract?.returns?.map((item) => item.percentage);
  return array?.reduce((sum, percentage) => sum + percentage, 0) || 0;
};

const handleIntersetAmount = (amount, percentage) => {
  return amount * (percentage / 100);
};

const handleTotalAmount = (contract) => {
  const totalPercentage = handleTotalInterset(contract);
  return contract?.amount * (totalPercentage / 100);
};

function isEndDateWithinMonth(endDate) {
  const oneMonthFromNow = new Date();
  oneMonthFromNow.setMonth(oneMonthFromNow.getMonth() + 1);
  return new Date(endDate).getTime() <= oneMonthFromNow.getTime();
}

export default function ContractList() {
  const navigate = useNavigate();
  const getActiveContract = useGetContracts();
  const renewContract = useRenewContracts();

  const activeContracts = getActiveContract?.data;

  const [activeContractId, setActiveContractId] = useState("");

  const handleAddContract = () => {
    navigate("/contracts/newContracts");
  };

  const handleContractDetails = (e) => {
    setActiveContractId(prevId => prevId === e ? "" : e);
  };

  const contractColumns = [
    {
      header: "S.No",
      cell: ({ row }) => row.index + 1,
    },
    {
      header: "Contract ID",
      cell: ({ row }) => <span>{row.original.contractId?.id}</span>,
    },
    {
      header: "Purchase ID",
      cell: ({ row }) => row.original.status === "Pending" ? "-" : <span>{row.original?.id}</span>,
    },
    {
      header: "Created At",
      cell: ({ row }) => DateTimeFormat(row.original?.createdAt),
    },
    {
      header: "Start Date",
      cell: ({ row }) => row.original.status === "Pending" ? "-" : DateTimeFormat(row.original.updatedAt),
    },
    {
      header: "End Date",
      cell: ({ row }) => row.original.status === "Pending" ? "-" : DateTimeFormat(row.original.endDate),
    },
    {
      header: "Validity",
      cell: ({ row }) => <span>{row.original.validity} Months </span>,
    },
    {
      header: "Returns",
      cell: ({ row }) => (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          {handleTotalInterset(row.original)} %
          {row.original.returns?.length > 0 && (
            activeContractId === row.original?.id ?
              <IoIosArrowUp style={{ marginLeft: "5px" }} onClick={() => handleContractDetails(row.original.id)} /> :
              <IoIosArrowDown style={{ marginLeft: "5px" }} onClick={() => handleContractDetails(row.original.id)} />
          )}
        </div>
      ),
    },
    {
      header: "Next STB Date",
      cell: ({ row }) => row.original.status === "Active" ? DateTimeFormat(row.original.nextStbDate?.month) : "-",
    },
    {
      header: "Status",
      cell: ({ row }) => (
        <div style={{
          color: row.original?.status === "Active" ? "#a3ec26" :
                 row.original?.status === "Pending" ? "orange" : "red",
        }}>
          {row.original?.status}
        </div>
      ),
    },
    {
      header: "Renew",
      cell: ({ row }) => {
        const endDateWithinMonth = isEndDateWithinMonth(row.original.endDate);
        return endDateWithinMonth && !row.original.isRenewed ? (
          <WebButton
            text="Renew"
            className="widthdraw_btn"
            type=""
            onClickEvent={() => renewContract.mutate(row.original.id)}
          />
        ) : "-";
      },
    },
  ];

  if (getActiveContract?.isLoading) {
    return <Loading />;
  }

  return (
    <div className="contracts_bg">
      <div className="contracts_header_row">
        <div className="active_contracts_head">YOUR CONTRACTS</div>
        <div>
          <WebButton
            color="#a3ec26"
            onClickEvent={handleAddContract}
            text="New Contract"
            className="green_gradient_button"
            type=""
          />
        </div>
      </div>

      <div className="contracts_content">
        <div className="data-table-container">
          <DataTable
            columns={contractColumns}
            data={activeContracts}
            subComponent={ContractDetail}
            activeId={activeContractId}
          />
        </div>
        <div className="refral-links-container  small-screen-only">
          <RefralLinks />
        </div>
      </div>
    </div>
  );
}

const ContractDetail = (row) => {
  return (
    <tr className="contract_details">
      <td colSpan={11}>
        <div className="contract_details_head">Quarter Listing</div>
        <div className="contract_details_header">
          <p>Month</p>
          <p>Interest</p>
          <p>Interest Amount</p>
          <p>Stb Date</p>
        </div>

        {row?.returns?.map((item, index) => (
          <div key={index} className="contract_details_row">
            <p>{index + 1}</p>
            <p>{item.percentage} % </p>
            <p className="web_table_green_td">
              {handleIntersetAmount(row?.amount, item?.percentage)}
            </p>
            <p>{DateTimeFormat(item.month)}</p>
          </div>
        ))}

        <div className="contract_details_footer">
          <p className="contract_details_grand_total">Grand Total</p>
          <p>{handleTotalInterset(row)} %</p>
          <p>{handleTotalAmount(row)}</p>
          <p></p>
        </div>
      </td>
    </tr>
  );
};