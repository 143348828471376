import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { HiMiniHandRaised } from "react-icons/hi2";
import { MdOutlineReply } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../../base/context/AppProviders";
import { DataTable } from "../../../components/common/table/Data-Table";
import WebButton from "../../../components/library/webButton";
import { getTickets } from "../../../redux/actions";
import { DateTimeFormat } from "./../../../helper/utils";
import "./style.css";
import RefralLinks from "../../../components/RefralLinks/index";

export default function MyTickets() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const app = useApp();

  const [table, setTable] = useState("open");

  const handleAddNew = () => {
    navigate("/support/createNewTicket");
  };

  const tickets = useSelector((state) => state.appReducers.tickets);

  const openTickets = tickets?.filter((item) => item.status === "Open");
  const closedTickets = tickets?.filter((item) => item.status !== "Open");

  useEffect(() => {
    const ticketPayload = {};
    app.showLoader(true, "wait for a minute");
    dispatch(getTickets(JSON.stringify(ticketPayload), sendSuccess, sendError));
  }, []);

  const sendSuccess = (res) => {
    app.showLoader(false, "wait over");
  };

  const sendError = (e) => {
    app.showLoader(false, "wait over");
  };

  const handleReply = (id) => {
    navigate(`/ticketReply/?id=${id}`);
  };

  const ticketColumns = [
    {
      header: "Sno",
      cell: ({ row }) => <span>{row.index + 1}</span>,
    },
    {
      header: "Ticket ID",
      cell: ({ row }) => <span>{row.original.id}</span>,
    },
    {
      header: "Category",
      cell: ({ row }) => <span>{row.original.issue.name}</span>,
    },
    {
      header: "Total Messages",
      cell: ({ row }) => (
        <span>
          {row.original.message.length
            ? row.original.message.length
            : "No messages"}
        </span>
      ),
    },
    {
      header: "Status",
      cell: ({ row }) => (
        <span
          style={{
            color: row.original.status === "Open" ? "#a3ec26" : "#a3ec26",
          }}
        >
          {row.original.status}
        </span>
      ),
    },
    {
      header: "Last Update",
      cell: ({ row }) => <span>{DateTimeFormat(row.original.updatedAT)}</span>,
    },
    {
      header: " ",
      cell: ({ row }) => (
        <WebButton
          onClickEvent={() => handleReply(row.original.id)}
          text={
            <p className="button_icon_text">
              {table === "open" ? (
                <MdOutlineReply size={22} />
              ) : (
                <FaEye size={22} />
              )}{" "}
              {table === "open" ? "Reply" : "View"}
            </p>
          }
          style={null}
          className="support_btn"
          type="button"
        />
      ),
    },
  ];

  return (
    <div>
      <div className="open_tickets">
        <div className="ticket_header_row">
          <div
            style={{
              display: "flex",
              gap: "10px",
              marginBottom: "1rem",
            }}
          >
            <button
              className="widthdraw_btn web_button"
              style={
                table === "open"
                  ? { backgroundColor: "#313135", color: "#A3EC26" }
                  : { backgroundColor: "#4A4A4F", color: "white" }
              }
              onClick={() => setTable("open")}
            >
              Open
            </button>
            <button
              style={
                table === "closed"
                  ? { backgroundColor: "#313135", color: "#A3EC26" }
                  : { backgroundColor: "#4A4A4F", color: "white" }
              }
              onClick={() => setTable("closed")}
              className="widthdraw_btn web_button"
            >
              Closed
            </button>
          </div>

          <WebButton
            onClickEvent={handleAddNew}
            text={
              <p className="button_icon_text">
                <HiMiniHandRaised size={22} /> Raise Ticket
              </p>
            }
            style={null}
            className="green_gradient_button"
            type="button"
          />
        </div>
        {table === "open" ? (
          <DataTable columns={ticketColumns} data={openTickets} />
        ) : (
          <DataTable columns={ticketColumns} data={closedTickets} />
        )}
      </div>

      <div className="refral-links-container  small-screen-only">
        <RefralLinks />
      </div>
    </div>
  );
}
