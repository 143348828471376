import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import AppReducers from "../reducers";
import { thunk } from "redux-thunk";

/**
 * @author Diksha Lalit
 * @since 12-01-2024
 * @description app redux store
 */

const store = createStore(
  combineReducers({ appReducers: AppReducers }),
  {},
  compose(applyMiddleware(thunk)) // for development only
);

export default store;
