import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { EarningApiService } from "../../service/earningApiService";

const useWithdraw = () => {
  const navigate = useNavigate();

  const withdrawMutation = useMutation({
    mutationFn: EarningApiService.withdraw,

    onSuccess: (data) => {
      console.log(data)
      navigate("/Convert");
    },
  });

  return { withdrawMutation };
};

export default useWithdraw;
