import React from "react";
import "./style.css";

export default function WebInput({
  label,
  type,
  placeholder,
  onChange,
  error,
  value,
  eye,
  handleType,
  onKeyUp,
  style,
  labelStyle,
  inputRef,
  onpaste,
  message,
  name,
  maxLength,
  onCopy,
  readOnly,
  max,
}) {
  return (
    <div className="input_parent_div">
      <label style={labelStyle} className="label_style">
        {label}
      </label>

      <div className="input_wrapper" style={style}>
        <input
          name={name}
          ref={inputRef}
          onCopy={onCopy}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          className={type === "file" ? "image_input" : "add_input"}
          onKeyUp={onKeyUp}
          onPaste={onpaste}
          maxLength={maxLength}
          readOnly={readOnly}
          autoComplete=" "
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          max={max}
        />
        <div
          className="input_eye_btn"
          onClick={handleType}
          style={{ display: eye ? "flex" : "none" }}
        >
          {eye}
        </div>
      </div>

      <p className="input_error">{error ? "*" + error : null}</p>
      <p className="input_message">{message ? "*" + message : null}</p>
    </div>
  );
}
