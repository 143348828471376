/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import "./style.css";
import Refrals from "../../components/RefralLinks/index";
import axios from "axios";
import { APIUrl } from "../../network/NetworkConfriguation";
import { getLocalStorage } from "../../storage/LocalStorage";
import moment from "moment/moment";

const index = () => {
  const [paymentHistory, setPaymentHistory] = useState();

  const requestHeaders = {};
  const token = getLocalStorage();
  const userToken = token.loginHeader;
  requestHeaders["Authorization"] = `Bearer ${userToken}`;

  useEffect(() => {
    handlePaymentHistory();
  }, []);

  const handlePaymentHistory = () => {
    axios
      .post(
        APIUrl + "/paymentHistory",
        {},
        {
          headers: requestHeaders,
        }
      )
      .then((res) => {
        console.log(res.data);
        setPaymentHistory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="payment-history-container">
      <div className="all_selects">
        <select
          style={{
            background: "#4A4A4F",
            color: "white",
            cursor: "pointer",
            width: "12vw",
            height: "5vh",
            borderRadius: "5px",
          }}
          name="Days"
          id="Days"
        >
          <option value="volvo">Select Days</option>
          <option value="volvo">Last 3 Days</option>
          <option value="saab">Last 7 Days</option>
          <option value="mercedes">Last 30 Days</option>
        </select>
        <select
          style={{
            background: "#4A4A4F",
            color: "white",
            cursor: "pointer",
            width: "15vw",
            height: "5vh",
            borderRadius: "5px",
          }}
          name="Transaction"
          id="Transaction"
        >
          <option value="volvo">All Transaction Type</option>
          <option value="volvo">Deposit</option>
          <option value="saab">Transfer</option>
          <option value="mercedes">Withdrawal</option>
          <option value="mercedes">Refund</option>
          <option value="mercedes">Reward</option>
          <option value="mercedes">Rebate</option>
        </select>
        <select
          style={{
            background: "#4A4A4F",
            color: "white",
            cursor: "pointer",
            width: "12vw",
            height: "5vh",
            borderRadius: "5px",
          }}
          name="Time"
          id="Time"
        >
          <option value="volvo">All Time</option>
          <option value="volvo">Processing</option>
          <option value="saab">Done</option>
          <option value="mercedes">Rejected</option>
        </select>
      </div>

      <div className="content-wrapper">
        <div className="table-container">
          <div className="web_table_box">
            <table className="web_table">
              <thead>
                <tr>
                  <td>S.No</td>
                  <td> ST Token</td>
                  <td>Amount</td>
                  <td>Payment Type</td>
                  <td>Payment Status</td>
                  <td>Created At</td>
                </tr>
              </thead>
              <tbody>
                {paymentHistory && paymentHistory?.length > 0 ? (
                  paymentHistory?.map((rowdata, index) => (
                    <tr key={index + 1}>
                      <td>{index + 1}</td>
                      <td>{rowdata.amount}</td>
                      <td>{rowdata.amounr}</td>
                      <td>{rowdata.mode}</td>
                      <td>{rowdata.paymentStatus}</td>
                      <td>{moment(rowdata.createdAt).format("DD-MM-YYYY , LT")}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6">No Data Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
        <div className="refrals-container">
          <Refrals />
        </div>
      </div>
    </div>
  );
};

export default index;