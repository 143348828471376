import { clearLocalStorage, getLocalStorage } from "../storage/LocalStorage";

/**
 * @author Diksha Lalit
 * @since 02-01-2024
 * @param url API request url.
 * @param method request method.
 * @param body request body.
 * @param contentType request contentType headers.
 * @description network request manager.
 */

export const callAPI = async (url, method, requestHeaders, body) => {
  return new Promise((resolve, reject) => {
    const token = getLocalStorage();
    const userToken = token.loginHeader;

    requestHeaders["Authorization"] = `Bearer ${userToken}`;

    fetch(url, {
      method: method,
      headers: requestHeaders,
      body: body,
    })
      .then((response) => {
        let statusCode = +response.status;

        console.log("statusCode", statusCode);
        console.log("NetworkConnection.js Raw Response -->", response);

        if (statusCode < 300) {
          // If success than execute other response.
          resolve(response.json());
        } else {
          if (statusCode === 401 || statusCode === 499) {
            clearLocalStorage();
            if (!url.includes("login")) {
              setTimeout(() => {
                window.location.href = "/";
              }, 3000);
            }
          }
          if (statusCode === 444) {
            window.location.href = "/dashboard";
          }
          reject(response.json());
        }
      })
      .catch((error) => {
        reject(error.message);
      });
  });
};
