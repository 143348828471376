import axios from "axios";
import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { APIUrl } from "../../network/NetworkConfriguation";

const ApexChart = () => {
  const [data, setData] = useState();
  const handleCandleChart = () => {
    axios
      .get(APIUrl + "/ohcl", {})

      .then((res) => {
        console.log(res.data);
        const transformedData = res.data?.data?.map((item) => ({
          x: new Date(item.intervalStart),
          y: [item.open, item.high, item.low, item.close],
        }));

        setData(transformedData);
      })

      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    handleCandleChart();
  }, []);
  const series = [
    {
      data: data
    },
  ];

  const options = {
    chart: {
      type: "candlestick",
      height: 350, // Set the height here
      width: 900, // Set the width here
    },
    title: {
      text: "Profile Chart",
      align: "left",
      style: {
        color: "#a3ec26",
        fontSize: "22px",
      },
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: {
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <div>
      <div id="chart" style={{ marginTop: "2rem" }}>
        <ReactApexChart
          options={options}
          series={series}
          type="candlestick"
          height={350} // Set the height here as well
          width={900} // Set the width here as well
        />
      </div>
      <div id="html-dist"></div>
    </div>
  );
};

export default ApexChart;
