import {
  ACTIVE_CONTRACTS,
  CONTRACTS_LIST,
  USER_PROFILE,
  DIRECT_INCOME,
  DIRECT_LIST,
  DOWNLINE_INCOME,
  DOWNLINE_LIST,
  KYC_STATUS,
  STB_INCOME,
  IS_AUTH,
  SUPPORT_CATEGORY,
  TICKETS,
  EARNINGS,
  WITHDRAWALS,
  REWARDS,
} from "../actions/types";

const Initial_state = {
  activeContracts: [],
  contracts: [],
  userProfile: [],
  directNetwork: [],
  downlineNetwork: [],
  stbIncome: [],
  directIncome: [],
  downlineIncome: [],
  kycStatus: "",
  isAuth: false,
  supportCategory: [],
  tickets: [],
  earnings: [],
  withdrawals: [],
  rewards: [],
};

const AppReducers = (state = Initial_state, action) => {
  switch (action.type) {
    case CONTRACTS_LIST: {
      return { ...state, contracts: action.payload };
    }
    case USER_PROFILE: {
      return { ...state, userProfile: action.payload };
    }
    case DIRECT_LIST: {
      return { ...state, directNetwork: action.payload };
    }
    case DOWNLINE_LIST: {
      return { ...state, downlineNetwork: action.payload };
    }
    case ACTIVE_CONTRACTS: {
      return { ...state, activeContracts: action.payload };
    }
    case STB_INCOME: {
      return { ...state, stbIncome: action.payload };
    }
    case DIRECT_INCOME: {
      return { ...state, directIncome: action.payload };
    }
    case DOWNLINE_INCOME: {
      return { ...state, downlineIncome: action.payload };
    }
    case KYC_STATUS: {
      return { ...state, kycStatus: action.payload };
    }
    case IS_AUTH: {
      return { ...state, isAuth: action.payload };
    }
    case SUPPORT_CATEGORY: {
      return { ...state, supportCategory: action.payload };
    }
    case TICKETS: {
      return { ...state, tickets: action.payload };
    }
    case EARNINGS: {
      return { ...state, earnings: action.payload };
    }
    case WITHDRAWALS: {
      return { ...state, withdrawals: action.payload };
    }
    case REWARDS: {
      return { ...state, rewards: action.payload };
    }
    default:
      return state;
  }
};

export default AppReducers;
