import { useQuery } from "@tanstack/react-query";
import { AuthApiService } from "../../service/authApiService";
import { NetworkApiService } from "../../service/networkApiService";

const useGetNetworks = () => {
  const directNetwork = useQuery({
    queryKey: ["directNetwork"],
    queryFn: NetworkApiService.fetchDirectNetwork,
  });

  const downlineNetworks = useQuery({
    queryKey: ["downlineNetworks"],
    queryFn: NetworkApiService.fetchDownlineNetwork,
  });

  return { directNetwork, downlineNetworks };
};

export default useGetNetworks;
