import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { ContractApiService } from "../../service/contractApiService";

const useGetAllContractsList = () => {
  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const getAllContracts = useQuery({
    queryKey: ["getAllContracts"],
    queryFn: ContractApiService.fetchContracts,
  });

  const purchaseNewContractMutation = useMutation({
    mutationFn: ContractApiService.purchaseNewContract,

    onSuccess: (data) => {
      queryClient.invalidateQueries(["getActiveContracts"]);
      navigate("/contracts");
      return data
    },
  });

  return { getAllContracts, purchaseNewContractMutation };
};

export default useGetAllContractsList;
