import React from "react";
import "./style.css";

export default function WebButton({
  onClickEvent,
  text,
  style,
  className,
  type,
  disabled,
}) {
  return (
    <button
      type={type}
      className={`web_button ${className}`}
      onClick={onClickEvent}
      style={style}
      disabled={disabled}
    >
      {text}
    </button>
  );
}
