import React, { forwardRef, useImperativeHandle, useState } from "react";
import Lottie from "lottie-react";
import LoaderGif from "../../../assests/gif/loader.json";
import "./style.css";

const Loader = forwardRef((props, ref) => {
  const [loaderPopup, setLoaderPopup] = useState();

  useImperativeHandle(ref, () => ({
    showLoader(loader, message) {
      setLoaderPopup(loader);
    },
  }));

  return (
    <div>
      {loaderPopup ? (
        <div className="loader_bg">
          <div className="loader_icon_bg">
            <Lottie
              animationData={LoaderGif}
              loop={true}
              className="loader_icon"
            />
          </div>
        </div>
      ) : null}
    </div>
  );
});

export default Loader;
