import React, { useEffect, useState } from "react";
import "./style.css";
import axios from "axios";
import { APIUrl } from "../../network/NetworkConfriguation";
import { getLocalStorage } from "../../storage/LocalStorage";
import RefralLinks from "../../components/RefralLinks/index";
import useGetUserProfile from "../../hooks/user/getUserProfile.hook";
import { toast } from "react-toastify";

const STToken = () => {
  const [addcoin, setAddCoin] = useState();
  const [coinPrice, setCoinPrice] = useState(0);
  const [amount, setAmount] = useState(0);
  const [data, setData] = useState(0);
  const [ifscCode, setIfscCode] = useState();
  const [formError, setFormError] = useState(null);
  const [bankAccount, setBankAccount] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const requestHeaders = {};
  const token = getLocalStorage();
  const userToken = token.loginHeader;
  requestHeaders["Authorization"] = `Bearer ${userToken}`;

  const userProfile = useGetUserProfile();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 550);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleBankAccount = (e) => {
    const input = e.target.value.replace(/\D/g, "");
    if (input.length <= 16) {
      setBankAccount(input);
    }
  };

  const handlegetCoin = () => {
    axios
      .get(APIUrl + "/coin", {
        headers: requestHeaders,
      })
      .then((res) => {
        setCoinPrice(res.data.data.manualRate);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCoin = () => {
    if (!amount || !bankAccount || !ifscCode || !name || !email || !number) {
      setFormError("Please fill all required fields.");
      toast.error("All fields are required");
      return;
    }
    axios
      .post(
        APIUrl + "/buy",
        {
          amount: Number(amount),
          bank_account_number: bankAccount,
          ifsc_code: ifscCode,
          name: name,
          email: email,
          number: number,
        },
        { headers: requestHeaders }
      )
      .then((res) => {
        console.log(res.data);
        toast.success("Coin Added Successfully");
        window.location.reload();
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.message === "please complete your kyc first") {
          toast.success("Complete Your KYC First");
          setTimeout(() => {
            window.location.href = "/kycDocumentation";
          }, 2500);
        }
      });
  };

  useEffect(() => {
    handlegetCoin();
  }, []);

  const handleChange = (e) => {
    setAmount(e.target.value);
    axios
      .post(APIUrl + "/amountToQuantity", {
        amount: e.target.value,
      })
      .then((res) => {
        console.log(res.data);
        setAddCoin(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className={`st-token-container ${isMobile ? 'mobile' : ''}`}>
      <div className="balance-history-container">
        <div  className="balance_history">
          <h4>
            YOUR AVAILABLE BALANCE{" "}
            <span>{userProfile?.data?.user?.coinQuantity}</span>
          </h4>
        </div>
      </div>

      <div className="main-content">
        <div className="form-container">
          <div className="add_coin">
            <label>AMOUNT</label>
            <input
              type="text"
              placeholder=""
              value={amount}
              onChange={handleChange}
            />
          </div>

          <div className="deposited_coin">
            <label>TO BE DEPOSITED</label>
            <input
              type="text"
              placeholder="10000-5400INR"
              value={data?.data}
              readOnly
            />
          </div>

          <div className="bottom_inputs">
            <div>
              <label>Name</label>
              <input
                onChange={(e) => setName(e.target.value)}
                value={name}
                type="text"
                placeholder=""
              />
            </div>

            <div>
              <label>Email ID</label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="text"
                placeholder=""
              />
            </div>

            <div>
              <label>Phone Number</label>
              <input
                value={number}
                onChange={(e) => setNumber(e.target.value)}
                type="number"
                maxLength={12}
                placeholder=""
              />
            </div>

            <div>
              <label>Bank Account Number</label>
              <input
                onChange={handleBankAccount}
                value={bankAccount}
                type="text"
                placeholder=""
              />
              {bankAccount?.length > 16 && (
                <p className="error-message">
                  Please enter a maximum of 16 digits.
                </p>
              )}
            </div>

            <div>
              <label>IFSC Code - Shift Code</label>
              <input
                onChange={(e) => setIfscCode(e.target.value)}
                value={ifscCode}
                type="text"
                placeholder=""
              />
              {ifscCode?.length > 11 && (
                <p className="error-message">
                  Please enter a maximum of 11 digits.
                </p>
              )}
            </div>
          </div>

          <div>
            <button onClick={handleCoin} type="button" className="submit_coin_btn">
              SUBMIT
            </button>
          </div>
        </div>

        <div className={`refral-links ${isMobile ? 'mobile' : ''}`}>
          <RefralLinks />
        </div>
      </div>
    </div>
  );
};

export default STToken;