import { useQuery } from "@tanstack/react-query";
import { ReportsApiService } from "../../service/reportsApiService";

const useGetDirectIncome = () => {
  const directIncome = useQuery({
    queryKey: ["directIncome"],
    queryFn: ReportsApiService.getDirectIncome,
  });

  return { directIncome };
};

export default useGetDirectIncome;
