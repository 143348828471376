import api from "../config/apiConfig";
import { APIUrl, NetworkConfiguration } from "../network/NetworkConfriguation";
import { callAPI } from "../network/NetworkConnection";

const kycRegistration = (kycPayload) => {
  return new Promise((resolve, reject) => {
    callAPI(
      APIUrl + NetworkConfiguration.KYC_REGISTRATION,
      "POST",
      { "contect-type": "multipart/form-data" },
      kycPayload
    )
      .then(async (res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

const reApplyKYC = async () => {
  const res = await api.post(NetworkConfiguration.RE_APPLY_KYC, {});
  return res;
};

export const KycApiService = {
  kycRegistration,
  reApplyKYC,
};
