import { useMutation, useQueryClient } from "@tanstack/react-query";
import { ContractApiService } from "../../service/contractApiService";

const useRenewContracts = () => {
  const queryClient = useQueryClient();

  const renewContract = useMutation({
    mutationFn: ContractApiService.renewContract,

    onError: (error) => {
      console.error("Error in renew contract", error);
    },

    onSuccess: () => {
      queryClient.invalidateQueries(["getActiveContracts"]);
    },
  });

  return renewContract;
};

export default useRenewContracts;
