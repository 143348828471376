import { Popover } from "@radix-ui/themes";
import React, { useState } from "react";
import { CgProfile } from "react-icons/cg";
import { FaUserLarge } from "react-icons/fa6";
import { GrRefresh } from "react-icons/gr";
import { IoIosArrowDown } from "react-icons/io";
import { IoMenuOutline } from "react-icons/io5";
import { MdLockOutline, MdOutlineNotificationsActive } from "react-icons/md";
import { PiSignOutBold } from "react-icons/pi";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import useGetUserProfile from "../../../hooks/user/getUserProfile.hook";
import useUpdateProfile from "../../../service/userServices";
import Loading from "../../library/loader/loader";
import WebButton from "../../library/webButton";
import "./style.css";
import useGetNotification from "../../../hooks/notification/getNotification.hook";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    borderRadius: "20px",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "0",
    overflow: "hidden",
    border: "none",
  },
};

export default function Navbar({ sidebar, handleSidebarCollapse }) {
  const [profileVisibility, setProfileVisibility] = useState(false);
  const { notification } = useGetNotification();

  const upateProfileMutation = useUpdateProfile();

  const [editProfileModal, setEditProfileModal] = useState(false);
  const [image, setImage] = useState(null);
  const [notificationCount, setNotificationCount] = useState(15);

  // const handleProfile = () => {
  //   setProfileVisibility(!profileVisibility);
  // };
  const navigate = useNavigate();

  const userProfile = useGetUserProfile();
  const user = userProfile?.data?.user;

  const handleRefresh = () => {
    window.location.reload();
  };

  if (
    userProfile.isLoading ||
    userProfile.isPending ||
    userProfile.isFetching
  ) {
    return <Loading />;
  }

  const handleProfile = () => {
    navigate("/myProfile");
    // setIsOpen(false);
  };

  const handleChangePassword = () => {
    navigate("/changePassword");
    // setIsOpen(false);
  };

  const handleNotifications = () => {
    navigate("/notifications");
    // setIsOpen(false);
  };

  const handleSignOut = () => {
    // setIsOpen(false);
    // clearLocalStorage();
    localStorage.clear();
    navigate("/");
    // message.showToast("You have successfully logged out!", ToastTypes.SUCCESS);
  };

  const handleAddImages = () => {
    const formData = new FormData();
    formData.append("image", image);
    upateProfileMutation.mutate(formData);
    setEditProfileModal(false);
  };

  return (
    <div>
      <div className="navbar_bg">
        <div className="navbar_left">
          {!sidebar ? (
            <div className="navbar_menu_icon" onClick={handleSidebarCollapse}>
              <IoMenuOutline />
            </div>
          ) : null}
          {/* <div className="navbar_trading_logo">Trading Logo</div> */}
        </div>
        <div className="navbar_right">
          {/* <WebButton
            onClickEvent={handleRefresh}
            text={
              <p className="button_icon_text">
                 "}
                {window.innerWidth > 576 ? "REFRESH" : null}
              </p>
            }
            style={null}
            className="refresh_btn"
            type="button"
          /> */}

          <button
            style={{
              padding: "5px",
              borderRadius: "6px",
            }}
            className="refresh_btn "
            onClick={handleRefresh}
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                fontSize: "13px",
                border: "5px",
                padding: "3px",
              }}
            >
              Refresh
              <GrRefresh size={15} />
            </span>
          </button>
          <div className="user_profile_icon">
            {user?.imageUrl ? (
              <img
                src={user?.imageUrl}
                alt="profile"
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <FaUserLarge
                size={window.innerWidth > 576 ? 25 : 20}
                color="white"
              />
            )}
          </div>
          <div className="navbar_user_name">
            {user?.firstName} {user?.lastName}
            <div>
              <Popover.Root>
                <Popover.Trigger>
                  <div>
                    <div>
                      <div
                        style={{
                          position: "absolute",
                          top: "26px",
                          right: "18px",
                        }}
                      >
                        <IoIosArrowDown />
                        {notification?.data &&
                        notification?.data?.data?.count > 0 ? (
                          <div
                            style={{
                              position: "absolute",
                              // fontSize: "4.5rem",
                              backgroundColor: "red",
                              // margin: "0",
                              top: "0",
                              right: "114px",
                              width: "5px",
                              height: "5px",
                              borderRadius: "50%",
                              color:"#262626"
                            }}
                          >
                            .
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </Popover.Trigger>
                <Popover.Content
                  style={{
                    padding: 0,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "15px",
                      borderRadius: "10px",
                      border: "3px solid #a3ec26",
                      padding: "10px",
                      backgroundColor: "#4A4A4F",
                      color: "white",
                    }}
                  >
                    <Popover.Close>
                      <p
                        onClick={() => {
                          setEditProfileModal(true);
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "10px",
                        }}
                      >
                        <CgProfile size={22} />
                        <span>Set Profile</span>
                      </p>
                    </Popover.Close>
                    <Popover.Close>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "10px",
                        }}
                        onClick={handleProfile}
                      >
                        <CgProfile size={22} />
                        <span>View Profile</span>
                      </p>
                    </Popover.Close>

                    <Popover.Close>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "10px",
                        }}
                        onClick={handleChangePassword}
                      >
                        <MdLockOutline size={25} />
                        Change Password
                      </p>
                    </Popover.Close>

                    <Popover.Close>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "10px",
                          position: "relative",
                        }}
                        onClick={handleNotifications}
                      >
                        <MdOutlineNotificationsActive size={25} />
                        Notifications
                        {notification?.data?.data?.count > 0 && (
                          <div
                            style={{ fontSize: "9px" }}
                            className="notification_count"
                          >
                            {notification?.data?.data?.count}
                          </div>
                        )}
                      </p>
                    </Popover.Close>

                    <Popover.Close>
                      <p
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          gap: "10px",
                        }}
                        onClick={handleSignOut}
                      >
                        <PiSignOutBold size={24} />
                        Sign Out
                      </p>
                    </Popover.Close>
                  </div>
                </Popover.Content>
              </Popover.Root>
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={editProfileModal}
        onRequestClose={() => setEditProfileModal(false)}
        style={{
          overlay: {},
          content: {
            backgroundColor: "#4A4A4F",
            width: "50%",
            margin: "auto",
            height: "max-content",
            border: "1px solid #ccc",
            borderRadius: "8px",
            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <div className="modal-content">
          <div
            className="modal-header"
            style={{
              borderBottom: "1px solid #ccc",
              padding: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h2 style={{ margin: 0, color: "white" }}>Set Profile </h2>
            <p
              onClick={() => setEditProfileModal(false)}
              style={{
                border: "none",
                cursor: "pointer",
                fontSize: "1.2rem",
                color: "white",
              }}
            >
              X
            </p>
          </div>

          <div className="modal-body" style={{ padding: "20px" }}>
            <input
              type="file"
              onChange={(e) => {
                setImage(e.target.files[0]);
              }}
            />
          </div>
          <div
            className="modal-footer"
            style={{
              padding: "10px",
              display: "flex",
              gap: "10px",
            }}
          >
            <WebButton
              onClickEvent={() => {
                setEditProfileModal(false);
              }}
              text="Cancel"
              style={{
                backgroundColor: "#f5f5f5",
                color: "black",
                border: "1px solid #ccc",
              }}
              className="cancel_btn"
              type="button"
            />
            <WebButton
              onClickEvent={handleAddImages}
              text="Save"
              style={{
                backgroundColor: "#a3ec26",
                color: "white",
                border: "none",
              }}
              className="save_btn"
              type="button"
            />
          </div>
        </div>
      </Modal>
    </div>
  );
}
