import { StorageConstants } from "../helper/storageConstants";

/**
 * @author Diksha Lalit
 * @since 12-01-2024
 * @description to set objects on localstorage
 */
export const setLocalStorage = (token) => {
  localStorage.setItem(StorageConstants.IS_USER_LOGGED_IN, true);
  localStorage.setItem(StorageConstants.LOGIN_HEADER, token);
};

/**
 * @author Diksha Lalit
 * @since 12-01-2024
 * @description to get objects on localstorage
 */
export const getLocalStorage = () => {
  const response = {};
  response.loginHeader = localStorage.getItem(StorageConstants.LOGIN_HEADER);
  return response;
};

/**
 * @author Diksha Lalit
 * @since 12-01-2024
 * @description to clear objects from localstorage
 */
export const clearLocalStorage = () => {
  localStorage.setItem(StorageConstants.IS_USER_LOGGED_IN, false);
  localStorage.clear(StorageConstants.LOGIN_HEADER);
};
