import React, { useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa6";
import "./style.css";
import { RejexConstants } from "../../../helper/stringConstants";

export default function OtpInputs({ handleVerify, setOtp, otp }) {
  const [otpVal, setOtpVal] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
  });

  const handleOtp = (e) => {
    setOtpVal({
      ...otpVal,
      [e.target.name]: e.target.value.replace(RejexConstants.numRejex, ""),
    });
  };

  //handle focus input
  const inputFocus = (e) => {
    if (e.key === "Delete" || e.key === "Backspace") {
      const next = e.target.tabIndex - 1;
      if (next > -1) {
        e.target.form.elements[next].focus();
      }
    } else if (e.key === "Enter" && e.target.tabIndex === 5) {
      handleVerifyOtp();
    } else {
      const next = e.target.tabIndex + 1;
      if (next < 6 && e.target.value) {
        e.target.form.elements[next].focus();
      }
    }
  };

  useEffect(() => {
    setOtp(
      otpVal.one +
        otpVal.two +
        otpVal.three +
        otpVal.four +
        otpVal.five +
        otpVal.six
    );
  }, [otpVal]);

  const handleVerifyOtp = (e) => {
    e.preventDefault();
    if (otp.length === 6) {
      handleVerify();
    }
  };

  return (
    <form className="otp_input_row" onSubmit={handleVerifyOtp}>
      <input
        type="text"
        maxLength="1"
        onChange={handleOtp}
        name="one"
        value={otpVal.one}
        tabIndex={0}
        onKeyUp={inputFocus}
      ></input>

      <input
        type="text"
        maxLength="1"
        onChange={handleOtp}
        name="two"
        value={otpVal.two}
        tabIndex={1}
        onKeyUp={inputFocus}
      ></input>

      <input
        type="text"
        maxLength="1"
        onChange={handleOtp}
        name="three"
        value={otpVal.three}
        tabIndex={2}
        onKeyUp={inputFocus}
      ></input>

      <input
        type="text"
        maxLength="1"
        onChange={handleOtp}
        name="four"
        value={otpVal.four}
        tabIndex={3}
        onKeyUp={inputFocus}
      ></input>

      <input
        type="text"
        maxLength="1"
        onChange={handleOtp}
        name="five"
        value={otpVal.five}
        tabIndex={4}
        onKeyUp={inputFocus}
      ></input>

      <input
        type="text"
        maxLength="1"
        onChange={handleOtp}
        name="six"
        value={otpVal.six}
        tabIndex={5}
        onKeyUp={inputFocus}
      ></input>
      <button type="submit" onClick={handleVerifyOtp}>
        <FaArrowRight />
      </button>
    </form>
  );
}
