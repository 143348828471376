import {
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getPaginationRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React from "react";

export function DataTable({ columns, data, subComponent, activeId }) {
  const memoizedColumns = React.useMemo(() => columns, [columns]);
  const memoizedData = React.useMemo(() => data, [data]);
  const [expanded, setExpanded] = React.useState({});

  const table = useReactTable({
    columns: memoizedColumns,
    data: data ? memoizedData : [],
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
    getSubRows: (row) => row.returns,
    getExpandedRowModel: getExpandedRowModel(),

    initialState: {
      pagination: {
        pageSize: 8,
      },
    },
  });

  return (
    <div
      style={{
        maxWidth: "1600px",
      }}
    >
      <div className="web_table_box ">
        <table className="web_table">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <td
                      style={{
                        color: "#ffffffr",
                        fontWeight: "700",
                      }}
                      key={header.id}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel()?.rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <>
                  <tr
                    style={{
                      padding: "0.5rem",
                      border: "1px solid #f2f2f2",
                      borderRadius: "10px",
                    }}
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <td key={cell.id}>
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>

                  {subComponent &&
                    row.original.id === activeId &&
                    subComponent(row.original)}
                </>
              ))
            ) : (
              <tr
              // style={{
              //   height: "40vh",
              // }}
              >
                <td
                  colSpan={columns?.length}
                  rowSpan={table.getState().pagination.pageSize}
                >
                  No results.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination-buttons-container">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          Showing Page {table.getState().pagination.pageIndex + 1} of{"  "}
          {table.getPageCount() === 0 ? 1 : table.getPageCount()}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "1rem",
          }}
        >
          <button
            className="pagination-button"
            onClick={() => table.previousPage()}
            disabled={!table.getCanPreviousPage()}
          >
            Previous
          </button>

          <div className="pagination-number">
            {table.getState().pagination.pageIndex + 1}
          </div>

          <button
            className="pagination-button"
            onClick={() => table.nextPage()}
            disabled={!table.getCanNextPage()}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
