import { DateTimeFormat } from "../../helper/utils";

export const columns = [
  {
    header: "S.No",
    cell: ({ row }) => <span>{row.index + 1}</span>,
  },

  {
    header: "Membership ID",
    accessorKey: "referralCode",
  },
  // {
  //   header: "Email",
  //   // accessorKey: "email",
  //   cell: ({ row }) => (
  //     <span className="success_status">{row.original.email}</span>
  //   ),
  // },

  {
    header: "Email",
    cell: ({ row }) => {
      const email = row.original.email;
      const [username, domain] = email.split("@");
      const maskedUsername = username.slice(0, 3) + "xxxxxx";
      return (
        <span className="success_status">{`${maskedUsername}@${domain}`}</span>
      );
    },
  },
  // {
  //   header: "Mobile Number",
  //   accessorKey: "phone",
  // },

  {
    header: "Mobile Number",
    accessorKey: "phone",
    cell: ({ row }) => {
      const phoneNumber = row.getValue("phone");
      const first2Digits = phoneNumber.slice(0, 2);
      const last2Digits = phoneNumber.slice(-2);
      const maskedMiddle = "xxxx";
      const maskedPhoneNumber = `${first2Digits}${maskedMiddle}${last2Digits}`;
      return <span className="success_status">{maskedPhoneNumber}</span>;
    },
  },
  {
    header: "Earning",
    // accessorKey: "earned",
    cell: ({ row }) => (
      <span>{row.original.earned ? row.original.earned : 0}</span>
    ),
  },

  {
    header: "Register Date",
    cell: ({ row }) => DateTimeFormat(row.original.createdAt),
  },
];
