import { useState } from "react";

const useIsVarified = () => {
  const [isVarified, setIsVarified] = useState(null);

  return {
    isVarified,
    setIsVarified,
  };
};

export default useIsVarified;
