/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DataTable } from "../../components/common/table/Data-Table";
import Loading from "../../components/library/loader/loader";
import { DateTimeFormat } from "../../helper/utils";
import useGetNetworks from "../../hooks/networks/getNetworks.hook";
import WebButton from "../../components/library/webButton";
import RefralLinks from "../../components/RefralLinks/index";


const Networks = () => {
  const navigate = useNavigate();
  const { directNetwork } = useGetNetworks();
  const directData = directNetwork.data?.data;
  const { downlineNetworks } = useGetNetworks();
  const [params] = useSearchParams();
  const [table, setTable] = useState(
    params.get("redirect") ? "downline" : "direct"
  );

  const downlineNetwork = downlineNetworks?.data?.data;

  const downlineColumns = [
    {
      header: "S.No",
      cell: ({ row }) => <span>{row.index + 1}</span>,
    },

    {
      header: "Membership ID",
      accessorKey: "referralCode",
    },

    {
      header: "Name",
      cell: ({ row }) => (
        <span>
          {row.original.firstName} {row.original.lastName}
        </span>
      ),
    },

    {
      header: "Email",
      // accessorKey: "email",
      cell: ({ row }) => (
        <span className="success_status">{row.original.email}</span>
      ),
    },

    {
      header: "Earning",
      cell: ({ row }) => (
        <span>{row.original.earned ? row.original.earned : 0}</span>
      ),
    },

    {
      header: "KYC Status",
      cell: ({ row }) => (
        <span>
          {row.original.KYCStatus === "Accepted" ? (
            <span className="success_status">Approved</span>
          ) : (
            <span className="pending_status">Pending</span>
          )}
        </span>
      ),
    },
    {
      header: "Generation",
      accessorKey: "generation",
    },

    {
      header: "Register Date",
      cell: ({ row }) => DateTimeFormat(row.original.createdAt),
    },
    {
      header: "Contract",
      cell: ({ row }) => (
        <WebButton
          text="Contract List"
          onClickEvent={() =>
            navigate("/DownlineContractlist", { state: row.original.id })
          }
          style={{ backgroundColor: "#a3ec26" }}
          className="package_button"
          type="text"
        />
      ),
    },
  ];

  const directColumns = [
    {
      header: "S.No",
      cell: ({ row }) => <span>{row.index + 1}</span>,
    },

    {
      header: "Membership ID",
      accessorKey: "referralCode",
    },

    {
      header: "Name",
      cell: ({ row }) => (
        <span>
          {row.original.firstName} {row.original.lastName}
        </span>
      ),
    },

    {
      header: "Email",
      // accessorKey: "email",
      cell: ({ row }) => (
        <span className="success_status">{row.original.email}</span>
      ),
    },
    {
      header: "Earning",
      cell: ({ row }) => (
        <span>{row.original.earned ? row.original.earned : 0}</span>
      ),
    },

    {
      header: "KYC Status",

      cell: ({ row }) => (
        <div
          className={
            row.original.KYCStatus === "Accepted"
              ? "success_status"
              : "pending_status"
          }
        >
          {row.original.KYCStatus}
        </div>
      ),
    },
    {
      header: "Generation",
      accessorKey: "generation",
    },

    {
      header: "Register Date",
      cell: ({ row }) => DateTimeFormat(row.original.createdAt),
    },

    {
      header: "Contract",
      cell: ({ row }) => (
        <WebButton
          text="Contract List"
          onClickEvent={() =>
            navigate("/NetworkContractList", { state: row.original.id })
          }
          style={{ backgroundColor: "#a3ec26" }}
          className="package_button"
          type="text"
        />
      ),
    },
  ];

  if (directNetwork.isLoading || downlineNetworks.isLoading) {
    return <Loading />;
  }

  if (downlineNetworks.isError || directNetwork.isError) {
    return <>Error occured! Please try again later</>;
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        {/* <p
          style={{
            color: table === "direct" ? "#00e38c" : "#000",
            fontWeight: table === "direct" ? "bold" : "normal",
            borderRight: "1px solid #000",
            paddingRight: "15px",
          }}
          onClick={() => setTable("direct")}
        >
          Direct
        </p>

        <p
          style={{
            color: table === "downline" ? "#00e38c" : "#000",
            fontWeight: table === "downline" ? "bold" : "normal",
          }}
          onClick={() => setTable("downline")}
        >
          Downline
        </p> */}

        <button
          className="widthdraw_btn web_button"
          style={
            table === "direct"
              ? {
                  backgroundColor: "#313135",
                  color: "#A3EC26",
                }
              : {
                  backgroundColor: "#4A4A4F",
                  color: "#fff",
                }
          }
          onClick={() => setTable("direct")}
        >
          Direct
        </button>

        <button
          className="widthdraw_btn web_button"
          style={
            table === "downline"
              ? {
                  backgroundColor: "#313135",
                  color: "#A3EC26",
                }
              : {
                  backgroundColor: "#4A4A4F",
                  color: "#fff",
                }
          }
          onClick={() => setTable("downline")}
        >
          Downline
        </button>
      </div>

      {table === "direct" && (
        <DataTable columns={directColumns} data={directData} />
      )}

      {table === "downline" && (
        <DataTable columns={downlineColumns} data={downlineNetwork} />
      )}

<div className="refral-links-container  small-screen-only">
          <RefralLinks />
        </div>
    </div>
  );
};

export default Networks;
