import { useMutation, useQueryClient } from "@tanstack/react-query";
import { AuthApiService } from "../../service/authApiService";

const useSetPassword = () => {
  const queryClient = useQueryClient();

  const setFirtTimePassword = useMutation({
    mutationFn: AuthApiService.firstTimePassword,

    onSuccess: (data) => {
      queryClient.invalidateQueries(["userProfile"]);
    },
  });

  return setFirtTimePassword;
};

export default useSetPassword;
