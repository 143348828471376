import { useQuery } from "@tanstack/react-query";
import { getGraphData } from "../../service/dashboardApiService";

const useGetGraphData = () => {
  const graphData = useQuery({
    queryKey: ["getGraphData"],
    queryFn: getGraphData,
  });

  return { graphData };
};

export default useGetGraphData;
