import { createContext, useContext, useRef } from "react";
import Loader from "../../components/library/loader";
export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const loaderRef = useRef();

  const showLoader = (loader, message) => {
    loaderRef.current.showLoader(loader, message);
  };

  return (
    <AppContext.Provider value={{ showLoader }}>
      {children}
      <Loader ref={loaderRef} />
    </AppContext.Provider>
  );
};

export const useApp = () => useContext(AppContext);

export default AppProvider;
