export const AppFonts = {
  fontFamilySerif: "Playfair Display",
  fontFamilySansSerif: "Quicksand",

  fontRegular: 400,
  fontMedium: 500,
  fontSemiBold: 600,
  fontBold: 700,
  fontExtraBold: 800,
  fontBlack: 900,

  fontSizeXXSmall: "0.9rem",
  fontSizeXSmall: "1rem",
  fontSizeSmall: "1.2rem",
  fontSizeRegular: "1.4rem",
  fontSizeMedium: "1.6rem",
  fontSizeLarge: "2rem",
  fontSizeXLarge: "2.3rem",
  fontSizeXXLarge: "2.5rem",
};
