export const CONTRACTS_LIST = "CONTRACTS_LIST";
export const USER_PROFILE = "USER_PROFILE";
export const DIRECT_LIST = "DIRECT_LIST";
export const DOWNLINE_LIST = "DOWNLINE_LIST";
export const ACTIVE_CONTRACTS = "ACTIVE_CONTRACTS";
export const STB_INCOME = "STB_INCOME";
export const DIRECT_INCOME = "DIRECT_INCOME";
export const DOWNLINE_INCOME = "DOWNLINE_INCOME";
export const KYC_STATUS = "KYC_STATUS";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const IS_AUTH = "IS_AUTH";
export const SUPPORT_CATEGORY = "SUPPORT_CATEGORY";
export const TICKETS = "TICKETS";
export const EARNINGS = "EARNINGS";
export const WITHDRAWALS = "WITHDRAWALS";
export const REWARDS = "REWARDS";
