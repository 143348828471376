import { DateTimeFormat } from "../../helper/utils";

export const columns = [
  {
    header: "S.No",
    cell: ({ row }) => row.index + 1,
  },

  {
    header: "Transaction ID",
    accessorKey: "id",
  },
  {
    header: "ST Token",
    accessorKey: "amount",
  },
  {
    header: "Type",
    accessorKey: "type",
  },
  {
    header: "Remark",
    accessorKey: "remark",
  },
  {
    header: "Mode",
    accessorKey: "mode",
  },
  {
    header: "Status",
    // accessorKey: "paymentStatus",
    cell: ({ row }) => {
      if (row.original.paymentStatus === "Pending") {
        return (
          <span className="web_table_red_td">{row.original.paymentStatus}</span>
        );
      } else if (row.original.paymentStatus === "Success" ) {
        return (
          <span className="web_table_green_td">
            {row.original.paymentStatus}
          </span>
        );
      } else {
        return (
          <span className="web_table_yellow_td">
            {row.original.paymentStatus}
          </span>
        );
      }
    },
  },
  {
    header: "Transaction Timestamp",
    // accessorKey: "createdAt",
    cell: ({ row }) => DateTimeFormat(row.original.createdAt),
  },
];
