import { useQuery } from "@tanstack/react-query";
import { ReportsApiService } from "../../service/reportsApiService";

const useGetSTBincome = () => {
  const stbIncome = useQuery({
    queryKey: ["stbIncome"],
    queryFn: ReportsApiService.getStbIncome,
  });

  return { stbIncome };
};

export default useGetSTBincome;
