// import { APIUrl, NetworkConfiguration } from "../network/NetworkConfriguation";
// import { callAPI } from "../network/NetworkConnection";

import api from "../config/apiConfig";
import { NetworkConfiguration } from "../network/NetworkConfriguation";

// const login = (loginPayload) => {
//   return new Promise((resolve, reject) => {
//     callAPI(
//       APIUrl + NetworkConfiguration.LOGIN,
//       "POST",
//       {
//         "content-type": "application/json",
//       },

//       loginPayload
//     )
//       .then(async (res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

// export const LoginApiService = {
//   login,
// };

const login = async (loginPayload) => {
  const res = await api.post(NetworkConfiguration.LOGIN, loginPayload);
  return res.data;
};

export { login };
