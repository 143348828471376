import api from "../config/apiConfig";
import { NetworkConfiguration } from "../network/NetworkConfriguation";

const fetchEarnings = async () => {
  const res = await api.get(NetworkConfiguration.GET_EARNING_LIST);
  return res.data.data;
};

const withdraw = async (withdrawPayload) => {
  try {
    await api.post(NetworkConfiguration.WITHDRAW_BALANCE, withdrawPayload);
  } catch (err) {
    console.log(err);
    // toast.error("Something went wrong");
  }
};

/**
 * @author Diksha Lalit.
 * @since 23-01-2024.
 * @description  to withdraw earnings .
 */
// const getWithdrawals = (withdrawPayload) => {
//   return new Promise((resolve, reject) => {
//     callAPI(
//       APIUrl + NetworkConfiguration.WITHDRAWALS_LIST,
//       "POST",
//       {
//         "content-type": "application/json",
//       },
//       withdrawPayload
//     )
//       .then(async (res) => {
//         resolve(res);
//       })
//       .catch((err) => {
//         reject(err);
//       });
//   });
// };

const getWithdrawals = async (withdrawPayload) => {
  const res = await api.post(
    NetworkConfiguration.WITHDRAWALS_LIST,
    withdrawPayload
  );
  return res.data.data;
};

export const EarningApiService = {
  fetchEarnings,
  withdraw,
  getWithdrawals,
};
