import { useQuery } from "@tanstack/react-query";
import { notificationApiService } from "../../service/notificationApiService";

const useGetNotification = () => {
  const notification = useQuery({
    queryKey: ["getNotifications"],
    queryFn: notificationApiService.getNotifications,
  });

  return { notification };
};

export default useGetNotification;
