import Lottie from "lottie-react";
import LoaderGif from "../../../assests/gif/loader.json";
import "./style.css";

const Loading = () => {
  return (
    <div className="loader_bg">
      <div className="loader_icon_bg">
        <Lottie animationData={LoaderGif} loop={true} className="loader_icon" />
      </div>
    </div>
  );
};

export default Loading;
