import React, { useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import WebButton from "../../components/library/webButton";
import WebInput from "../../components/library/webInput";
import { findtoken } from "../../firebase";
import useLoginHook from "../../hooks/login.hook";
import "./style.css";

export default function Login() {
  useEffect(() => {
    localStorage.removeItem("TRADING_USER_TOKEN");
    findtoken();
  }, []);

  const [newPasswordInput, setNewPasswordInput] = useState(true);

  const [userData, setUserData] = useState({
    referralCode: "",
    password: "",
  });

  const [error, setError] = useState({
    referralCode: "",
    password: "",
  });

  const navigate = useNavigate();
  const { loginInMutation } = useLoginHook();

  const handleNewPasswordType = () => {
    setNewPasswordInput(!newPasswordInput);
  };

  const handleNewPasswordEye = () => {
    if (newPasswordInput) {
      return <FaEye style={{ color: "white" }} />;
    } else {
      return <FaEyeSlash style={{ color: "white" }} />;
    }
  };

  const handleUserData = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
    setError({ ...error, [e.target.name]: "" });
  };

  const validate = () => {
    let result = true;
    if (!userData.referralCode) {
      setError({ ...error, referralCode: "Please Enter Membership Id" });
      result = false;
    } else if (!userData.password) {
      setError({ ...error, password: "Please Enter Password" });
      result = false;
    }
    return result;
  };

  const handleLogin = (e) => {
    e.preventDefault();
    if (validate()) {
      const data = {
        referralCode: userData.referralCode,
        password: userData.password,
        device_token: localStorage.getItem("fcmToken"),
      };

      loginInMutation.mutate(data);
    }
  };

  const handleForget = () => {
    navigate("/forgetPassword");
  };

  return (
    <div className="login_bg">
      <div className="loginx_box">
        <form className="login_form_bg" onSubmit={handleLogin}>
          <div className="login_signup_heading">Sign In</div>
          <div className="login_inputs">
            <WebInput
              label=""
              type="text"
              placeholder="Enter Your Membership Id"
              onChange={handleUserData}
              error={error.referralCode}
              value={userData.referralCode}
              eye={null}
              handleType={null}
              onKeyUp={null}
              style={{
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderRadius: 0,
              }}
              labelStyle={null}
              inputRef={null}
              onpaste={null}
              message={null}
              name="referralCode"
              maxLength="20"
            />

            <WebInput
              label=""
              type={newPasswordInput ? "password" : "text"}
              placeholder="Enter Your Password"
              onChange={handleUserData}
              error={error.password}
              value={userData.password}
              style={{
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none",
                borderRadius: 0,
              }}
              name="password"
              maxLength="16"
              handleType={handleNewPasswordType}
              eye={handleNewPasswordEye()}
            />
          </div>
          <p className="forget_password_line">
            <span onClick={handleForget}>Forgot Password?</span>
          </p>

          <WebButton
            onClickEvent={null}
            text="CONTINUE"
            style={null}
            className="submit_btn"
            type="submit"
          />

          <p className="login_signUp_bottom_msg">
            New Member?{" "}
            <span onClick={() => navigate("/registration")}>Sign Up</span>
          </p>
        </form>
      </div>
    </div>
  );
}
