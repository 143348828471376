import React, { useEffect, useState } from "react";
import { MdVerifiedUser } from "react-icons/md";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AppColor } from "../../../assests/colors/colors";
import { AppFonts } from "../../../assests/fonts/fonts";
import { useApp } from "../../../base/context/AppProviders";
import WebButton from "../../../components/library/webButton";
import WebInput from "../../../components/library/webInput";
import WebVerifyOtp from "../../../components/library/webVerifyOtp";
import { RejexConstants } from "../../../helper/stringConstants";
import useGetUserProfile from "../../../hooks/user/getUserProfile.hook";
import { getUserProfile } from "../../../redux/actions";
import { AuthApiService } from "../../../service/authApiService";
import "./style.css";

export default function EditProfile() {
  const app = useApp();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [emailOtp, setEmailOtp] = useState("");
  const [emailOtpPopup, setEmailOtpPopup] = useState(false);
  const [oldEmailOtpPopup, setOldEmailOtpPopup] = useState(false);

  const [emailResId, setEmailResId] = useState("");

  const [phoneOtp, setPhoneOtp] = useState("");
  const [phoneOtpPopup, setPhoneOtpPopup] = useState(false);
  const [oldPhoneOtpPopup, setOldPhoneOtpPopup] = useState(false);

  const [verified, setVerified] = useState({
    oldEmail: false,
    email: false,
    oldPhone: false,
    phone: false,
  });

  const [data, setData] = useState({
    oldEmail: "",
    oldPhone: "",
    email: "",
    phone: "",
  });

  const [error, setError] = useState({
    oldEmail: "",
    oldPhone: "",
    email: "",
    phone: "",
  });

  // const userProfile = useSelector((state) => state.appReducers.userProfile);
  const userProfile = useGetUserProfile();
  const user = userProfile?.data?.user;

  useEffect(() => {
    setData({
      ...data,
      oldEmail: user?.email,
      oldPhone: user?.phone,
    });
  }, [user]);

  /*
  set new phone number
  */
  const handlePhone = (e) => {
    if (verified.oldPhone && !verified.phone) {
      setData({
        ...data,
        phone: e.target.value.replace(RejexConstants.numRejex, ""),
      });
      setError({ ...error, phone: "", oldPhone: "" });
    } else {
      if (!verified.oldPhone) {
        setError({ ...error, oldPhone: "First Verify Mobile Number" });
      }
    }
  };

  /*
  set new Email Address
  */
  const handleEmail = (e) => {
    if (verified.oldEmail) {
      setData({
        ...data,
        email: e.target.value,
      });
      setError({ ...error, email: "", oldEmail: "" });
    } else {
      setError({ ...error, oldEmail: "First Verify Email Address" });
    }
  };

  /*
  send otp to email function
  */
  const sendOtpToEmail = (obj) => {
    setError({ ...error, email: "", oldEmail: "" });

    app.showLoader(true, "wait for a minute");

    const emailPayload = {
      email: obj.update ? data.oldEmail : data.email,
      update: obj.update,
      id: user?.id,
    };

    AuthApiService.sendOtpToEmail(JSON.stringify(emailPayload))
      .then((res) => {
        setEmailResId(res.data);

        if (obj.update) {
          setOldEmailOtpPopup(true);
        } else {
          setEmailOtpPopup(true);
        }

        app.showLoader(false, "wait over");
        toast.success(res.message);
      })
      .catch((e) => {
        sendError(e);
      });
  };

  /*
  verify email function
  */
  const handleVerifyEmailOtp = (obj) => {
    app.showLoader(true, "wait for a minute");

    const otpPayload = {
      id: emailResId,
      phone: obj.update ? data.email : data.oldEmail,
      otp: emailOtp,
      userId: user?.id,
    };

    AuthApiService.verifyEmailOtp(JSON.stringify(otpPayload))
      .then((res) => {
        app.showLoader(false, "wait over");
        toast.success(res.message);
        setEmailOtpPopup(false);
        setOldEmailOtpPopup(false);
        if (obj.update) {
          setVerified({ ...verified, email: true });
        } else {
          setVerified({ ...verified, oldEmail: true });
        }
      })
      .catch((e) => {
        sendError(e);
      });
  };

  /*
  send otp to phone function
  */
  const sendOtpToPhone = (obj) => {
    setError({ ...error, phone: "", oldPhone: "" });

    app.showLoader(true, "wait for a minute");

    const phonePayload = {
      phone: obj.update ? data.oldPhone : data.phone,
      update: obj.update,
      id: user?.id,
    };

    AuthApiService.sendOtpToPhone(JSON.stringify(phonePayload))
      .then((res) => {
        if (obj.update) {
          setOldPhoneOtpPopup(true);
        } else {
          setPhoneOtpPopup(true);
        }

        app.showLoader(false, "wait over");
        toast.success(res.message);
      })
      .catch((e) => {
        sendError(e);
      });
  };

  /*
  verify phone function
  */
  const handleVerifyPhoneOtp = (obj) => {
    app.showLoader(true, "wait for a minute");

    const otpPayload = {
      phone: obj.update ? data.phone : data.oldPhone,
      otp: phoneOtp,
      id: user?.id,
    };

    AuthApiService.verifyPhoneOtp(JSON.stringify(otpPayload))
      .then((res) => {
        if (obj.update) {
          setVerified({ ...verified, phone: true });
        } else {
          setVerified({ ...verified, oldPhone: true });
        }
        setPhoneOtpPopup(false);
        setOldPhoneOtpPopup(false);
        app.showLoader(false, "wait over");
        toast.success(res.message);
      })
      .catch((e) => {
        sendError(e);
      });
  };

  /*
  validate function
  */
  const validate = () => {
    let result = true;
    if (!data.email) {
      if (!data.phone) {
        setError({ ...error, email: "First Enter Email Address" });
        result = false;
      }
    } else if (!data.phone) {
      if (!data.email) {
        setError({ ...error, phone: "First Enter Mobile Number" });
        result = false;
      }
    }

    return result;
  };

  const handleUpdateProfile = (e) => {
    e.preventDefault();
    if (validate()) {
      app.showLoader(true, "wait for a minute");

      const payload = {
        email: data.email ? data.email : data.oldEmail,
        phone: data.phone ? data.phone : data.oldPhone,
      };
      AuthApiService.updateProfile(JSON.stringify(payload))
        .then((res) => {
          dispatch(getUserProfile());
          navigate("/myProfile");
          app.showLoader(false, "wait over");
          toast.success(res.message);
        })
        .catch((e) => {
          sendError(e);
        });
    }
  };

  const sendError = (e) => {
    app.showLoader(false, "wait over");
  };

  return (
    <div className="update_profile_box">
      <form className="update_profile_form" onSubmit={handleUpdateProfile}>
        {/* Old Email Input */}
        <WebInput
          label="Email Address"
          type="text"
          placeholder="Enter Your Email Address"
          error={error.oldEmail}
          value={data.oldEmail}
          maxLength="70"
          eye={
            !verified.oldEmail &&
            data?.oldEmail &&
            RejexConstants.emailRegex.test(data?.oldEmail) ? (
              <WebButton
                onClickEvent={() => sendOtpToEmail({ update: true })}
                text="Verify"
                style={{ zIndex: "111" }}
                className="package_button"
                type="button"
              ></WebButton>
            ) : verified.oldEmail ? (
              <MdVerifiedUser color={AppColor.primaryColor} size={25} />
            ) : null
          }
          labelStyle={{ fontWeight: AppFonts.fontMedium }}
        />

        {/* Email Input */}
        <WebInput
          label="Email Address"
          type="text"
          placeholder="Enter Your Email Address"
          onChange={handleEmail}
          error={error.email}
          value={data.email}
          maxLength="70"
          eye={
            !verified.email &&
            data?.email &&
            RejexConstants.emailRegex.test(data?.email) ? (
              <WebButton
                onClickEvent={() => sendOtpToEmail({ update: false })}
                text="Verify"
                style={{ zIndex: "111" }}
                className="package_button"
                type="button"
              ></WebButton>
            ) : verified.email ? (
              <MdVerifiedUser color={AppColor.primaryColor} size={25} />
            ) : null
          }
          labelStyle={{ fontWeight: AppFonts.fontMedium }}
          readOnly={verified.email}
        />

        {/* Old Mobile Number Input */}
        <WebInput
          label="Mobile Number"
          type="text"
          placeholder="Enter Your Mobile Number"
          error={error.oldPhone}
          value={data.oldPhone}
          eye={
            !verified.oldPhone &&
            data?.oldPhone &&
            data.oldPhone.length === 10 ? (
              <WebButton
                onClickEvent={() => sendOtpToPhone({ update: true })}
                text="Verify"
                style={{ zIndex: "111" }}
                className="package_button"
                type="button"
              ></WebButton>
            ) : verified.oldPhone ? (
              <MdVerifiedUser color={AppColor.primaryColor} size={25} />
            ) : null
          }
          labelStyle={{ fontWeight: AppFonts.fontMedium }}
        />

        {/* Mobile Number Input */}
        <WebInput
          label="Mobile Number"
          type="text"
          placeholder="Enter Your Mobile Number"
          onChange={handlePhone}
          error={error.phone}
          value={data.phone}
          maxLength="10"
          eye={
            !verified.phone && data?.phone && data.phone.length === 10 ? (
              <WebButton
                onClickEvent={() => sendOtpToPhone({ update: false })}
                text="Verify"
                style={{ zIndex: "111" }}
                className="package_button"
                type="button"
              ></WebButton>
            ) : verified.phone ? (
              <MdVerifiedUser color={AppColor.primaryColor} size={25} />
            ) : null
          }
          labelStyle={{ fontWeight: AppFonts.fontMedium }}
          readOnly={verified.phone}
        />

        <WebButton
          onClickEvent={handleUpdateProfile}
          text="Update Profile"
          style={{ margin: "20px 0" }}
          className="submit_btn"
          type="submit"
        />
      </form>

      {emailOtpPopup ? (
        <WebVerifyOtp
          message={`Verification code sent to ${data.email} `}
          setOtpValue={setEmailOtp}
          verifyOtp={() => handleVerifyEmailOtp({ update: true })}
          resendOtp={() => sendOtpToEmail({ update: false })}
          close={() => setEmailOtpPopup(false)}
        />
      ) : null}

      {phoneOtpPopup ? (
        <WebVerifyOtp
          message={`Verification code sent to ${data.phone} `}
          setOtpValue={setPhoneOtp}
          verifyOtp={() => handleVerifyPhoneOtp({ update: true })}
          resendOtp={() => sendOtpToPhone({ update: false })}
          close={() => setPhoneOtpPopup(false)}
        />
      ) : null}

      {oldEmailOtpPopup ? (
        <WebVerifyOtp
          message={`Verification code sent to ${data.oldEmail}`}
          setOtpValue={setEmailOtp}
          verifyOtp={() => handleVerifyEmailOtp({ update: false })}
          resendOtp={() => sendOtpToEmail({ update: true })}
          close={() => setOldEmailOtpPopup(false)}
        />
      ) : null}

      {oldPhoneOtpPopup ? (
        <WebVerifyOtp
          message={`Verification code sent to ${data.oldPhone}`}
          setOtpValue={setPhoneOtp}
          verifyOtp={() => handleVerifyPhoneOtp({ update: false })}
          resendOtp={() => sendOtpToPhone({ update: true })}
          close={() => setOldPhoneOtpPopup(false)}
        />
      ) : null}
    </div>
  );
}
