import { useQuery } from "@tanstack/react-query";
import { EarningApiService } from "../../service/earningApiService";

const useEarningWallet = () => {
  const earningWallet = useQuery({
    queryKey: ["earningWallet"],
    queryFn: EarningApiService.fetchEarnings,
  });

  return earningWallet;
};

export default useEarningWallet;
