import api from "../config/apiConfig";
import { NetworkConfiguration } from "../network/NetworkConfriguation";

const getNotifications = async () => {
  const res = await api.get(NetworkConfiguration.NOTIFICATION);
  return res.data;
};

export const notificationApiService = {
  getNotifications,
};
