/* eslint-disable react-hooks/rules-of-hooks */
import { useEffect, useState } from "react";
import "./style.css";
import RefralLinks from "../../components/RefralLinks/index";
import axios from "axios";
import { APIUrl } from "../../network/NetworkConfriguation";
import { getLocalStorage } from "../../storage/LocalStorage";
import { toast } from "react-toastify";
import useGetUserProfile from "../../hooks/user/getUserProfile.hook";
import { useLocation } from "react-router-dom";

const Index = () => {
  const [quantity, setQuantity] = useState(0);
  const [addcoin, setAddCoin] = useState();
  const [data, setData] = useState(0);
  const [bankAccount, setBankAccount] = useState();
  const [ifscCode, setIfscCode] = useState();
  const [formError, setFormError] = useState(null);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [number, setNumber] = useState();
  const { state } = useLocation();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Call it initially

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleBankAccount = (e) => {
    const input = e.target.value.replace(/\D/g, "");
    if (input.length <= 16) {
      setBankAccount(input);
    }
  };

  const requestHeaders = {};
  const token = getLocalStorage();
  const userToken = token.loginHeader;
  requestHeaders["Authorization"] = `Bearer ${userToken}`;

  const userProfile = useGetUserProfile();

  const handleConvert = (e) => {
    setQuantity(e.target.value);
    axios
      .post(APIUrl + "/quantityToAmount", {
        quantity: e.target.value,
      })
      .then((res) => {
        setAddCoin(res.data);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCoin = () => {
    if (!bankAccount || !ifscCode) {
      setFormError("Please fill all required fields.");
      toast.error("All fields Are Required");
      return;
    }
    axios
      .post(
        APIUrl + "/sell",
        {
          quantity: Number(quantity || state),
          bank_account_number: bankAccount,
          ifsc_code: ifscCode,
          name: name,
          email: email,
          number: number,
        },
        { headers: requestHeaders }
      )
      .then((res) => {
        toast.success("Coin Converted To Amount");
        window.location.reload();
      })
      .catch((err) => {
        if (err.response.data.message === "you dont have this much coin") {
          toast.error(err.response.data.message);
        } else {
          toast.error(err.response.data.message);
        }
      });
  };

  const handleifsccode = (e) => {
    setIfscCode(e.target.value);
  };

  const handleName = (e) => {
    setName(e.target.value);
  };

  const handleemail = (e) => {
    setEmail(e.target.value);
  };

  const handlenumber = (e) => {
    setNumber(e.target.value);
  };

  useEffect(() => {
    if (state) {
      const handleConvert1 = () => {
        axios
          .post(APIUrl + "/quantityToAmount", {
            quantity: state,
          })
          .then((res) => {
            setAddCoin(res.data);
            setData(res.data);
          })
          .catch((err) => {
            console.log(err);
          });
      };
      handleConvert1();
    }
  }, []);

  return (
    <div className={`st-token-container ${isMobile ? "mobile" : ""}`}>
      <div className="balance-history-container">
        <div className="balance_history">
          <h4>
            YOUR AVAILABLE BALANCE{" "}
            <span>{userProfile?.data?.user?.coinQuantity}</span>
          </h4>
        </div>
      </div>

      <div className="content-wrapper">
        <div className="form-container">
          <div className="input_convert">
            <label>Quantity</label>
            <input
              readOnly={state ? true : false}
              onChange={handleConvert}
              value={quantity || state}
              type="number"
              placeholder=""
            />
          </div>

          <div className="input_convert_AMOUNT">
            <label>Amount to be withdrawal</label>
            <input value={data?.data} type="number" placeholder="" readOnly />
          </div>

          <div className="bottom_inputs">
            <div>
              <label>Name</label>
              <input
                type="text"
                onChange={handleName}
                value={name}
                placeholder=""
              />
            </div>

            <div>
              <label>Email ID</label>
              <input
                onChange={handleemail}
                value={email}
                type="text"
                placeholder=""
              />
            </div>

            <div>
              <label>Phone Number</label>
              <input
                onChange={handlenumber}
                value={number}
                type="text"
                placeholder=""
              />
            </div>

            <div>
              <label>Bank Account Number</label>
              <input
                onChange={handleBankAccount}
                value={bankAccount}
                type="number"
                placeholder=""
              />
              {bankAccount?.length > 16 && (
                <p className="error-message">
                  Please enter a maximum of 16 digits.
                </p>
              )}
            </div>

            <div>
              <label>IFSC Code-Shift Code</label>
              <input
                onChange={handleifsccode}
                value={ifscCode}
                type="text"
                placeholder=""
              />
              {ifscCode?.length > 11 && (
                <p className="error-message">
                  Please enter a maximum of 11 digits.
                </p>
              )}
            </div>
          </div>

          <div
           className="responsive_withdraw"
          >
            <button
              onClick={handleCoin}
              type="button"
              className="withdrawl_btnn"
            >
              WITHDRAWAL
            </button>
          </div>
        </div>

        <div className="RefralLinks">
          <RefralLinks />
        </div>
      </div>
    </div>
  );
};

export default Index;
