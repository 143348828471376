import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { APIUrl } from "../network/NetworkConfriguation";
/**
 * @author Pratham
 * @since 15-2-2024
 * @description axios interceptors
 */

const handleUnauthorizedError = () => {
  localStorage.clear();
  toast.error("Session expired. Please login again.");
  window.location.href = "/";
};

const handleNetworkError = (error) => {
  console.error("Network error:", error);
  toast.error("Network error. Please check your internet connection.");
};

const api = axios.create({
  baseURL: APIUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("TRADING_USER_TOKEN");
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    const successMessage = response.data.message;

    if (
      successMessage &&
      ["post", "delete", "put"].includes(response.config.method.toLowerCase())
    ) {
      toast.success(successMessage);
    }

    return response;
  },
  (error) => {
    if (error.response) {
      if (error.response.status === 401 || error.response.status === 403) {
        handleUnauthorizedError();
        return Promise.reject(new Error("Unauthorized access"));
        // } else if (error.response.status === 400) {
        //   return;
      } else if (error.response.status === 444) {
        console.log("hellon from");
        return error.response;
      } else if (error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        // console
        console.log("errorMessage", error);
        toast.error(errorMessage);

        return Promise.reject(new Error(errorMessage));
      } else {
        console.error("HTTP error:", error.response.status);
        return;
      }
    } else if (error.request) {
      handleNetworkError(error);
    } else {
      console.error("Request error:", error.message);
    }

    return;
  }
);

export default api;
