import React from "react";
import { DataTable } from "../../components/common/table/Data-Table";
import Loading from "../../components/library/loader/loader";
import { DateTimeFormat } from "../../helper/utils";
import useGetAllWithdraw from "../../hooks/earning/getAllWithdraws.hook";
import useEarningWallet from "../../hooks/user/getEarningWallet.hook";
import useGetUserProfile from "../../hooks/user/getUserProfile.hook";
import "./style.css";
import RefralLinks from "../../components/RefralLinks/index";
import axios from "axios";
import { APIUrl } from "../../network/NetworkConfriguation";
import { getLocalStorage } from "../../storage/LocalStorage";
import { toast } from "react-toastify";

import withdrawlableicon from "../../assests/images/withdraw.png"
import Escrowicon from "../../assests/images/escrow balance.png"
import finalbalanceicon from "../../assests/images/final balance.png"




export default function Withdrawals() {
  const { getAllWithdrawalList } = useGetAllWithdraw();
  const earningWallet = useEarningWallet();
  const userProfile = useGetUserProfile();
  const token = getLocalStorage();
  const userToken = token.loginHeader;
  const requestHeaders = {};
  requestHeaders["Authorization"] = `Bearer ${userToken}`;
  if (getAllWithdrawalList.isLoading) {
    return <Loading />;
  }

  const handlereddemapi = (id) => {
    axios
      .post(
        APIUrl + "/redeem",
        {
          id,
        },
        { headers: requestHeaders }
      )

      .then((res) => {
        console.log(res.data);
        toast.success("Redeem Successfully")
      })

      .catch((err) => {
        console.log(err);
      });
  };

  const columns = [
    {
      header: "S.No",
      cell: ({ row }) => {
        return row.index + 1;
      },
    },
    {
      header: "Requested ST Token",
      accessorKey: "amount",
    },
    {
      header: "Value to Withdraw",
      accessorKey: "amountToWithdraw",
    },
    // {
    //   header: "Withdrawal Tax",
    //   accessorKey: "withdrawalTax",
    // },
    {
      header: "Left Balance",
      // accessorKey: "withdrawalTax",
      cell: ({ row }) =>
        row.original.leftBalance ? row.original.leftBalance : "-",
    },
    {
      header: "Status",
      // accessorKey: "status",
      cell: ({ row }) => {
        return (
          <div>
            <span
              className={
                row?.original.status === "Accepted"
                  ? "success_status "
                  : row?.original.status === "Pending"
                  ? "pending_status"
                  : "rejected_status"
              }
            >
              {row?.original.status}
            </span>
          </div>
        );
      },
    },

    {
      header: "Redeem",
      cell: ({ row }) => {
        return (
          <sapn>
            <button
              onClick={() => handlereddemapi(row.original.id)}
              type="btn"
              className="butn_redeem"
            >
              Redeem
            </button>
          </sapn>
        );
      },
    },
    {
      header: "Created At",
      cell: ({ row }) => {
        return DateTimeFormat(row?.createdAt);
      },
    },
  ];

  return (
    <div className="withdrawals_box">
      <div className="earning_wallet_header_row">
        <div className="earning_wallet_header_col">
        <div className="withdraw_icon_div">
                <img src={withdrawlableicon} alt="" className="earning_images"/>
              </div>

              <div>

          <p
            style={{
              color: "#a3ec26",
            }}
            >
            WITHDRAWABLE BALANCE
          </p>
          <p
            style={{
              color: "#fff",
            }}
            >
            {earningWallet.data?.withdrawableBalance}
          </p>
            </div>
        </div>
        <div className="earning_wallet_header_col">

        <div className="withdraw_icon_div">
                <img src={Escrowicon} alt="" className="escrow_images"/>
              </div>

          <div>
            
          <p
            style={{
              color: "#a3ec26",
            }}
            >
            ESCROW BALANCE
          </p>
          <p
            style={{
              color: "#fff",
            }}
            >
            {earningWallet.data?.escrowBalance}
          </p>
            </div>
        </div>
        {/* <div className="earning_wallet_header_col_division_line"></div> */}
        <div className="earning_wallet_header_col">
        <div className="withdraw_icon_div">
                <img src={finalbalanceicon} alt="" className="final_images"/>
              </div>

              <div>

          <p
            style={{
              color: "#a3ec26",
            }}
            >
            FINAL BALANCE
          </p>
          <p
            style={{
              color: "#fff",
            }}
            >
            {userProfile?.data?.user?.balance}
          </p>
            </div>
        </div>
        {/* <RefralLinks /> */}
      </div>

      <DataTable columns={columns} data={getAllWithdrawalList.data} />
    </div>
  );
}
