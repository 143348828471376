import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBQZTVFTwIfzSJfNtzNIsMn2jDUnHJ9xqQ",
  authDomain: "trading-c1dd7.firebaseapp.com",
  projectId: "trading-c1dd7",
  storageBucket: "trading-c1dd7.appspot.com",
  messagingSenderId: "1064103587226",
  appId: "1:1064103587226:web:bb3e743da38b846c2b9e23",
  measurementId: "G-XC5N6JPR7M",
};

const firebaseApp = initializeApp(firebaseConfig);
const messaging = getMessaging(firebaseApp);
export const findtoken = () => {
  console.log("Requesting permission...");
  Notification.requestPermission().then((permission) => {
    console.log("Permission:", permission);
    if (permission === "granted") {
      getToken(messaging, {
        vapidKey:
          "BC9K3UL_l_VMfrPahNASkFPM0xtVqcvikmCcx7G_aQNHqEpU6w8E5BunlM9dwU7UxR58nz0SuKiLRfpNWBd8LaU",
      })
        .then((currentToken) => {
          if (currentToken) {
            localStorage.setItem("fcmToken", currentToken);
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
        });
    } else {
      console.log("Permission not granted....");
    }
  });
};

console.log(firebaseApp);

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      // console.log(payload,"payload");
      resolve(payload);
    });
  });
